<template>
  <section>
    <div class="my-4">
      <h1 class="smaller">{{ content.sectionLabels.forgotPassword }}</h1>
      <p>{{ descriptionText }}</p>
    </div>
    <div class="row margin-fix align-items-start">
      <div class="row col-12 bg-white rounded border" v-if="!linkSent">
        <div
          class="two-col-form col-md-7 col-12 p-4 ignore-margin"
          v-if="!linkSent"
        >
          <div class="form-group mb-3 required">
            <label for="field-email-address">{{
              content.fieldLabels.emailAddress
            }}</label>
            <input
              class="form-control form-control-lg"
              v-model="emailAddress"
              type="email"
              maxlength="100"
              name="email-address"
              id="field-email-address"
              required
            />
          </div>
        </div>
        <div class="col-md-5 col-12">
          <div class="row p-3 mt-4">
            <div class="col-12 my-3">
              <button
                class="btn btn-lg text-white btn-lg-text w-100 h-100 p-3 rounded"
                @click="forgotPassword"
              >
                {{ buttonText }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row col-12 bg-white rounded border" v-if="linkSent">
         <div  class="p-5 text-center m-0" v-if="userType === 'agent'">
            <p>{{ content.resentLinkSentTravel }}</p>
          </div>
          <div  class="p-5 text-center m-0" v-if="userType !== 'agent'">
            <p>{{ content.resentLinkSent }}</p>
          </div>
      </div>
    </div>
  </section>
</template>

<script>
/*
  global
    CURRENT_LANGUAGE
*/
import Warning from "./warning-banner.vue";
import axios from "axios";
import Validation from "../util/formValidation";

export default {
  name: "forgot-password-form",
  props: {
    content: { default: () => {}, type: Object },
    userType: { default: "", type: String },
  },

  data() {
    return {
      emailAddress: "",
      buttonText: this.content.buttonLabels.forgotPassword,
      descriptionText: this.content.forgotPasswordIntro,
      language: CURRENT_LANGUAGE,
      linkSent: false,
    };
  },
  methods: {
    forgotPassword: function () {
      if (this.validateEmail()) {
        axios
          .post("/wp-admin/admin-ajax.php?action=pnf_forgot_password", {
            email: this.emailAddress,
            language:
              this.language.charAt(0).toUpperCase() + this.language.slice(1),
            userType: this.userType,
          })
          .then((result) => {
            if (result.data.errors) {
              Warning.warn(result.data.errors[0], 5);
            } else {
              document
                .getElementById("field-email-address")
                .setCustomValidity("");
              this.buttonText = this.content.sent;
              // this.descriptionText = this.content.resentLinkSent;
              this.linkSent = true;
              this.emailAddress = "";
            }
          });
      }
    },
    validateEmail: function () {
      return Validation.email.test(this.emailAddress);
    },
  },
};
</script>
