<template>
  <section>
    <p><em>* {{content.mandatory}}</em></p>
    <form id="registration-form" @submit="validate">
      <div class="two-col-form row margin-fix">
        <div class="inner-form col-12 col-lg-8 bg-white rounded p-4 border">
          <div class="form-row justify-content-between mb-0">
            <div class="col-12 padding-fix mt-3">
              <h2>{{ content.sectionLabels.information }}</h2>
            </div>
            <div class="form-group required">
              <input
                class="form-control form-control-lg"
                v-model="firstName"
                type="text"
                name="firstName"
                id="field-firstName"
                required
                validate-name
                :aria-label="'* ' + content.fieldLabels.firstName"
                :placeholder="'* ' + content.fieldLabels.firstName"
              />
            </div>
            <div class="form-group required">
              <input
                class="form-control form-control-lg"
                v-model="lastName"
                type="text"
                name="lastName"
                id="field-lastName"
                required
                validate-name
                :aria-label="'* ' + content.fieldLabels.lastName"
                :placeholder="'* ' + content.fieldLabels.lastName"
              />
            </div>
          </div>
          <div class="form-row justify-content-between mb-4">
            <div class="form-group required w-100">
              <input
                class="form-control form-control-lg"
                v-model="postalCode"
                type="text"
                name="postalCode"
                id="field-address-postal"
                required
                validate-postal
                :placeholder="'* ' + content.fieldLabels.postal"
                :aria-label="'* ' + content.fieldLabels.postal"
              />
            </div>
          </div>
          <div class="form-row justify-content-between mb-0 border-top">
            <div class="col-12 padding-fix mt-4 pt-2">
              <h2>{{ content.sectionLabels.signIn }}</h2>
            </div>
            <div class="col-12">
              <div class="form-row">
                <div class="form-group">
                  <input
                    class="form-control form-control-lg"
                    type="email"
                    maxlength="100"
                    id="field-email"
                    v-model="emailAddress"
                    data-server-name="email"
                    validate-email
                    @focus="showError.email = false"
                    @blur="showError.email = !isValidEmailAddress"
                    :aria-label="`* ${content.fieldLabels.email}`"
                    :placeholder="`* ${content.fieldLabels.email}`"
                  />
                </div>
                <div class="form-group">
                  <input
                    class="form-control form-control-lg"
                    type="email"
                    maxlength="100"
                    id="field-email"
                    v-model="confirmEmailAddress"
                    data-server-name="email"
                    validate-email
                    @focus="showError.confirmEmail = false"
                    @blur="showError.confirmEmail = !isValidConfirmEmailAddress"
                    :aria-label="`* ${content.fieldLabels.confirmEmail}`"
                    :placeholder="`* ${content.fieldLabels.confirmEmail}`"
                  />
                </div>
              </div>
            </div>
            <div class="col-12" v-if="!isValidConfirmEmailAddress">
              <p class="text-red">
                {{ content.error.confirmEmail }}
              </p>
            </div>
            <div class="col-12">
              <div class="form-group">
                <input
                  class="form-control form-control-lg w-100"
                  id="field-cellNumber"
                  type="tel"
                  v-mask="'(###) ###-####'"
                  v-model="cellNumber"
                  @focus="showError.phone = false"
                  @blur="showError.phone = !isValidPhoneNumber"
                  validate-phone
                  :aria-label="`* ${content.fieldLabels.phoneNumberPH}`"
                  :placeholder="`* ${content.fieldLabels.phoneNumberPH}`"
                />
              </div>
            </div>
          </div>
          <div class="form-row justify-content-between mb-2">
            <input type="hidden" id="verified-email-hash" required />
            <validation-code
              emailAddressId="field-email"
              verifiedHashId="verified-email-hash"
              phoneNumberId="field-cellNumber"
              bothContactsAccepted
              contactType="E"
              v-on:hash-received="storeHash"
            />
          </div>
          <password-fields
            class="inner-form justify-content-between mt-2 mb-4"
            ref="passwordFields"
          ></password-fields>
          <div class="inner-form pt-4 border-top">
            <div class="form-row mb-0 mt-2">
              <h2>{{ content.sectionLabels.additionalInfo }}</h2>
            </div>
            <div class="form-row justify-content-between">
              <div class="form-group">
                <input
                  class="form-control form-control-lg"
                  v-model="aeroplanNumber"
                  type="text"
                  name="aeroplanNumber"
                  id="field-aeroplan-number"
                  data-server-name="Aeroplan"
                  validate-plan-number
                  :placeholder="content.fieldLabels.aeroplanNumber"
                  :aria-label="content.fieldLabels.aeroplanNumber"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-lg"
                  v-model="corporateDiscountNumber"
                  type="text"
                  name="corporateDiscountNumber"
                  id="field-corporate-discount-number"
                  validate-plan-number
                  :placeholder="content.fieldLabels.corporateDiscountNumber"
                  :aria-label="content.fieldLabels.corporateDiscountNumber"
                />
              </div>
              <div class="form-group required">
                <div class="select-wrapper --large">
                  <select
                    class="form-control form-control-lg"
                    validate-city
                    v-model="preferredCityId"
                    name="location"
                    id="field-location"
                    required
                    :aria-label="'* ' + content.fieldLabels.location"
                  >
                    <option disabled selected value="null">
                      {{ "* " + content.fieldLabels.location }}
                    </option>
                    <template v-for="location in locations">
                      <option :key="location.city_id" :value="location.city_id">
                        {{ location.city_name | formatFrenchCharacters }}
                      </option>
                    </template>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <div class="select-wrapper --large">
                  <select
                    class="form-control form-control-lg"
                    id="preferred-language"
                    v-model="preferredLanguage"
                    :aria-label="content.fieldLabels.language"
                  >
                    <option disabled selected value="-1">
                      {{ content.fieldLabels.language }}
                    </option>
                    <option value="En">English</option>
                    <option value="Fr">Français</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="inner-form my-4 pt-4 border-top">
            <div
              class="form-row d-flex justify-content-between align-items-center"
            >
              <h2 class="mb-0">{{ content.sectionLabels.vehicleInfo }}</h2>
              <button
                type="button"
                class="btn"
                v-if="!showVehicleForm"
                @click="setFormToAddNewVehicle()"
              >
               {{content.buttonLabels.addVehicle}}
              </button>
            </div>
            <div v-if="showVehicleForm">
              <div class="form-row justify-content-between">
                <div class="form-group">
                  <div class="select-wrapper --large">
                    <select
                      class="form-control form-control-lg"
                      id="vehicle-make"
                      v-model="newVehicle.make"
                      :aria-label="content.fieldLabels.vehicleMake"
                    >
                      <option disabled selected value="-1">
                        {{
                          `${vehicleInfoHasBeenAdded ? "* " : ""}${
                            content.fieldLabels.vehicleMake
                          }`
                        }}
                      </option>
                      <template v-for="make in formOptions.make">
                        <option
                          v-bind:key="'make' + make.vehicle_make_id"
                          v-bind:value="[make.vehicle_make_id, make.make_name]"
                        >
                          {{ make.make_name | capitalize }}
                        </option>
                      </template>
                    </select>
                  </div>
                </div>
                <div class="form-group">
                  <div class="select-wrapper --large">
                    <select
                      class="form-control form-control-lg"
                      id="vehicle-type"
                      v-model="newVehicle.type"
                      :aria-label="content.fieldLabels.vehicleType"
                    >
                      <option disabled selected value="-1">
                        {{
                          `${vehicleInfoHasBeenAdded ? "* " : ""}${
                            content.fieldLabels.vehicleType
                          }`
                        }}
                      </option>
                      <template v-for="type in formOptions.type">
                        <option
                          v-bind:key="'type' + type.vehicle_type_id"
                          v-bind:value="[type.vehicle_type_id, type.body_type]"
                        >
                          {{ type.body_type | capitalize }}
                        </option>
                      </template>
                    </select>
                  </div>
                </div>
                <div class="form-group">
                  <div class="select-wrapper --large">
                    <select
                      class="form-control form-control-lg"
                      id="vehicle-color"
                      v-model="newVehicle.color"
                      :aria-label="content.fieldLabels.vehicleColor"
                    >
                      <option disabled selected value="-1">
                        {{
                          `${vehicleInfoHasBeenAdded ? "* " : ""}${
                            content.fieldLabels.vehicleColor
                          }`
                        }}
                      </option>
                      <template v-for="color in formOptions.color">
                        <option
                          v-bind:key="'color' + color.vehicle_colour_id"
                          v-bind:value="[color.vehicle_colour_id, color.colour]"
                        >
                          {{ color.colour | capitalize }}
                        </option>
                      </template>
                    </select>
                  </div>
                </div>
                <div class="form-group">
                  <input
                    id="license-number"
                    class="form-control form-control-lg"
                    type="text"
                    maxlength="10"
                    v-model="newVehicle.licenseNumber"
                    :placeholder="`${vehicleInfoHasBeenAdded ? '* ' : ''}${
                      content.fieldLabels.plateNumber
                    }`"
                    :aria-label="content.fieldLabels.plateNumber"
                  />
                </div>
                <div class="form-group">
                  <div class="select-wrapper --large">
                    <select
                      class="form-control form-control-lg"
                      id="vehicle-province1"
                      v-model="newVehicle.licenseProvince"
                      :aria-label="content.fieldLabels.province"
                    >
                      <option disabled selected value="-1">
                        {{
                          `${vehicleInfoHasBeenAdded ? "* " : ""}${
                            content.fieldLabels.province
                          }`
                        }}
                      </option>
                      <template v-for="province in formOptions.provinces">
                        <option
                          v-bind:key="province.province_id"
                          v-bind:value="[
                            province.province_id,
                            province.province_name,
                          ]"
                        >
                          {{ province.province_name | capitalize }}
                        </option>
                      </template>
                    </select>
                  </div>
                </div>
                <div class="form-group col-12 col-md-6 mt-2 mb-3">
                  <div class="inline-block">
                    <span class="d-inline-block">
                      {{
                        `${vehicleInfoHasBeenAdded ? "* " : ""}${
                          content.fieldLabels.electricVehicle
                        }`
                      }}
                    </span>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="vehicle-electric"
                        id="vehicle-electric"
                        value="Y"
                        v-model="newVehicle.isEV"
                        :aria-label="content.fieldLabels.yes"
                        :required="vehicleInfoHasBeenAdded"
                      />
                      <label
                        class="form-check-label text-medium"
                        for="vehicle-electric"
                        >{{ content.fieldLabels.yes }}</label
                      >
                      <input
                        class="form-check-input ml-4"
                        type="radio"
                        name="vehicle-electric"
                        id="vehicle-electric"
                        value="N"
                        v-model="newVehicle.isEV"
                        :aria-label="content.fieldLabels.no"
                        :required="vehicleInfoHasBeenAdded"
                      />
                      <label
                        class="form-check-label text-medium"
                        for="vehicle-electric"
                        >{{ content.fieldLabels.no }}</label
                      >
                    </div>
                  </div>
                </div>
                <div class="form-group col-12 my-4">
                  <button
                    type="button"
                    class="btn btn-lg text-white"
                    v-if="currentVehicleIndex === -1"
                    @click="addVehicle"
                    :disabled="!addVehicleFormIsValid"
                  >
                    {{ content.buttonLabels.addVehicle }}
                  </button>
                  <button
                    type="button"
                    class="btn btn-lg text-white"
                    v-if="currentVehicleIndex >= 0"
                    @click="addVehicle"
                    :disabled="!addVehicleFormIsValid"
                  >
                    {{ content.buttonLabels.updateVehicle }}
                  </button>

                  <button
                    type="button"
                    class="btn cancel-button-text ml-2"
                    @click="cancelVehicleForm"
                  >
                     {{ content.buttonLabels.cancel }}
                  </button>
                </div>
              </div>
            </div>
            <div>
              <!-- Vehicle Table -->
              <div>
                <template v-if="this.vehicles && this.vehicles.length > 0">
                  <div class="table-responsive mt-4">
                    <table class="table">
                      <thead class="thead-grey">
                        <tr>
                          <th scope="col">
                            {{ content.fieldLabels.vehicleMake }}
                          </th>
                          <th scope="col">
                            {{ content.fieldLabels.vehicleType }}
                          </th>
                          <th scope="col">
                            {{ content.fieldLabels.vehicleColor }}
                          </th>
                          <th scope="col">
                            {{ content.fieldLabels.plateNumber }}
                          </th>
                          <th scope="col" class="no-border">
                            {{ content.fieldLabels.province }}
                          </th>
                          <th scope="col" class="no-border">
                            {{ content.fieldLabels.electricVehicleHeader }}
                          </th>
                          <th scope="col" class="no-border">&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody class>
                        <tr
                          v-for="(vehicle, index) in this.vehicles"
                          :key="index"
                        >
                          <template v-if="vehicle != null">
                            <td>{{ vehicle.make }}</td>
                            <td>{{ vehicle.type }}</td>
                            <td>{{ vehicle.color }}</td>
                            <td>{{ vehicle.licenseNumber }}</td>
                            <td>
                              {{ vehicle.licenseProvince }}
                            </td>
                            <td>
                              {{
                                vehicle.electronicVehicle === "Y"
                                  ? content.fieldLabels.yes
                                  : content.fieldLabels.no
                              }}
                            </td>
                            <td
                              class="d-flex align-items-center justify-content-between"
                            >
                              <a
                                v-if="!showVehicleForm"
                                class="blue-link inherit-font pl-2 pr-2 mr-1"
                                @click="editVehicle(vehicle, index)"
                                ><i class="fa fa-pencil" aria-hidden="true"></i
                              ></a>
                              <a
                                v-if="!showVehicleForm"
                                class="blue-link inherit-font pl-2 pr-2"
                                @click="deleteVehicle(vehicle)"
                                ><i class="fa fa-trash-o" aria-hidden="true"></i
                              ></a>
                            </td>
                          </template>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </template>
                <div
                  v-if="this.vehicles.length === 0"
                  class="text-center mt-5 mb-5 text-italics"
                >
                  <em> {{ content.fieldLabels.noVehicles }} </em>
                </div>
              </div>
            </div>
            <div class="form-row col-12 pt-4 border-top px-0 w-100">
              <div class="col-12 padding-fix mt-4 pt-2">
                <h2>{{ content.sectionLabels.notificationSettings }}</h2>
              </div>
              <p>
                {{ content.fieldLabels.notificationMessage }}
              </p>

              <p>
                <strong
                  >{{ content.fieldLabels.reservationCommunication }}:</strong
                >
                <br />
                <em class="small">{{ content.fieldLabels.mustSelectOne }}</em>
              </p>

              <div class="form-row justify-content-between w-100 mb-3">
                <div class="form-group d-flex mt-0">
                  <label
                    id="viaEmailComminication"
                    class="d-flex align-items-center"
                  >
                    <input
                      class="big-checkbox --align-top mr-3 d-block"
                      type="checkbox"
                      validate-res-com
                      v-model="resCommbyEmail"
                      @blur="clearValidation"
                    />

                    {{ content.fieldLabels.viaEmail }}
                  </label>
                </div>
                <div class="form-group mt-0 d-flex">
                  <label class="d-flex align-items-center">
                    <input
                      class="big-checkbox --align-top mr-3 d-block"
                      type="checkbox"
                      v-model="resCommbySMS"
                      @blur="clearValidation"
                    />
                    {{ content.fieldLabels.viaSMS }}
                  </label>
                </div>
              </div>
              <p>
                <strong>{{ content.fieldLabels.newsAndOffer }}: </strong><br />
              </p>
              <div class="form-row justify-content-between w-100 mb-5">
                <div class="form-group d-flex mt-0">
                  <label
                    id="viaEmailComminication"
                    class="d-flex align-items-center"
                  >
                    <input
                      class="big-checkbox --align-top mr-3 d-block"
                      type="checkbox"
                      v-model="marketbyEmail"
                    />

                    {{ content.fieldLabels.viaEmail }}
                  </label>
                </div>
                <div class="form-group mt-0 d-flex">
                  <label class="d-flex align-items-center">
                    <input
                      class="big-checkbox --align-top mr-3 d-block"
                      type="checkbox"
                      v-model="marketbySMS"
                    />
                    {{ content.fieldLabels.viaSMS }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 mt-4 mt-lg-0 p-0">
          <div class="row p-3 rounded bg-white border sticky-top submit-box">
            <div class="col-12 mt-4">
              <h3>{{ content.needHelp }}</h3>
            </div>
            <div class="col-12 mb-3">
              <p class="mb-0">
                {{ content.registrationSupport[0] }}
                <a href="mailto:loyalty@parknfly.ca">{{
                  content.registrationSupport[1]
                }}</a>
              </p>
            </div>
              <div
              class="col-12 mt-3"
              v-show="contactValidationNeeded && formIsValid"
            >
              <p class="text-red mt-4 mb-0">
                {{ content.error.validationNeeded }}
              </p>
            </div>
            <div class="col-12 mt-3" v-show="showError.resComm">
              <p class="text-red mt-4 mb-0">
                {{ content.error.commError }}
              </p>
            </div>
            <div
              class="col-12 mt-3"
              v-show="!isValidConfirmEmailAddress && formIsValid"
            >
              <p class="text-red mt-4 mb-0">
                {{ content.error.confirmEmail }}
              </p>
            </div>
            <div class="col-12 my-2" v-if="!submitting">
              <input
                type="submit"
                class="btn btn-lg text-white btn-lg-text w-100 h-100 p-3 rounded"
                :value="content.buttonLabels.register"
                aria-label="Submit registration button"
              />
              <a
                class="cancel-button"
                :href="getCancelLink()"
                v-if="fromOrigin === 'app'"
                >  {{ content.buttonLabels.cancelRegistration }}</a
              >
            </div>
            <div class="col-12 my-2" v-if="submitting">
              <div class="text-center">
                <img
                  src="/wp-content/themes/parknfly/resources/assets/images/loading.gif"
                  width="65"
                  height="65"
                />
              </div>
            </div>
            <div class="col-12 mt-3">
              <p v-html="content.termsAndPolicyAgreement"></p>
            </div>
          </div>


        </div>
      </div>
    </form>
    <div class="progress-spy" v-if="fromOrigin === 'app'">
      <div class="progress-bar"></div>
    </div>
  </section>
</template>

<script>
/*
  global
    CURRENT_LANGUAGE
*/
import axios from "axios";
import ValidationCode from "./validation-code.vue";
import PasswordFields from "./password-fields.vue";
import Content from "../../string_resources/member-edit.json";
import Toast from "./warning-banner.vue";
import { mask } from "vue-the-mask";
import * as util from "../util/shared";
import Validation from "../util/formValidation";
import store from "../components/store";
import { load } from "recaptcha-v3";
import { RECAPTCHA_API_KEY } from "../main";
const initialVehicle = {
  make: -1,
  type: -1,
  color: -1,
  licenseProvince: -1,
  licenseNumber: "",
  isEV: "N",
};

export default {
  name: "registration-form",
  components: { ValidationCode, PasswordFields },
  directives: { mask },
  props: ["user", "isConversion"],
  data() {
    return {
      content: Content[CURRENT_LANGUAGE],

      // CURRENTLY HARD CODED - PLEASE GRAB LOCATIONS DYNAMICALLY
      locations: [],
      firstName: "",
      lastName: "",
      postalCode: "",
      emailAddress: "",
      userVehicles: "",
      confirmEmailAddress: "",
      cellNumber: "",
      aeroplanNumber: "",
      corporateDiscountNumber: "",
      preferredCityId: null,
      preferredLanguage: "En",
      marketbyEmail: false,
      marketbySMS: false,
      resCommbyEmail: false,
      resCommbySMS: false,
      preferences: {
        preferredLanguage: null,
        preferredLocation: null,
        marketbyEmail: false,
        marketbySMS: false,
        cardPrint: false,
      },
      formIsValid: false,
      addingVehicle: false,
      newVehicle: { ...initialVehicle },
      vehicles: [],
      promotionsApproved: false,
      contactValidationHash: "",
      showVehicleForm: false,
      currentVehicleIndex: -1,
      showError: {
        phone: false,
        email: false,
        postal: false,
        confirmEmail: false,
        resComm: false,
        preferredCity: false,
      },
      vehicleInfoHasBeenAdded: false,
      addVehicleFormIsValid: false,
      loaded: false,
      fromOrigin: "",
      submitting: false,
    };
  },
  watch: {
    newVehicle: {
      handler: function () {
        this.vehicleInfoHasBeenAdded =
          this.newVehicle.type !== initialVehicle.type ||
          this.newVehicle.make !== initialVehicle.make ||
          this.newVehicle.color !== initialVehicle.color ||
          this.newVehicle.licenseNumber !== initialVehicle.licenseNumber ||
          this.newVehicle.licenseProvince !== initialVehicle.licenseProvince;

        this.addVehicleFormIsValid =
          this.newVehicle.type !== initialVehicle.type &&
          this.newVehicle.make !== initialVehicle.make &&
          this.newVehicle.color !== initialVehicle.color &&
          this.newVehicle.licenseNumber !== initialVehicle.licenseNumber &&
          this.newVehicle.licenseProvince !== initialVehicle.licenseProvince &&
          /^([A-z]|\d){1,10}$/.test(this.newVehicle.licenseNumber);
      },
      deep: true,
    },
  },
  methods: {
    getCancelLink: function () {
      return "/rewards-registration-cancel";
    },
    setFormToAddNewVehicle: function () {
      this.newVehicle = { ...initialVehicle };
      this.showVehicleForm = true;
      this.currentVehicleIndex = -1;
    },
    editVehicle: function (vehicle, index) {
      var editVehicle = {
        make: [vehicle.makeId, vehicle.make],
        type: [vehicle.typeId, vehicle.type],
        color: [vehicle.colorId, vehicle.color],
        licenseProvince: [
          vehicle.licenseProvinceId,
          vehicle.licenseProvince.replace(/\s/g, ""),
        ],
        licenseNumber: vehicle.licenseNumber,
        isEV: vehicle.electronicVehicle,
      };
      this.newVehicle = { ...editVehicle };
      this.showVehicleForm = true;
      this.currentVehicleIndex = index;
    },
    validate: function (e) {
      e.preventDefault();


      this.selectRequired();
      this.validateName();
      this.validatePhoneNumber();
      this.$refs.passwordFields.validatePassword();
      this.validatePlanNumber();
      this.validatePostalCode();
      this.validatePrefferedCity();
       if (!this.isValidCommunication) {
        this.showError.resComm = true;
        return;
      } else {
        this.showError.resComm = false;
      }

      this.formIsValid = $("#registration-form")[0].reportValidity();
      if (this.formIsValid && this.contactValidationHash != "") {
        this.registerUser();
      }
    },
    capitalize: function (text) {
      return text ? text.charAt(0).toUpperCase() + text.slice(1) : " ";
    },
    storeHash: function () {
      this.contactValidationHash = document.getElementById(
        "verified-email-hash"
      ).value;
    },
    deleteVehicle: function (vehicle) {
      let filteredVehicleList = this.vehicles.filter((v) => v != vehicle);
      this.vehicles = filteredVehicleList ? filteredVehicleList : [];
    },
    cancelVehicleForm: function () {
      this.newVehicle = { ...initialVehicle };
      this.currentVehicleIndex = -1;
      this.showVehicleForm = false;
    },
    addVehicle: function () {
      let vehicleToAdd = {
        vehicleId: 0,
        makeId: this.newVehicle.make[0],
        typeId: this.newVehicle.type[0],
        colorId: this.newVehicle.color[0],
        make: this.newVehicle.make[1],
        type: this.newVehicle.type[1],
        color: this.newVehicle.color[1],
        electronicVehicle: this.newVehicle.isEV,
        licenseNumber: this.newVehicle.licenseNumber,
        licenseProvince: this.newVehicle.licenseProvince[1],
        licenseProvinceId: this.newVehicle.licenseProvince[0]
          ? parseInt(this.newVehicle.licenseProvince[0])
          : 0,
      };
      if (!this.vehicles) {
        this.vehicles = [];
      }
      if (this.currentVehicleIndex >= 0) {
        this.vehicles[this.currentVehicleIndex] = vehicleToAdd;
      } else {
        this.vehicles.push(vehicleToAdd);
      }
      //Reset form values
      this.newVehicle = { ...initialVehicle };
      this.currentVehicleIndex = -1;
      this.showVehicleForm = false;
    },
    selectRequired: function () {
      $("select[required]").each(() => {
        if ($(this).value == -1) {
          $(this).attr("isvalid", false);
        } else {
          $(this).attr("isvalid", true);
        }
      });
    },
    validatePhoneNumber: function () {
      this.runValidation(
        "[validate-phone]",
        Validation.phoneNumber,
        this.content.error.phone,
        this.validatePhoneNumber
      );
    },
    validatePrefferedCity: function () {
      this.runValidation(
        "[validate-city]",
        Validation.preferredCity,
        this.content.error.preferredCity,
        this.validatePrefferedCity
      );
    },
    validateEmail: function () {
      this.runValidation(
        "[validate-email]",
        Validation.email,
        this.content.error.email,
        this.validateEmail
      );
    },
    validateName: function () {
      this.runValidation(
        "[validate-name]",
        Validation.name,
        this.content.error.name,
        this.validateName
      );
    },
    validatePlanNumber: function () {
      this.runValidation(
        "[validate-plan-number]",
        Validation.planNumber,
        this.content.error.discountNumber,
        this.validatePlanNumber
      );
    },
    validatePostalCode: function () {
      this.runValidation(
        "[validate-postal]",
        Validation.postal,
        this.content.error.postalCode,
        this.validatePostalCode
      );
    },
    validateResComm: function () {
      $("[validate-res-com]").each((index, el) => {
        if (this.isValidCommunication) {
          el.setCustomValidity("");
        } else {
          el.setCustomValidity(this.content.error.commError);
        }
      });
    },
    clearValidation: (e) => {
      e.target.setCustomValidity("");
    },
    runValidation: function (
      selector,
      validationPattern,
      msg,
      validationCallback
    ) {
      $(selector).each((index, el) => {
        var valid = validationPattern.test(el.value);
        if (valid) {
          el.setCustomValidity("");
        } else {
          el.setCustomValidity(msg);
          if (typeof el.onblur != "function") {
            el.onblur = validationCallback;
          }
        }
      });
    },
    invalidateTemporarily: function (selector, msg) {
      $(selector).each((index, el) => {
        el.setCustomValidity(msg);
        if (typeof el.onblur != "function") {
          el.onblur = () => {
            el.setCustomValidity("");
          };
        }
      });
    },
    registerUser: function () {
      if (!this.isValidConfirmEmailAddress) {
        return;
      }
      let $this = this;
      let userObj = {
        custId: this.user ? this.user.custId : null,
        legacyRewardNumber: this.user ? this.user.rewards.rewardNumber : null,
        firstName: this.capitalize(this.firstName),
        lastName: this.capitalize(this.lastName),
        postalCode: this.postalCode,
        email: this.emailAddress,
        cellNumber: this.phoneNumberDigits,
        aeroplanNumber:
          this.aeroplanNumber.length > 0 ? this.aeroplanNumber : null,
        corporateDiscountNumber: this.corporateDiscountNumber,
        password: this.$refs.passwordFields.password,
        vehicles: this.vehicles,
        preferredCityId: this.preferredCityId,
        preferredLanguage: this.preferredLanguage,
        marketbyEmail: this.marketbyEmail ? "Y" : "N",
        marketbySMS: this.marketbySMS ? "Y" : "N",
        resCommbyEmail: this.resCommbyEmail ? "Y" : "N",
        resCommbySMS: this.resCommbySMS ? "Y" : "N",
      };

      function handleRegistration(newUser, attempts) {
        attempts = attempts || 1;
        axios
          .post("/wp-admin/admin-ajax.php?action=pnf_register_user", newUser, {
            timeout: 60000,
          })
          .then((result) => {
            if (
              (result.data.status == "TIMEOUT" || result.status === 500) &&
              attempts < 4
            ) {
              handleRegistration(newUser, ++attempts);
            } else if (result.status == "OK" || result.data.status == "OK") {
              $this.redirectAfterRegistration();
              return;
            } else if (
              result.status == "INVALID" ||
              result.data.status == "INVALID"
            ) {
              let validationMsg =
                CURRENT_LANGUAGE == "en"
                  ? result.data.response.messageEnglish
                  : result.data.response.messageFrench;
              Toast.warn(validationMsg);
              $this.invalidateTemporarily(
                `[data-server-name=${result.data.response.field}`,
                validationMsg
              );
              $this.submitting = false;
              return;
            } else if (
              result.status == "VALIDATION_ERROR" ||
              result.data.status == "VALIDATION_ERROR"
            ) {
              let validationMsg =
                CURRENT_LANGUAGE == "en"
                  ? result.data.errors.error_message_en
                  : result.data.errors.error_message_fr;
              Toast.warn(validationMsg);
              $this.submitting = false;
              return;
            } else {
              Toast.warn(
                CURRENT_LANGUAGE === "en"
                  ? "Sorry, your request could not be completed at this time."
                  : "Désolé, votre demande n’a pas pu être complétée pour le moment."
              );
              $this.submitting = false;
            }
          })
          .catch(() => {
            if (attempts < 4) {
              handleRegistration(newUser, ++attempts);
            } else {
              Toast.warn(
                CURRENT_LANGUAGE === "en"
                  ? "Sorry, your request could not be completed at this time."
                  : "Désolé, votre demande n’a pas pu être complétée pour le moment."
              );
              $this.submitting = false;
            }
          });
      }
      load(RECAPTCHA_API_KEY).then((recaptcha) => {
        recaptcha.execute("registerreward").then((token) => {
          userObj.token = token;
          userObj.action = "registerreward";
          this.submitting = true;
          handleRegistration(userObj);
        });
      });

      // this.submitting = true;
      // handleRegistration(userObj);
    },
    redirectAfterRegistration: function () {
      window.location.href =
        CURRENT_LANGUAGE === "en"
          ? "/rewards-registration-thank-you?welcomeid=" +
            this.phoneNumberDigits +
            "&welcomeuser=" +
            this.firstName +
            "&from=" +
            this.fromOrigin
          : "/fr/inscription-aux-recompenses-merci?welcomeid=" +
            this.phoneNumberDigits +
            "&from=" +
            this.fromOrigin;
    },
    checkForSpaces: function (str) {
      var removedSpaces = str.replace(/\s/g, "");
      return removedSpaces.length > 0 ? removedSpaces : "";
    },
    checkForLanguage: function (str) {
      if (str === "En" || str === "Fr") {
        return str;
      } else if (str === "E ") {
        return "En";
      } else if (str === "F ") {
        return "Fr";
      }
      return str;
    },
    populateDataFromUser() {
      this.firstName = this.user.firstName;
      this.lastName = this.user.lastName;
      this.postalCode = this.user.address.postalCode;
      this.emailAddress = this.user.email;
      this.cellNumber = this.user.cellNumber;
      this.aeroplanNumber = this.checkForSpaces(this.user.aeroplanNumber);
      this.preferredLanguage = this.checkForLanguage(
        this.user.preferences.preferredLanguage
      );
      this.marketbyEmail = this.user.preferences.markeyByEmail;
      this.marketbySMS = this.user.preferences.marketbySMS;
      // For Old users who has the value as null
      if (this.user.vehicles) {
        for (var i = 0; i < this.user.vehicles.length; i++) {
          this.user.vehicles[i].vehicleId = 0;
          this.user.vehicles[i].electronicVehicle = this.user.vehicles[i]
            .electronicVehicle
            ? "N"
            : "Y";
        }
      }
      this.vehicles = this.user.vehicles;
      this.corporateDiscountNumber = this.user.corporateDiscountNumber
        ? this.user.corporateDiscountNumber + ""
        : null;
    },
  },
  computed: {
    formOptions: function () {
      return {
        color: this.$store.getters.getVehicleColours,
        type: this.$store.getters.getVehicleTypes,
        make: this.$store.getters.getVehicleMakes,
        provinces: this.$store.getters.getVehicleProvinces,
      };
    },
    contactValidationNeeded: function () {
      if (this.isValidEmailAddress || this.isValidPhoneNumber) {
        return this.contactValidationHash == "";
      }
    },
    phoneNumberDigits: function () {
      return this.cellNumber.replace(/[^0-9]/g, "");
    },
    isValidPhoneNumber: function () {
      let phone = this.phoneNumberDigits;
      return phone.length == 10;
    },
    isValidEmailAddress: function () {
      if (this.emailAddress !== "") {
        return this.validateEmail();
      } else {
        return true;
      }
    },
    isValidConfirmEmailAddress: function () {
      return this.emailAddress === this.confirmEmailAddress;
    },
    isValidCommunication: function () {
      return this.resCommbyEmail || this.resCommbySMS;
    },
  },
  filters: {
    capitalize(text) {
      return text ? text.charAt(0).toUpperCase() + text.slice(1) : " ";
    },
    formatFrenchCharacters: (val) =>
      util.OfferFilters[CURRENT_LANGUAGE].renderHtmlEscapedCharacters(val),
  },
  created() {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has("from")) {
      this.fromOrigin = queryParams.get("from");
    }
    if (this.user) {
      this.populateDataFromUser();
    }

    if (this.fromOrigin === "app") {
      // add scroll spy listener
      $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        var maxScroll = $(document).height() - $(window).height() - 300;
        var spyHeight = (scroll / maxScroll) * 100;
        if (spyHeight > 100) {
          $(".progress-spy .progress-bar").css("height", "100%");
        } else {
          $(".progress-spy .progress-bar").css(
            "height",
            `${Math.round(spyHeight)}%`
          );
        }
      });
    }

    axios
      .get("/wp-admin/admin-ajax.php?action=pnf_getCities")
      .then((result) => {
        this.locations = result.data.result.cities;
      })
      .catch(() => {});
  },
  beforeCreate: function () {
    this.$store = store;
  },
  mounted() {
    util.styleDropdowns();
    // Autofill corporate discount number
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has("coupon")) {
      this.corporateDiscountNumber = queryParams.get("coupon");
    }
  },
};
</script>
