<template>
  <div class="booking-comp">
    <div class="row field-container margin-fix">
      <div class="location-container">
        <div class="dropdown">
          <label for="dropdownMenuButton" class="field__label">{{
            content.locationLabel
          }}</label>
          <button
            class="dropdown-toggle form-control"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {{ selectedCity }}
          </button>
          <div
            class="dropdown-menu form-control"
            aria-labelledby="dropdownMenuButton"
          >
            <template v-for="location in locations">
              <a
                class="dropdown-item"
                :aria-label="location.title"
                :title="location.title"
                v-on:click="changeLocation(location.city)"
                v-bind:key="location.permalink"
                v-bind:href="location.permalink"
                >{{ location.title }}</a
              >
              <div
                class="dropdown-divider"
                v-bind:key="`divider-${location.title}`"
              ></div>
            </template>
          </div>
          <label class="field__lower-text">{{ selectedAirport }}</label>
        </div>
      </div>

      <div class="picker-parent-container">
        <div tabindex="0" id="dropoff-field-label">
          <label for="FieldDropOff" class="field__label">{{
            content.dropOffLabel
          }}</label>
          <input
            type="hidden"
            id="FieldDropOff"
            v-model="this.selectedDropOffTime"
            ref="dropOffPickr"
            class="form-control"
          />
          <vue-timepicker
            v-show="isDropOffPicker"
            ref="dropOffTimeSelector"
            hide-clear-button
            format="hh:mm a"
            :minute-interval="30"
            :dropOffTime="this.displayDropOffTime.toDate()"
            @change="(val) => handleTimeSelect(val, true)"
          ></vue-timepicker>
          <button
            class="dropdown-toggle form-control"
            @click="triggerPicker('FieldDropOff')"
            @focus="triggerPicker('FieldDropOff')"
            type="button"
          >
            <span class="d-block d-md-none d-xxl-block">{{
              this.displayDropOffTime.format("MMMM D, YYYY")
            }}</span>
            <span class="d-none d-md-block d-xxl-none">{{
              this.displayDropOffTime.format("MM/D/YY")
            }}</span>
          </button>
          <label class="field__lower-text">{{
            this.displayDropOffTime.format("h:mm A")
          }}</label>
        </div>
      </div>

      <div class="picker-parent-container">
        <div tabindex="0" id="pickup-field-label">
          <label for="FieldPickup" class="field__label">{{
            content.pickUpLabel
          }}</label>
          <input
            type="hidden"
            id="FieldPickup"
            class="form-control"
            v-model="this.selectedPickUpTime"
            ref="pickUpPickr"
          />
          <vue-timepicker
            v-show="!isDropOffPicker"
            ref="pickUpTimeSelector"
            hide-clear-button
            format="h:mm a"
            :minute-interval="30"
            :pickupTime="this.displayPickUpTime.toDate()"
            @change="(val) => handleTimeSelect(val, false)"
          ></vue-timepicker>
          <button
            class="dropdown-toggle form-control"
            @click="triggerPicker('FieldPickup')"
            @focus="triggerPicker('FieldPickup')"
            type="button"
          >
            <span class="d-block d-md-none d-xxl-block">{{
              this.displayPickUpTime.format("MMMM D, YYYY")
            }}</span>
            <span class="d-none d-md-block d-xxl-none">{{
              this.displayPickUpTime.format("MM/D/YY")
            }}</span>
          </button>
          <label class="field__lower-text">{{
            this.displayPickUpTime.format("h:mm A")
          }}</label>
        </div>
      </div>
      <div
        class="no-border no-align discount-container"
        tabindex="0"
        id="coupon-field-label"
      >
        <div v-if="!isReferralCoupon">
          <label for="DiscountCode" class="field__label">
            {{ content.discountCodeLabel[0] }}
            <i>{{ content.discountCodeLabel[1] }} </i>
            <a
              class="ml-3 tooltip-marker"
              data-toggle="tooltip"
              data-placement="auto"
              :title="
                selectedCity === 'Montreal'
                  ? content.montrealDiscountCodeHelp
                  : content.discountCodeHelp
              "
              ><span class="icon-pnf-support-questions"></span
            ></a>
          </label>
          <input
            type="text"
            id="DiscountCode"
            v-model="discount"
            class="form-control mt-2"
            placeholder=" "
          />
        </div>
        <div v-if="isReferralCoupon">
          <label
            for="RemoveReferral"
            v-html="content.referralCouponLabel"
            class="field__label"
          ></label>
          <label for="FieldDropOff" class="booking__label">{{
            content.preferredRatesApplied
          }}</label>
        </div>
      </div>
      <div
        id="find-rate-btn-container"
        v-on:click="getQuote"
        class="col-md-3 btn btn--big btn--full-col"
      >
        <a href="#ReserveSpot" class="text-white no-underline btn--big">{{
          content.findYourRate
        }}</a>
      </div>
    </div>
    <div id="datepicker-container" ref="datePickerContainer"></div>
    <div v-if="loading" class="loading-spinner">
      <img
        src="/wp-content/themes/parknfly/resources/assets/images/loading.gif"
        width="100"
        height="100"
      />
    </div>
  </div>
</template>

<script>
/*
  global
    locationsList
    CURRENT_LANGUAGE
    locationAlerts
    window
*/
import { router } from "../components/routes";
import VueTimepicker from "./vue-timepicker.vue";
import Alert from "./warning-banner.vue";
import Content from "../../string_resources/booking.json";
import store from "../components/store";

const Lightpick = require("lightpick");
const moment = require("moment");
moment.locale(CURRENT_LANGUAGE);

export default {
  name: "booking",
  components: { VueTimepicker },
  data() {
    return {
      content: Content[CURRENT_LANGUAGE],
      locations: locationsList,
      activeLocation: locationsList.filter((l) => l.active)[0],
      isDropOffPicker: true,
      discount: null,
      keyboardFocus: false,
      selectedDropOffTime: this.initDropOffTime(),
      selectedPickUpTime: this.initPickUpTime(),
      dropOffPickr: null,
      calCoordinates: { x: 0, y: 0 },
      calendarLoaded: false,
      isReferralCoupon: this.$store
        ? this.$store.getters.getAuthUserRole === "corporate" ||
          this.$store.getters.getAuthUserRole === "agent"
        : false,
      toastMessage: "",
    };
  },
  computed: {
    displayDropOffTime() {
      return moment(this.selectedDropOffTime);
    },
    displayPickUpTime() {
      return moment(this.selectedPickUpTime);
    },
    selectedCity() {
      return this.activeLocation.city;
    },
    selectedAirport() {
      return this.activeLocation.airport;
    },
    loading() {
      return this.$store.getters.getLoading;
    },
  },
  methods: {
    initPickUpTime: function () {
      const pickUp = this.$store.getters.getPickUp
        ? moment(this.$store.getters.getPickUp, "YYYY-MM-DD HH:mm").toDate()
        : moment().add(7, "day").set("minute", 0).set("second", 0).toDate();
      return pickUp;
    },
    initDropOffTime: function () {
      const dropOff = this.$store.getters.getDropOff
        ? moment(this.$store.getters.getDropOff, "YYYY-MM-DD HH:mm").toDate()
        : moment().set("minute", 0).set("second", 0).toDate();
      return dropOff;
    },
    handlePreviousTime: function () {
      return new Promise((resolve, reject) => {
        if (moment(this.selectedDropOffTime).isBefore(moment())) {
          this.selectedDropOffTime = moment(this.selectedDropOffTime)
            .add(30, "minutes")
            .toDate();

          if (
            moment(this.selectedPickUpTime).isBefore(
              moment(this.selectedDropOffTime)
            )
          ) {
            reject(this.content.checkTimes);
          } else {
            resolve(this.handlePreviousTime());
          }
        } else {
          resolve();
        }
      });
    },
    handleTimeSelect: function (val, isDropOffPicker) {
      // Handle 12PM/AM
      val.data.hh = val.data.hh == 12 ? (val.data.hh = 0) : val.data.hh;
      // Translate pm toggle
      if (val.data.a == "pm") val.data.hh = parseInt(val.data.hh) + 12;
      if (isDropOffPicker) {
        this.selectedDropOffTime = this.displayDropOffTime
          .set({ hour: val.data.hh, minute: val.data.mm })
          .toDate();
      } else {
        this.selectedPickUpTime = this.displayPickUpTime
          .set({ hour: val.data.hh, minute: val.data.mm })
          .toDate();
      }
    },
    refreshStorageState: function () {
      let coupon = this.$store.getters.getCoupon;
      this.toastMessage = this.$store.getters.getToastMessage;
      this.discount = null;

      if (this.$store.getters.getAuthUserRole == "member" && coupon != 0) {
        this.discount = coupon;
      }
      if (this.$store.getters.getAuthUserRole !== "member") {
        this.$store.dispatch("deleteStoreState", { root: true });
      }

      this.$store.dispatch("clearQuotes", { root: true });
    },
    getQuote: function (evt) {
      evt.preventDefault();

      this.$store.dispatch("clearQuotes", { root: true });

      let discount = this.discount || "0";
      discount = discount.toString();

      const city = this.selectedCity;
      const airport = this.selectedAirport;
      this.$store.commit("LOADING");

      this.handlePreviousTime()
        .then(() => {
          const dropOff = this.displayDropOffTime.format("YYYY-MM-DD HH:mm");
          const pickUp = this.displayPickUpTime.format("YYYY-MM-DD HH:mm");
          this.$store.dispatch("setDropOff", dropOff);
          this.$store.dispatch("setPickUp", pickUp);
          this.$store.dispatch("setCity", city);
          this.$store.dispatch("setCoupon", discount);
          this.$store.dispatch("setAirport", airport);

          this.$store.dispatch("setQuotes").then(
            (response) => {
              this.$store.commit("LOADED");
              if (response.data.result.status == "OK") {
                router.replace("/quote");
              } else {
                if (
                  response.data.result.errors &&
                  response.data.result.errors.length > 0
                ) {
                  var errors = response.data.result.errors;
                  var errorsMessages = "";
                  for (var i = 0; i < errors.length; i++) {
                    errorsMessages += `<span class="english-error">${errors[0].messageEnglish}</span><span class="french-error">${errors[0].messageFrench}</span>`;
                    if (i < errors.length - 1) {
                      errorsMessages += "<br/>";
                    }
                  }
                  Alert.warn(errorsMessages);
                } else {
                  Alert.warn(this.content.genericErrorPrompt);
                }
              }
            },
            (error) => {
              this.$store.commit("LOADED");
              Alert.warn(this.content.genericErrorPrompt + "<br/>" + error);
            }
          );
        })
        .catch((error) => Alert.warn(error, 10));
    },
    changeLocation: function (newCity) {
      // Update the global "active" city
      locationsList.forEach((aLocation) => {
        aLocation.active = aLocation.city === newCity ? true : false;
      });
    },
    triggerPicker: function (id, type) {
      var el = document.getElementById(id);
      const _this = this;
      this.calCoordinates = el.parentNode.parentNode.getBoundingClientRect();
      this.isDropOffPicker = id == "FieldDropOff";
      if (this.isDropOffPicker) {
        this.dropOffPickr.el.classList.remove("pickup-picker");
        this.dropOffPickr.el.classList.add("dropoff-picker");
      } else {
        this.dropOffPickr.el.classList.remove("dropoff-picker");
        this.dropOffPickr.el.classList.add("pickup-picker");
      }
      type === "click"
        ? el.click()
        : (() => {
            el.click();
            _this.focusPicker();
          })();
    },
    focusPicker: function () {
      const _this = this;
      this.keyboardFocus = true;
      $(
        ".lightpick__day.is-available, .lightpick__days, .time-picker-inner-container button.lightpick__close-action"
      ).attr("tabindex", 0);
      $(this.dropOffPickr.el).attr("tabindex", -1);
      $(".lightpick__day.is-available")
        .off("click")
        .off("keypress")
        .on("keypress", (event) => {
          if (event.which == 32) {
            event.preventDefault();
            $(event.target).click();
          }
        })
        .on(
          "click",
          (event) => {
            let el = event.target;
            let etype = "mousedown";
            if (el.fireEvent) {
              el.fireEvent("on" + etype);
            } else {
              var evObj = document.createEvent("Events");
              evObj.initEvent(etype, true, false);
              el.dispatchEvent(evObj);
            }
            $(".time-picker-inner-container .apms button").first().focus();
          },
          { passive: true }
        );
      $(".time-picker-inner-container button.lightpick__close-action")
        .attr("aira-label", "Confirm Date")
        .off("keypress")
        .on("keypress", (event) => {
          event.preventDefault();
          if (event.which == 32 || event.which == 13) {
            _this.dropOffPickr.hide();
          } else if (event.which == 9) {
            event.preventDefault();
            this.dropOffPickr.el.focus();
          }
        });
      this.dropOffPickr.el.focus();
    },
  },
  beforeCreate: function () {
    this.$store = store;
  },
  beforeMount: function () {
    document.getElementsByClassName("app-container")[0].classList.add("has-bg");
    this.refreshStorageState();
  },
  mounted: function () {
    var url = window.location.href;
    var name = "coupon";
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);

    if (results && results[2]) {
      const couponId = decodeURIComponent(results[2].replace(/\+/g, " "));
      this.discount = couponId;
      sessionStorage.setItem("discount", couponId);
    }
    var sessionDiscount = sessionStorage.getItem("discount");
    if (sessionDiscount) {
      this.discount = sessionDiscount;
    }

    // Populate location alert
    if (locationAlerts.length) {
      Alert.warn(locationAlerts, 30);
    }
    if (this.toastMessage) {
      Alert.toast(this.toastMessage, 10);
    }

    // Refresh carousel on reloads
    $("#feature-carousel").slick("refresh");
    let $this = this;

    const initCalendar = function () {
      // Remove last letter from date label since lightpick doesn't have access to this configuration
      var labels = document.getElementsByClassName(
        "lightpick__day-of-the-week"
      );
      for (var i = 0; i < labels.length; i++) {
        labels[i].innerHTML = labels[i].innerHTML.substring(0, 2);
      }
    };

    let createHoverMask = function () {
      const dpick = $this.dropOffPickr.el;

      const cleanUpSelectedDate = function () {
        let startDate = dpick.getElementsByClassName("is-start-date");
        let endDate = dpick.getElementsByClassName("is-end-date");
        if ($this.isDropOffPicker && startDate.length) {
          startDate[0].classList.remove("is-start-date");
        } else if (!$this.isDropOffPicker && endDate.length) {
          endDate[0].classList.remove("is-end-date");
        }
      };
      if (/Mobi|Android/i.test(navigator.userAgent)) {
        cleanUpSelectedDate();

        const start = $this.dropOffPickr.getStartDate();
        const end = $this.dropOffPickr.getEndDate();
        $this.dropOffPickr.setDateRange(start, end, true);
        return;
      }

      // Init hover toggle above day picking element
      var daysContainer = dpick.getElementsByClassName("lightpick__days")[0];

      var hoverMask = document.createElement("div");
      hoverMask.id = "hover-mask";
      daysContainer.appendChild(hoverMask);

      // Disable hover mask if clicked
      requestAnimationFrame(function () {
        hoverMask.onclick = function () {
          setTimeout(function () {
            cleanUpSelectedDate();
            document.getElementById("hover-mask").remove();
          }, 20);
        };
      });
    };

    $this.dropOffPickr = new Lightpick({
      field: document.getElementById("FieldDropOff"),
      secondField: document.getElementById("FieldPickup"),
      repick: true,
      lang: `${CURRENT_LANGUAGE}-ca`,
      autoclose: false,
      startDate: $this.displayDropOffTime,
      endDate: $this.displayPickUpTime,
      minDate: moment(),
      dropdowns: {
        years: {
          min: moment().year(),
          max: moment().add(5, "year").year(),
        },
        months: true,
      },
      locale: {
        buttons: {
          prev: '<span class="icon-up-chevron"></span>',
          next: '<span class="icon-down-chevron"></span>',
          close: "&times;",
          reset: "Reset",
          apply: "Apply",
        },
        tooltip: {
          one: "day",
          other: "days",
        },
        tooltipOnDisabled: null,
        pluralize: function (i, locale) {
          if (typeof i === "string") i = parseInt(i, 10);

          if (i === 1 && "one" in locale) return locale.one;
          if ("other" in locale) return locale.other;

          return "";
        },
      },
      hoveringTooltip: false,
      onSelect: function (start, end) {
        // If the start date is before the end date:
        // end date = start date + 7 days
        if (start.isAfter(end)) {
          end = start.clone();
          this.setDateRange(start.toDate(), end.add(7, "day").toDate());
        }
        // On date selection; preserve time
        var dO = moment($this.selectedDropOffTime);
        var pU = moment($this.selectedPickUpTime);
        $this.selectedDropOffTime = start
          .set({
            hour: dO.hour(),
            minute: dO.minute(),
          })
          .toDate();
        $this.selectedPickUpTime = end
          .set({
            hour: pU.hour(),
            minute: pU.minute(),
          })
          .toDate();
        createHoverMask();
      },
      onOpen: function () {
        var dpick = $this.dropOffPickr.el;
        dpick.style.left =
          window.pageXOffset + $this.calCoordinates.left + "px";
        dpick.style.top =
          $(window).width() < 700
            ? window.pageYOffset + "px"
            : window.pageYOffset + $this.calCoordinates.top + "px";
        initCalendar();
      },
      onClose: function () {
        // Re-enable hover styling for dates
        var daysContainer =
          $this.dropOffPickr.el.getElementsByClassName("lightpick__days")[0];
        daysContainer.classList.remove("no-date-hover");
        if (this.keyboardFocus) {
          $this.isDropOffPicker
            ? $("#pickup-field-label")[0].focus()
            : $("#coupon-field-label")[0].focus();
        }
      },
    });
    // Override nextMonth / prevMonth to trigger initCalendar
    $this.dropOffPickr.__proto__._nextMonth =
      $this.dropOffPickr.__proto__.nextMonth;
    $this.dropOffPickr.__proto__._prevMonth =
      $this.dropOffPickr.__proto__.prevMonth;
    $this.dropOffPickr.__proto__.nextMonth = function () {
      $this.dropOffPickr._nextMonth();
      initCalendar();
    };
    $this.dropOffPickr.__proto__.prevMonth = function () {
      $this.dropOffPickr._prevMonth();
      initCalendar();
    };

    // Inject time selector in datepicker
    $this.dropOffPickr.el.appendChild($this.$refs.dropOffTimeSelector.$el);
    $this.dropOffPickr.el.appendChild($this.$refs.pickUpTimeSelector.$el);

    let timeBtn = document.querySelectorAll(".confirm-time-button");
    if (timeBtn.length) {
      timeBtn.forEach(function (e) {
        e.onmouseup = e.ontouchend = $this.dropOffPickr.hide;
      });
    }

    // Fix for bug in lightpick that doesn't detect if the potential start date (on hover)
    // is after the end date. Restyles the end date accordingly
    function dateStyleOverride(e) {
      requestAnimationFrame(() => {
        e = e || window.event;
        var target = e.target || e.srcElement;
        if (!target) {
          return;
        }
        if (!target.classList.contains("lightpick__day")) {
          return;
        }
        // Clean up sentinal flipping class
        if (!$this.isDropOffPicker) {
          return;
        }
        var endDate = document.getElementsByClassName("is-end-date")[0];
        if (endDate != undefined) {
          endDate.classList.remove("is-flipped-override");
        }
        if (
          new Date(parseInt(target.getAttribute("data-time"))) >
            $this.selectedPickUpTime &&
          endDate != undefined
        ) {
          endDate.classList.add("is-flipped-override");
        }
      });
    }

    $this.dropOffPickr.el.onmouseover = dateStyleOverride;

    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  },
  beforeDestroy: function () {
    document
      .getElementsByClassName("app-container")[0]
      .classList.remove("has-bg");
    $("section.lightpick").remove();

    // Reset picker prototype to original state
    this.dropOffPickr.__proto__.nextMonth =
      this.dropOffPickr.__proto__._nextMonth;
    this.dropOffPickr.__proto__.prevMonth =
      this.dropOffPickr.__proto__._prevMonth;
  },
};
</script>

