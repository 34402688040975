import store from '../components/store';
import Vue from 'vue';
import axios from 'axios';
import AdditionalServices from '../components/additional-services.vue';

export default {
  init() {
    axios
    .post(
      "/wp-admin/admin-ajax.php?action=pnf_get_parking_lots",
      {},
      { timeout: 10000 }
    )
    .then(result => {
      window.parkingLotsList = result.data;
      let componentElements = document.querySelectorAll(".additional-services-component");
      componentElements.forEach(a => {
        new Vue({
           components: {AdditionalServices},
           el: a,
           store,
           template: `<additional-services locationId="${a.attributes.locationid.value}" displayOnly></additional-services >`,
         });
      })
    });
  },
  finalize() {
  },
};
