<template>
  <div class="tabbed-section w-100">
    <div class="row">
      <div class="col-sm-12">
        <ul
          class="nav nav-pills nav-pills--btn justify-content-flex-start pr-4 pl-4 row"
          id="pills-tab"
          role="tablist"
        >
          <template v-for="(parkingLot, index) in parkingLots">
            <li v-bind:key="parkingLot.ID" class="nav-item col-12 col-md-6 col-lg-3">
              <a
                :class="['nav-link', {'active' : index == 0} ]"
                v-bind:id="parkingLot | formatOfferId | isTab"
                data-toggle="pill"
                v-bind:href="parkingLot | formatOfferId | isId"
                role="tab"
                v-bind:aria-controls="parkingLot | formatOfferId"
                aria-selected="true"
              >{{parkingLot | formatOfferName}}</a>
            </li>
          </template>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <template v-for="(parkingLot, index) in parkingLots">
            <div
              v-bind:key="parkingLot.ID"
              :class="['tab-pane', 'fade', {'show active': index == 0}]"
              :id="parkingLot | formatOfferId"
              role="tabpanel"
              :aria-labelledby="parkingLot | formatOfferId | isTab"
            >
              <div class="col-sm-12 tabbed-subsection padding-fix tab-content">
                <p class="subsection-intro pl-4 pr-4" v-html="parkingLot.description"></p>
                <ul
                  class="nav nav-pills mb-3 green-underline-link"
                  :id="'pills-tab-'+index"
                  role="tablist"
                >
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      :id="'pills-promo-tab-'+index"
                      data-toggle="pill"
                      :href="'#pills-promo-'+index"
                      role="tab"
                      :aria-controls="'pills-promo-'+index"
                      aria-selected="true"
                    >{{content.locationInfo}}</a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      :id="'pills-pnfa-tab-'+index"
                      data-toggle="pill"
                      :href="'#pills-pnfa-'+index"
                      role="tab"
                      :aria-controls="'pills-pnfa-'+index"
                      aria-selected="false"
                    >{{content.discover}} {{parkingLot | formatOfferType}}</a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      :id="'pills-valet-tab-'+index"
                      data-toggle="pill"
                      :href="'#pills-valet-'+index"
                      role="tab"
                      :aria-controls="'pills-valet-'+index"
                      aria-selected="false"
                    >{{content.vehicleDropOff}}</a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      :id="'pills-sp-tab-'+index"
                      data-toggle="pill"
                      :href="'#pills-sp-'+index"
                      role="tab"
                      :aria-controls="'pills-sp-'+index"
                      aria-selected="false"
                    >{{content.vehiclePickUp}}</a>
                  </li>
                  <li class="nav-item" v-if="parkingLot.valet_services">
                    <a
                      class="nav-link"
                      :id="'pills-services-tab-'+index"
                      data-toggle="pill"
                      :href="'#pills-services-'+index"
                      role="tab"
                      :aria-controls="'pills-services-'+index"
                      aria-selected="false"
                    >{{content.additionalServices}}</a>
                  </li>
                </ul>
                <div class="tab-content" id="pills-childTabContent">
                  <div
                    class="tab-pane no-pad fade show active"
                    :id="'pills-promo-'+index"
                    role="tabpanel"
                    :aria-labelledby="'pills-promo-tab-'+index"
                  >
                    <div class="row mx-auto mx-md-0 pl-md-4">
                      <div class="col-md-6 col-lg-4">
                        <div>
                          <img :src="parkingLot.promo_image" class="img-fluid my-4" />
                          <h3>{{parkingLot | formatLocationTitle}}</h3>
                          <p id="location-address">{{parkingLot.address}}</p>
                          <p>
                            <a
                              :href="parkingLot.directions_link"
                              class="blue-link"
                              target="_blank"
                            >{{content.getDirections}}</a>
                          </p>
                          <p>{{content.contactSupport}} <a rel="nofollow" :href="activeLocation.customer_service_number | formatTelLink">{{activeLocation.customer_service_number}}</a> {{content.contactSupport2}}</p>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4">
                        <div>
                          <figure class="m-0">
                            <a :href="parkingLot.directions_link" class="blue-link" target="_blank">
                              <img :src="parkingLot.map_image" class="img-fluid" />
                            </a>
                          </figure>
                        </div>
                      </div>
                      <div class="col-12 col-lg-4 trip-info-section">
                        <div class="d-flex justify-content-around row h-100 p-4">
                          <div class="trip-info-vertical --start mb-4 mb-sm-0 col-12 col-sm-6 col-lg-12">
                            <div class="info-container">
                              <p class="info-label">{{content.shuttleInfo[0]}}</p>
                              <p class="minutes-label">{{parkingLot.shuttle_frequency}}</p>
                              <p class="subtext-label small-text">{{content.shuttleInfo[1]}}</p>
                            </div>
                            <div class="icon-container">
                              <span class="--airplane icon-pnf-247-shuttle"></span>
                            </div>
                          </div>
                          <div class="trip-info-vertical --spacer d-none d-lg-block"></div>
                          <div class="trip-info-vertical --end col-12 col-sm-6 col-lg-12">
                            <div class="info-container">
                              <p class="info-label">{{content.travelTime[0]}}</p>
                              <p class="minutes-label">{{parkingLot.travel_time}}</p>
                              <p class="subtext-label">{{content.travelTime[1]}}</p>
                            </div>
                            <div class="icon-container">
                              <span class="--airplane icon-pnf-airport">
                                <svg id="icon-pnf-airport" viewBox="0 0 32 32">
                                  <path d="M18.169 1.991c0-2.738-3.698-2.631-3.698 0.071v9.458l-14.471 8.427v3.804l14.4-4.48v7.111l-3.342 2.596v3.022l5.12-1.564 5.156 1.564v-2.987l-3.307-2.596v-7.111l14.222 4.516v-3.804l-14.080-8.32v-9.707z"></path>
                                </svg>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    :id="'pills-pnfa-'+index"
                    role="tabpanel"
                    :aria-labelledby="'pills-pnfa-tab-'+index"
                  >
                    <h3>{{content.discover}} {{parkingLot | formatOfferName}}</h3>
                    <p class="mb-4 pl-0 w-75">{{parkingLot.discover_intro}}</p>
                    <div class="row mt-4">
                      <div class="col-sm-4">
                        <div>
                          <figure>
                            <img :src="parkingLot.discover_image" class="img-fluid" alt />
                          </figure>
                        </div>
                      </div>
                      <div class="col-sm-8">
                        <div class="row h-100">
                          <div class="col-sm-12">
                            <div class="feature-list">
                              <template v-for="feature in parkingLot.lot_features">
                                <div v-bind:key="feature.title" class="feature-item">
                                  <span :class="'--feature '+feature.icon"></span>
                                  <span>
                                    <strong v-html="feature.title"></strong>
                                    <span v-html="feature.description"></span>
                                  </span>
                                </div>
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    :id="'pills-valet-'+index"
                    role="tabpanel"
                    :aria-labelledby="'pills-valet-tab-'+index"
                  >
                    <div class="row">
                      <div class="col-sm-7">
                    <h3>{{content.vehicleDropOff}}</h3>
                    <p>{{parkingLot.vehicle_drop_off_intro}}</p>
                        <figure class="my-4">
                          <img
                            :src="parkingLot.departure_tips_image_desktop"
                            class="img-fluid d-none d-md-block"
                          />
                          <img
                            :src="parkingLot.departure_tips_image_mobile"
                            class="img-fluid d-block d-md-none"
                          />
                        </figure>
                      </div>
                      <div class="col-sm-5">
                        <h3>{{content.departureTips}}</h3>
                        <div class="list-line-height" v-html="parkingLot.departure_tips_text"></div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    :id="'pills-sp-'+index"
                    role="tabpanel"
                    aria-labelledby="pills-sp-tab"
                  >
                    <div class="row">
                      <div class="col-sm-7">
                        <h3>{{content.vehiclePickUp}}</h3>
                        <p>{{parkingLot.vehicle_pick_up_intro}}</p>
                        <figure class="my-4">
                          <img
                            :src="parkingLot.arrival_tips_image_desktop"
                            class="img-fluid d-none d-md-block"
                          />
                          <img
                            :src="parkingLot.arrival_tips_image_mobile"
                            class="img-fluid d-block d-md-none"
                          />
                        </figure>
                      </div>
                      <div class="col-sm-5">
                        <h3>{{content.arrivalTips}}</h3>
                        <div class="list-line-height" v-html="parkingLot.arrival_tips_text"></div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    :id="'pills-services-'+index"
                    role="tabpanel"
                    :aria-labelledby="'pills-services-tab-'+index"
                    v-if="parkingLot.valet_services"
                  >
                    <h3>{{content.additionalServices}}</h3>
                    <p class="mb-5 w-75">{{content.additionalServicesDescription}}</p>
                    <div class="row">
                      <div class="col-sm-6">
                        <div>
                          <figure>
                            <a v-if="language == 'en'" href="/wash-and-detailing-services/" class="blue-link">
                              <img
                                src="/wp-content/uploads/2019/10/Additional-Services-Car-Wash.jpg"
                                class="img-fluid"
                                alt
                              />
                            </a>
                            <a v-else href="/fr/services-de-lavage-et-desthetiques/" class="blue-link">
                              <img
                                src="/wp-content/uploads/2019/10/Additional-Services-Car-Wash.jpg"
                                class="img-fluid"
                                alt
                              />
                            </a>
                          </figure>
                        </div>
                        <h3>{{content.services.exteriorWash}}</h3>
                        <p>{{content.services.exteriorWashDescription}}</p>
                        <p>
                          <a v-if="language == 'en'" href="/wash-and-detailing-services/" class="blue-link">
                              {{content.learnMore}}
                            </a>
                            <a v-else href="/fr/services-de-lavage-et-desthetiques/" class="blue-link">
                              {{content.learnMore}}
                            </a>
                        </p>
                      </div>
                      <div class="col-sm-6" v-if="hasOilChangeService">
                        <div>
                          <figure>
                            <a v-if="language == 'en'" href="/oil-changes/" class="blue-link">
                              <img
                                src="/wp-content/uploads/2019/10/Additional-Services-Oil-Change.jpg"
                                class="img-fluid"
                                alt
                              />
                            </a>
                            <a v-else href="/fr/vidanges-dhuile/" class="blue-link">
                              <img
                                src="/wp-content/uploads/2019/10/Additional-Services-Oil-Change.jpg"
                                class="img-fluid"
                                alt
                              />
                            </a>
                          </figure>
                        </div>
                        <h3>{{content.services.oilChange}}</h3>
                        <p>{{content.services.oilChangeDescription}}</p>
                        <p>
                            <a v-if="language == 'en'" href="/oil-changes/" class="blue-link">
                              {{content.learnMore}}
                            </a>
                            <a v-else href="/fr/vidanges-dhuile/" class="blue-link">
                              {{content.learnMore}}
                            </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/*
  global
    parkingLotsList
    CURRENT_LANGUAGE
*/
import Content from "../../string_resources/location-details.json";
import {OfferFilters} from '../util/shared';
import store from "../components/store";
export default {
  name: "location-details",
  data() {
    return {
      content: Content[CURRENT_LANGUAGE],
      selectedOption: false,
      activeLocation: this.$store.getters.getLocation,
      language: CURRENT_LANGUAGE,
    };
  },
  computed: {
    parkingLots() {
      return parkingLotsList
        .filter(d => d.location.ID == this.activeLocation.ID)
        .reverse();
    },
    hasOilChangeService() {
      return ["Winnipeg", "Montreal"].indexOf(this.activeLocation.city) < 0;
    },
  },
  filters: {
    formatOfferName: val => OfferFilters[CURRENT_LANGUAGE].formatOfferName(val),
    formatOfferId: val => OfferFilters[CURRENT_LANGUAGE].formatOfferId(val),
    formatOfferType: val => OfferFilters[CURRENT_LANGUAGE].formatOfferType(val),
    formatLocationTitle: val => OfferFilters[CURRENT_LANGUAGE].formatLocationTitle(val),
    formatTelLink: function(val) {
      return 'tel:' + (val ? val.match(/\d\w+/g).join("") : "")
    },
    isTab: function(val) {
      return val + "-tab";
    },
    isId: function(val) {
      return "#" + val;
    },
  },
  mounted() {
    $(function() {
      $("[href$=additionalServicesTab]").on("click", function(e) {
        e.preventDefault();
        var activeServicesTab = $("a[href^=#pills-services]")
          .filter(":visible")
          .first();
        if (activeServicesTab.length > 0) {
          activeServicesTab.click();
          $([document.documentElement, document.body]).animate(
            {
              scrollTop: $(activeServicesTab).offset().top,
            },
            500
          );
        }
      });
    });

  },
  beforeCreate: function () {
    this.$store = store;
  },
};
</script>
