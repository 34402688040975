<template v-if="currentUser">
  <div
    class="member-widget-container"
    data-container="body"
    data-toggle="popover"
    data-placement="bottom"
    data-trigger="click"
    data-html="true"
    :data-target="navType.target"
    :data-ref="navType.ref"
  >
    <div id="popover-widget-container">
      <div class="popover-widget" :style="arrowShown">
        <div class="arrow"></div>
      </div>
    </div>
    <div class="welcome-msg-container">
      <div class="welcome-msg truncate">{{ firstName }}</div>
      <div
        class="points-msg"
        v-if="rewardsPoints !== null && userRole == 'member'"
      >
        <!-- Green -->
        <div v-if="currentTier === 1">
          <div v-if="rewardsPoints === 0">
            {{ content.staysGold[0] }}
            <strong>{{ staysNeeded }}</strong>
            {{ content.staysGold[1] }}
          </div>
          <div v-if="rewardsPoints > 0">
            {{ content.points[0] }}
            <strong>{{ parsePoints }}</strong>
            {{ content.points[1] }}
          </div>
        </div>
        <!-- Gold / Plat -->
        <div v-if="currentTier === 2 || currentTier === 3">
          {{ content.points[0] }}
          <strong>{{ parsePoints }}</strong>
          {{ content.points[1] }}
        </div>
      </div>
    </div>
    <div class="member-menu-container">
      <div class="user-initial-container">
        <img
          class="green"
          src="/wp-content/themes/parknfly/resources/assets/images/pnf-green.svg"
        />
        <img
          class="gold"
          src="/wp-content/themes/parknfly/resources/assets/images/pnf-gold.svg"
        />
        <img
          class="platinum"
          src="/wp-content/themes/parknfly/resources/assets/images/pnf-platinum.svg"
        />
      </div>
    </div>
  </div>
</template>

<script>
/*
  global
    CURRENT_LANGUAGE
    window
*/

import GetUser from "../util/users";
import Content from "../../string_resources/member-widget.json";
import { showTierUI } from "../util/shared";
import store from "../components/store";
export default {
  name: "member-widget",
  data() {
    return {
      content: Content[CURRENT_LANGUAGE],
      popover: "",
      user: null,
      signOutBound: false,
      popoverVisible: false,
      currentTier: null,
    };
  },
  beforeCreate: function () {
    this.$store = store;
  },
  methods: {
    bindSignOut: function () {
      let $this = this;
      if (!this.signOutBound) {
        // Popover (Member Widget)
        jQuery(".member-widget-container").each(function (index, element) {
          var contentElementId = jQuery(element).data().target;
          var contentHtml = jQuery(contentElementId).html();
          $this.popover = jQuery(element)
            .popover({
              container: "#popover-widget-container",
              content: contentHtml,
              offset: "-15%, 26px",
            })
            .on("inserted.bs.popover", () => {
              jQuery("a[href$=userSignOut]").each(function (index, element) {
                jQuery(element)
                  .off("click")
                  .on("click", function (event) {
                    event.preventDefault();
                    window.logout();
                  });
              });
            })
            .on("show.bs.popover", () => {
              $this.popoverVisible = true;
            })
            .on("hide.bs.popover", () => {
              $this.popoverVisible = false;
            });
        });
        this.signOutBound = true;

        GetUser().then((user) => {
          this.user = user;
          this.$store.dispatch(
            "setRewardsPoints",
            user.reward_member.points_balance
          );
          this.currentTier = this.user.reward_member.tier_status_id;
          showTierUI(this.currentTier);
          this.$store.dispatch("setTier", this.currentTier);
        });
      }
    },
  },
  filters: {
    firstInitial: function (name) {
      if (name.firstName === "Paid Search Branded") {
        if (CURRENT_LANGUAGE === "en") {
          return "W";
        } else {
          return "B";
        }
      }
      return name ? name.firstName.charAt(0) : " ";
    },
  },
  watch: {},
  computed: {
    firstName: function () {
      var user = this.currentUser;
      if (user.firstName === "Paid Search Branded") {
        return this.content.welcome;
      }
      return user ? user.firstName : " ";
    },
    firstNameInitial: function () {
      var user = this.currentUser;
      if (user.firstName === "Paid Search Branded") {
        return this.content.welcome.charAt(0);
      }
      return user ? user.firstName.charAt(0) : " ";
    },
    parsePoints: function () {
      return this.rewardsPoints
        ? this.rewardsPoints.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : 0;
    },
    rewardsPoints: function () {
      return this.$store.getters.getRewardsPoints;
    },
    staysNeeded: function () {
      return this.user.reward_member.stays_til_promotion;
    },
    userRole: function () {
      return this.$store.getters.getAuthUserRole;
    },
    navType: function () {
      return this.isReferral
        ? { target: "#corporate-nav", ref: "corporate-widget-popover" }
        : { target: "#member-nav", ref: "member-widget-popover" };
    },
    isReferral: function () {
      return this.userRole === "corporate" || this.userRole === "agent";
    },
    currentUser: function () {
      if (this.$store.getters.getAuthState) {
        let name = this.$store.getters.getAuthUser;
        name = name ? decodeURIComponent(name.replace(/\+/g, " ")) : name;

        if (!this.isReferral) {
          name = name.split(" ");
          name = { firstName: name[0], lastName: name[1] };
        } else {
          name = { firstName: name, lastName: "" };
        }
        return name;
      }
    },

    showRewardsMessage: function () {
      return this.rewardsPoints > 0 && this.rewardsPoints != "";
    },
    arrowShown: function () {
      return this.popoverVisible ? "opacity:1" : "opacity:0";
    },
  },
  mounted() {
    this.bindSignOut();
  },
  destroyed() {
    let replacement = document.createElement("div");
    replacement.setAttribute("id", "member-widget");
    this.$el.parentNode.append(replacement);
    this.$el.parentNode.removeChild(this.$el);

    let popover = "#" + this.popover[0].getAttribute("aria-describedby");
    jQuery(popover).remove();
  },
};
</script>
