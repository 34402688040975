<template>
  <section>
    <div class="container my-6">
      <div class="row">
        <div class="col-sm-12 form-container">
          <div
            class="form-header green-underline mb-2 d-flex justify-content-between"
          >
            <h1>
              {{ quote.locationName | formatLocationName }}
              {{ content.offerConfirmation }}
            </h1>
          </div>
          <div class="row inner-form">
            <h3 class="col-sm-12" v-if="showReservationSent">
              {{ content.reservationSent }}
            </h3>
            <p class="col-sm-12">
              {{ content.askSupport[0] }}
              <a
                class="blue-link inherit-font"
                :href="language === 'en' ? '/support/' : '/fr/soutien/'"
                target="_blank"
                >{{ content.askSupport[1] }}</a
              >
            </p>
            <div class="col-sm-12 mb-4 mt-4">
              <a
                class="btn btn--md mr-4 mb-4"
                target="_blank"
                :href="
                  `${
                    language == 'en'
                      ? '/retrieval/confirmation/?retrievalId='
                      : '/fr/recuperer/confirmation/?retrievalId='
                  }` + retrievalId
                "
                >{{ content.print }}</a
              >
              <a
                class="btn btn--md mr-4 mb-4"
                :href="`${language == 'en' ? '/' : '/fr'}`"
                >{{ content.home }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
/*
  global
    CURRENT_LANGUAGE
*/
import { router } from "../components/routes";
import Content from "../../string_resources/thank-you.json";
import GetUser from "../util/users";
import store from "../components/store";

export default {
  name: "thankYouView",
  data() {
    return {
      content: Content[CURRENT_LANGUAGE],
      quote: {},
      confirmationId: 0,
      retrievalId: this.$store.getters.getRetrievalId,
      points: this.$store.getters.getRewardsPoints,
      language: CURRENT_LANGUAGE,
      rewardsSubscribe: null,
      rewardsUpdateRequests: 0,
      showReservationSent: this.$store.getters.getAuthUserRole !== "agent",
    };
  },
  beforeCreate: function () {
    this.$store = store;
  },
  methods: {
    startOver: function (evt) {
      evt.preventDefault();
      router.replace("/default");
    },
    checkRewards: function () {
      GetUser().then((user) => {
        if (
          user.rewards.points !== this.points ||
          this.rewardsUpdateRequests < 10
        ) {
          this.points = user.reward_member.points_balance;
          this.$store.dispatch(
            "setRewardsPoints",
            user.reward_member.points_balance
          );
          clearTimeout(this.rewardsSubscribe);
        } else {
          this.rewardsUpdateRequests++;
          this.rewardsSubscribe = setTimeout(this.checkRewards, 2000);
        }
      });
    },
  },
  filters: {
    formatLocationName(text) {
      text = text.toString();
      let formattedText = text.replace(/^[a-zàâçéèêëîïôûùüÿñæœ]+\s/i, "");
      return formattedText.trim() == ""
        ? text.replace(/^([A-Z]+\w+)([A-Z])/, "$1-$2")
        : formattedText.replace(/^([A-Z]+\w+)([A-Z])/, "$1-$2");
    },
  },
  created() {
    this.quote = this.$store.getters.getSelectedQuote;
    this.confirmationId = this.$store.getters.getConfirmationId;
    this.retrievalId = this.$store.getters.getRetrievalId;
  },
  mounted() {
    this.checkRewards();
  },
};
</script>
