<template>
  <section>
    <div class="header-extension">
      <div class="row field-container container">
        <div class="col-12 col-md-4 col-lg-2 offer__header-field">
          <p class="field__label">{{ content.locationLabel }}</p>
          <p class="booking__label">{{ city }}</p>
          <p class="field__lower-text">{{ airport }}</p>
        </div>
        <div class="col-6 col-md-4 col-lg-2 offer__header-field">
          <p class="field__label">{{ content.dropOffLabel }}</p>
          <p class="booking__label d-none d-sm-block d-md-none d-xxl-block">
            {{ displayDropOffTime.format("MMMM D, YYYY") }}
          </p>
          <p class="booking__label d-block d-sm-none d-md-block d-xxl-none">
            {{ displayDropOffTime.format("MM/D/YY") }}
          </p>
          <p class="field__lower-text">
            {{ displayDropOffTime.format("h:mm A") }}
          </p>
        </div>
        <div class="col-6 col-md-4 col-lg-2 offer__header-field">
          <p class="field__label">{{ content.pickUpLabel }}</p>
          <p class="booking__label d-none d-sm-block d-md-none d-xxl-block">
            {{ displayPickUpTime.format("MMMM D, YYYY") }}
          </p>
          <p class="booking__label d-block d-sm-none d-md-block d-xxl-none">
            {{ displayPickUpTime.format("MM/D/YY") }}
          </p>
          <p class="field__lower-text">
            {{ displayPickUpTime.format("h:mm A") }}
          </p>
        </div>
        <div
          v-if="!isReferralCoupon"
          class="col-6 col-md-4 col-lg-2 offer__header-field"
        >
          <p class="field__label">{{ content.caaLabel }}</p>
          <p class="booking__label">{{ coupon }}</p>
          <!--TODO: Make CAA membership clear work -->
          <a v-if="coupon != 'N/A'" href="#" class="clear-discount blue-link">{{
            content.clearButtonText
          }}</a>
        </div>
        <div
          v-if="isReferralCoupon"
          class="col-6 col-md-4 col-lg-2 offer__header-field"
        >
          <label
            for="RemoveReferral"
            v-html="content.referralCouponLabel"
            class="field__label"
          ></label>
          <label for="FieldDropOff" class="booking__label">{{
            content.preferredRatesApplied
          }}</label>
        </div>
        <div
          class="col-6 col-md-4 col-lg-2 align-self-center offer__header-field"
        >
          <router-link to="/" class="btn btn--big btn--blue">{{
            content.modify
          }}</router-link>
        </div>
      </div>
    </div>
    <div v-show="loading" class="text-center mt-5">
      <h3 class="mb-3">{{ content.loading }}</h3>
      <img
        src="/wp-content/themes/parknfly/resources/assets/images/loading.gif"
        width="100"
        height="100"
      />
    </div>
    <div v-show="!loading" class="row">
      <div v-if="quotes !== null && quotes.length == 0" class="col-12">
        <p
          class="h2 quote-error text-center text-danger"
          style="max-width: 600px"
          v-html="content.notAvailable"
        ></p>
      </div>

      <div
        v-for="(quote, index) in quotes"
        v-bind:key="quote.locationId"
        class="col-xl-3 col-md-6 col-12 mt-2 offer-container"
      >
        <div
          :class="[
            'offer',
            quote.locationName.toLowerCase().replace(/\s+/g, '-'),
            { 'highlighted-quote': index == 0 },
          ]"
          @mouseenter="highlightQuote"
        >
          <h1 class="mb-1">
            {{ replaceLocationName(quote.locationId) | formatOfferType }}
          </h1>
          <p class="mb--2">
            {{ content.savings[0] }}
            <strong>{{ quote.savings | formatSavings }}</strong>
            {{ content.savings[1] }}
          </p>
          <p class="offer__price mb--3">
            <sup>$</sup>{{ quote.netParking | formatNetParkingDollar
            }}<sup class="price-cents">{{
              quote.netParking | formatNetParkingCents
            }}</sup>
          </p>
          <p class="offer__fine-print">{{ content.taxesFuelFinePrint }}</p>
          <div class="offer__btn-holder">
            <a
              href="#0"
              class="btn btn--big"
              tabindex="0"
              v-on:click="getReserve(quote.quoteId, $event)"
            >
              {{ content.choose }}
              {{ replaceLocationName(quote.locationId) | formatOfferType }}
            </a>
          </div>
          <div class="offer__features" :ref="`offer-el-${index}`">
            <p
              class="offer__feature-list m-3"
              v-html="getParkingLotDescription(quote.locationId)"
            ></p>
            <div class="offer__trigger">
              <a
                href="#0"
                :ref="`show-details-${index}`"
                @click="showDetailsPane(quote, index)"
              >
                {{ content.viewDetails }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="!loading"
      class="offer-detail offer-pane-1 container-fluid"
      :ref="'offer-details-el'"
    >
      <div class="row margin-fix">
        <div class="col-12 col-xl-4 px-0 row margin-fix">
          <div class="offer-detail__location col-12 col-md-6 col-xl-12">
            <h3 class="mb-2">{{ selectedParkingLot | formatLocationTitle }}</h3>
            <p>{{ selectedParkingLot.address }}</p>
            <a
              :href="selectedParkingLot.directions_link"
              class="my-3 blue-link"
              target="_blank"
              >{{ content.getDirections }}</a
            >
          </div>
          <figure class="offer-detail__map col-12 col-md-6 col-xl-12">
            <img :src="selectedParkingLot.map_image" class="img-fluid" />
          </figure>
        </div>
        <div class="col-12 col-xl-8">
          <div class="offer-detail__location-info mb-4">
            <div
              class="d-flex justify-content-around align-items-center row mx-0 h-100"
            >
              <div class="trip-info-horizontal --start col-12 col-lg-6">
                <div class="info-container">
                  <p class="info-label">
                    {{ content.shuttleDepartureDetails[0] }}
                  </p>
                  <p class="minutes-label">
                    {{ selectedParkingLot.shuttle_frequency }}
                  </p>
                  <p class="subtext-label small-text">
                    {{ content.shuttleDepartureDetails[1] }}
                  </p>
                </div>
                <div class="icon-container">
                  <span class="--airplane icon-pnf-247-shuttle"></span>
                </div>
              </div>
              <div
                class="trip-info-horizontal --spacer col d-none d-lg-block"
              ></div>
              <div class="trip-info-horizontal --end col-12 col-lg-5">
                <div class="icon-container">
                  <span class="--airplane icon-pnf-airport">
                    <svg id="icon-pnf-airport" viewBox="0 0 32 32">
                      <path
                        d="M18.169 1.991c0-2.738-3.698-2.631-3.698 0.071v9.458l-14.471 8.427v3.804l14.4-4.48v7.111l-3.342 2.596v3.022l5.12-1.564 5.156 1.564v-2.987l-3.307-2.596v-7.111l14.222 4.516v-3.804l-14.080-8.32v-9.707z"
                      ></path>
                    </svg>
                  </span>
                </div>
                <div class="info-container">
                  <p class="info-label">{{ content.travelTimeDetails[0] }}</p>
                  <p class="minutes-label">
                    {{ selectedParkingLot.travel_time }}
                  </p>
                  <p class="subtext-label">
                    {{ content.travelTimeDetails[1] }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="offer-detail__features pl-4">
            <h3>{{ content.featuresLabel }}</h3>
            <div class="col-sm-12 mt-3">
              <div class="row h-100">
                <div class="col-sm-11 padding-fix">
                  <div class="feature-list">
                    <template
                      v-for="feature in selectedParkingLot.lot_features"
                    >
                      <div v-bind:key="feature.title" class="feature-item pb-3">
                        <span :class="'--feature ' + feature.icon"></span>
                        <span>
                          <strong v-html="feature.title"></strong>
                          <span v-html="feature.description"></span>
                        </span>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
/*
  global
    parkingLotsList
    CURRENT_LANGUAGE
    innerWidth
*/
import { router } from "../components/routes";
const Moment = require("moment");
import Content from "../../string_resources/quote.json";
import { OfferFilters } from "../util/shared";
import store from "../components/store";
Moment.locale(CURRENT_LANGUAGE);
export default {
  name: "quoteView",
  data() {
    return {
      content: Content[CURRENT_LANGUAGE],
      currentQuote: {
        locationName: "",
      },
      isMobile: innerWidth <= 400,
      activeLocation: this.$store.getters.getLocation,
      isReferralCoupon:
        this.$store.getters.getAuthUserRole === "corporate" ||
        this.$store.getters.getAuthUserRole === "agent",
    };
  },
  methods: {
    /* Temporary Placement to execute View Change */
    getReserve: function (quoteId, evt) {
      evt.preventDefault();
      this.$store.dispatch("setSelectedQuote", quoteId);
      router.replace("/reserve");
    },
    replaceLocationName(locationId) {
      let lot = this.parkingLots.find((x) => x.location_id == locationId);
      return lot;
    },
    showDetailsPane: function (quote, idx) {
      this.currentQuote = quote;

      if (this.isMobile) {
        this.removeInjectedDetailsPane();
        this.injectDetailsPane(idx);
      } else {
        let display_pane = document.getElementsByClassName("offer-detail")[0];
        display_pane.className = "offer-detail offer-pane-" + ++idx;
        display_pane.style.display = "block";
        // Scroll to element and up a bit to give users context
        window.scrollTo(0, display_pane.offsetTop - 100);
      }
    },
    injectDetailsPane: function (idx) {
      let showDetailsButton = this.$refs[`show-details-${idx}`][0];
      // If showDetailsButton isn't "Hide Details" then inject details panel
      if (showDetailsButton.innerHTML !== this.content.hideDetails) {
        let details = this.$refs["offer-details-el"];
        showDetailsButton.innerHTML = this.content.hideDetails;
        details.className =
          "offer-detail offer-detail-injected offer-pane-" + (idx + 1);
        details.style.display = "block";
        this.$refs[`offer-el-${idx}`][0].append(this.$refs["offer-details-el"]);
      } else {
        // Else: Swap text
        showDetailsButton.innerHTML = this.content.viewDetails;
      }
    },
    removeInjectedDetailsPane: function () {
      let prevDetails = document.getElementsByClassName(
        "offer-detail-injected"
      );
      if (prevDetails.length) prevDetails[0].remove();
    },
    highlightQuote: function (quoteElement) {
      var currentHighlight =
        document.getElementsByClassName("highlighted-quote")[0];
      if (currentHighlight) {
        currentHighlight.classList.remove("highlighted-quote");
      }
      quoteElement.target.classList.add("highlighted-quote");
    },
    moment: Moment,
    getParkingLotDescription: function (lotID) {
      let parkingLot = parkingLotsList.filter((d) => d.location_id == lotID)[0];
      return parkingLot.description;
    },
  },
  filters: {
    formatSavings(text) {
      text = text.toString();
      return text.replace(/^-/, "$");
    },
    formatOfferType: (val) =>
      OfferFilters[CURRENT_LANGUAGE].formatOfferType(val).replace(
        /(^.|\s\w)/g,
        (f) => f.toUpperCase()
      ),
    formatLocationTitle: (val) =>
      OfferFilters[CURRENT_LANGUAGE].formatLocationTitle(val),
    formatNetParkingDollar(text) {
      text = `${text}`.replace(",", "");
      return text.replace(/\.(\d\d)/, "");
    },
    formatNetParkingCents(text) {
      text = `${text}`.replace(",", "");
      return text.replace(/\d+\.(\d\d)/, "$1");
    },
  },
  beforeCreate: function () {
    this.$store = store;
  },
  computed: {
    quotes() {
      return this.$store.getters.getQuotes;
    },
    quoteCount() {
      return this.$store.getters.getQuoteCount;
    },
    dropOffDate() {
      return this.$store.getters.getDropOff;
    },
    pickUpDate() {
      return this.$store.getters.getPickUp;
    },
    displayDropOffTime() {
      return Moment(this.dropOffDate, "YYYY-M-D h:m");
    },
    displayPickUpTime() {
      return Moment(this.pickUpDate, "YYYY-M-D h:m");
    },
    city() {
      return this.$store.getters.getCity;
    },
    airport() {
      return this.$store.getters.getAirport;
    },
    coupon() {
      let c = this.$store.getters.getCoupon;
      return c == 0 ? "N/A" : this.$store.getters.getCoupon;
    },
    parkingLots() {
      return parkingLotsList
        .filter((d) => d.location.ID == this.activeLocation.ID)
        .reverse();
    },
    selectedParkingLot() {
      var r =
        this.currentQuote.locationName == ""
          ? this.parkingLots[0]
          : this.parkingLots.filter(
              (p) => p.location_id == this.currentQuote.locationId
            )[0];
      return r;
    },
    loading() {
      return this.$store.getters.getLoading;
    },
  },
};
</script>
