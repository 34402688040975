<template>
  <booking v-cloak></booking>
</template>

<script>

import Booking from '../components/booking.vue';
import LocationDetails from '../components/location-details.vue';
import MemberWidget from '../components/member-widget.vue';

export default {
  name: 'defaultView',
  components: {
    Booking,
    LocationDetails,
    MemberWidget,
  },
  beforeCreate() {
    document.body.style.visibility = "hidden"
    },
  mounted() {
    document.body.style.visibility = "visible";
  },
}
</script>
