<template>
  <section>
    <!-- <div class="row border-bottom pb-4 my-4">
      <div class="col-12">
        <h2>{{ content.popularQuestions }}</h2>
      </div>
      <template v-for="(faq, index) in popularQuestions">
        <div
          :key="'popular-q-' + index"
          :id="'location-faq-heading-popular-' + index"
          :class="
            'popular-faq-item col-6 py-3 margin-fix ' + faqLocationClass(faq)
          "
          v-show="
            faq.location.indexOf('all') > -1 ||
            faq.location.indexOf(selectedLocation) > -1
          "
          @click="handlePopularExpand"
        >
          <a
            class="location-faq-question blue-link"
            data-toggle="collapse"
            :data-target="'#location-faq-popular-' + index"
            aria-expanded="true"
            :aria-controls="'location-faq-popular-' + index"
          >
            {{ faq.question }}
          </a>
          <p
            :key="'popular-a-' + index"
            :id="'location-faq-popular-' + index"
            :aria-labelledby="'#location-faq-heading-popular-' + index"
            :data-parent="'#location-faq-heading-popular-' + index"
            class="location-faq-answer col-12 px-0 collapse collapsed mt-4 no-collapse-animation"
            v-html="faq.answer"
          ></p>
        </div>
      </template>
    </div> -->
    <!-- FAQ Section -->
    <h2 class="mb-3">{{ content.faq }}</h2>
    <div class="row">
      <div class="form-group col-12 pl-3 mb-4">
        <div
          class="select-wrapper --large --arrow-fix"
          style="max-width: 350px"
        >
          <!-- FAQ Section - Location Selection -->
          <select
            id="faq-locations"
            class="form-control form-control-lg"
            v-model="selectedLocation"
          >
            <option selected disabled>{{ content.pleaseSelect }}</option>
            <template v-for="location in locations">
              <option :key="location.ID" :value="location.ID">
                {{ content.pnfFaq[0] }} {{ location.city }}
                {{ content.pnfFaq[1] }}
              </option>
            </template>
          </select>
        </div>
      </div>
    </div>
    <div class="row" v-show="!questionsVisible">
      <template v-for="category in reorderedCategories">
        <!-- FAQ Section - Category Selection -->
        <div
          :key="category.display_name"
          class="col-sm-6 col-md-4 border-light p-3 my-2"
          v-show="locationHasCategoryQuestions(category.ID)"
          @click="showQuestionsFromCategory(category.ID)"
        >
          <div class="category-container mx-auto">
            <div class="category-icon-container mb-3 text-center">
              <span :class="'--faq ' + category.icon"></span>
            </div>
            <p class="text-center">{{ category.display_name }}</p>
          </div>
        </div>
      </template>
    </div>
    <div class="row" v-show="questionsVisible">
      <!-- FAQ Section - Question Selection -->
      <div class="col-12 border-top"></div>
      <div
        id="location-faq-container"
        class="location-faq-container px-0 mt-4 col-12"
      >
        <template v-for="(category, outerIndex) in faqQuestions">
          <div
            :key="'o-' + outerIndex"
            :id="'location-faq-' + outerIndex"
            class="col-11 mb-4"
            v-show="selectedCategory == category[0].category"
          >
            <h3
              class="d-inline-block"
              @click="questionsVisible = !questionsVisible"
            >
              <a class="icon-left-chevron d-inline-block mt-n2"></a>
            </h3>
            <h3
              :class="
                'mr-2 text-green mb-0 d-inline-block ' +
                getCategoryByID(category[0].category).icon
              "
              alt
              style="width: 2rem; height: 2rem; vertical-align: text-bottom"
            ></h3>
            <h2 class="d-inline-block">
              {{ getCategoryByID(category[0].category).display_name }}
            </h2>
            <template v-for="(faq, innerIndex) in category">
              <!-- FAQ Section - Question List-->
              <div
                :key="'q-' + innerIndex"
                :id="'location-faq-heading-' + outerIndex + '-' + innerIndex"
                :class="
                  'location-faq-item col-12 py-4 list-borders px-0 margin-fix ' +
                  faqLocationClass(faq)
                "
                v-show="
                  faq.location.indexOf('all') > -1 ||
                  faq.location.indexOf(selectedLocation) > -1
                "
              >
                <!-- FAQ Section - Question -->
                <a
                  class="location-faq-question blue-link"
                  data-toggle="collapse"
                  :data-target="
                    '#location-faq-' + outerIndex + '-' + innerIndex
                  "
                  aria-expanded="true"
                  :aria-controls="
                    'location-faq-' + outerIndex + '-' + innerIndex
                  "
                >
                  {{ faq.question }}
                </a>
                <!-- FAQ Section - Answer -->
                <p
                  :key="'a-' + innerIndex"
                  :id="'location-faq-' + outerIndex + '-' + innerIndex"
                  :aria-labelledby="
                    '#location-faq-heading-' + outerIndex + '-' + innerIndex
                  "
                  :data-parent="
                    '#location-faq-heading-' + outerIndex + '-' + innerIndex
                  "
                  class="location-faq-answer col-12 px-0 collapse collapsed mt-4"
                  v-html="faq.answer"
                ></p>
              </div>
            </template>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>
<script>
/*
global
  CURRENT_LANGUAGE
  faqQuestionList
  faqCategories
  locations
*/
import Content from "../../string_resources/support.json";
import store from "../components/store";
export default {
  name: "support",
  data() {
    return {
      content: Content[CURRENT_LANGUAGE],
      faqQuestions: faqQuestionList,
      locations: locations,
      faqCategories: faqCategories,
      selectedLocation:
        this.$store.getters.getLocation.ID || CURRENT_LANGUAGE == "en"
          ? 20
          : 35,
      questionsVisible: false,
      popularExpanded: false,
      selectedCategory: 0,
      language: CURRENT_LANGUAGE,
    };
  },
  beforeCreate: function () {
    this.$store = store;
  },
  methods: {
    showQuestionsFromCategory: function (category) {
      this.questionsVisible = true;
      this.selectedCategory = category;
    },
    getRelativeLocationID: function () {
      const relativeLocation = this.locations.filter((location) => {
        return (
          location.ID == this.selectedLocation ||
          location.alternateID == this.selectedLocation
        );
      });
      return relativeLocation[0].ID;
    },
    showCategories: function () {
      this.questionsVisible = false;
    },
    faqLocationClass: function (faq) {
      return ["", ...faq.location].join(" location-id-");
    },

    handlePopularExpand: function (e) {
      $(".active").removeClass("active col-12").addClass("col-6");
      this.popularExpanded = !this.popularExpanded;
      return this.popularExpanded ? this.expandFull(e) : this.collapseFull();
    },
    expandFull: function (e) {
      var el = $(e.target).hasClass("popular-faq-item")
        ? e.target
        : $(e.target).closest(".popular-faq-item");
      $(".active").removeClass("active col-12").addClass("col-6");
      $(el).addClass("active col-12");
      $(".popular-faq-item").filter(":not(.active)").hide();
    },
    collapseFull: function () {
      $(".active").removeClass("active col-12").addClass("col-6");
      $(".popular-faq-item").show();
    },
    getCategoryByID: function (categoryID) {
      return this.faqCategories.find(
        (category) => category.ID.toString() === categoryID.toString()
      );
    },
    locationHasCategoryQuestions: function (categoryID) {
      if (this.faqQuestions[categoryID.toString()]) {
        return this.faqQuestions[categoryID.toString()].filter(
          (faq) =>
            faq.location.indexOf("all") > -1 ||
            faq.location.indexOf(this.selectedLocation) > -1
        ).length;
      } else {
        return false;
      }
    },
  },
  computed: {
    popularQuestions: function () {
      let qList = this.faqQuestions;
      let result = [];
      Object.values(qList).map((c) => {
        c.map((q) => {
          if (q.popular == true) {
            result.push(q);
          }
        });
      });
      return result;
    },

    reorderedCategories() {
      var faq = [...this.faqCategories];
      var categories = [];
      var mobileIndex = faq.findIndex((f) => f.icon === "icon-pnf-support-app");
      if (mobileIndex > -1) {
        categories.push(faq.splice(mobileIndex, 1)[0]);
      }

      var rewards = faq.findIndex((f) => f.icon === "icon-pnf-rewards");
      if (rewards > -1) {
        categories.push(faq.splice(rewards, 1)[0]);
      }

      var payment = faq.findIndex((f) => f.icon === "icon-pnf-support-payment");
      if (payment > -1) {
        categories.push(faq.splice(payment, 1)[0]);
      }

      var discount = faq.findIndex(
        (f) => f.icon === "icon-pnf-support-discounts"
      );
      if (discount > -1) {
        categories.push(faq.splice(discount, 1)[0]);
      }

      var valet = faq.findIndex((f) => f.icon === "icon-pnf-valet");
      if (valet > -1) {
        categories.push(faq.splice(valet, 1)[0]);
      }

      var pickup = faq.findIndex((f) => f.icon === "icon-pnf-support-pickup");
      if (pickup > -1) {
        categories.push(faq.splice(pickup, 1)[0]);
      }

      var general = faq.findIndex(
        (f) => f.icon === "icon-pnf-support-questions"
      );
      if (general > -1) {
        categories.push(faq.splice(general, 1)[0]);
      }

      if(faq.length > 0) {
        while(faq.length !== 0) {
           categories.push(faq.splice(0, 1)[0]);
        }
      }
      return categories;
    },
  },
  mounted() {
    if (this.$store.getters.getLocation.ID) {
      this.selectedLocation = this.getRelativeLocationID();
    }
  },
};
</script>
