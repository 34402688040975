function UserPendingPromise(postData) {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: "POST",
      url: "/wp-admin/admin-ajax.php?action=pnf_get_pending_profile",
      data: JSON.stringify(postData),
      success: function (data) {
        if (data.result.confirmation) {
          resolve(data.result.confirmation[0]);
        }
      },
      error: function () {
        reject(false);
      },
    });
  });
}

export default UserPendingPromise;
