<template>
  <section>
    <div v-if="userLoaded">
      <div class="my-4 mb-5">
        <!--  Green -->
        <div v-if="tier === 1">
          <p >
           <span v-if="user.reward_member.points_balance > 0"> <strong>{{ pointsString }}</strong>
            {{ content.pointCount }} | </span> {{content.greenStatus}}
          </p>
          <p v-if="user.reward_member.points_balance <= 0">
            {{ content.showingDays }} <strong>{{ pointStays }}</strong>
            {{ content.daysNeededGold }}
          </p>
        </div>
        <!--  Gold -->
        <div v-if="tier === 2">
          <p>
           <strong>{{ pointsString }}</strong>
            {{ content.pointCount }} |  {{content.goldStatus}}
          </p>
          <p>
            {{ content.showingDays }} <strong>{{ pointStays }}</strong>
            {{ content.daysNeededPlatinum }}
          </p>
        </div>
        <!--  Platinum -->
        <div v-if="tier === 3">
          <p>
            <strong>{{ pointsString }}</strong>
            {{ content.pointCount }} |  {{content.platinumStatus}}
          </p>
        </div>
        <p>
          {{ content.plazaPremium }} -
          <a :href="plazaPremiumLink" target="_blank">
            {{ content.viewDetails }}
          </a>
        </p>
      </div>
      <div class="my-4 border-top"></div>
      <div class="rewards-history my-4">
        <h1 class="smaller my-4">{{ content.sectionLabels.rewardsHistory }}</h1>
        <template v-if="rewardsHistory">
          <div class="table-wrapper">
            <div
              class="row table-header-grey"
              style="margin-right: 0; margin-left: 0"
            >
              <!-- fieldLabels: {
    date: "Date",
    ticket: "Ticket #",
    pointsEarned: "Points Earned",
    pointsRedeemed: "Points Redeemed",
    balance: "Balance",
    description: "Description",
            },-->
              <div class="col-2">
                <strong>{{ content.fieldLabels.date }}</strong>
              </div>
              <div class="col-2">
                <strong>{{ content.fieldLabels.ticket }}</strong>
              </div>
              <div class="col-2">
                <strong>Points</strong>
              </div>
              <div class="col-6">
                <strong>{{ content.fieldLabels.description }}</strong>
              </div>
            </div>
            <div
              style="margin-right: 0; margin-left: 0"
              class="row table-body-grey bg-white"
              v-for="historyLine in rewardsHistory"
              :key="historyLine.date"
            >
              <div class="col-2">
                {{ historyLine.date | formatDate | formatEmpty }}
              </div>
              <div class="col-2">
                {{ historyLine.ticketNumber | formatEmpty }}
              </div>
              <div class="col-2">{{ historyLine.points | filteredPoints }}</div>
              <div class="col-6">
                {{ historyLine.description | formatEmpty }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div v-if="!userLoaded">
      <div class="my-2">
        <div class="text-center">
          <img
            src="/wp-content/themes/parknfly/resources/assets/images/loading.gif"
            width="100"
            height="100"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
/*
  global
    CURRENT_LANGUAGE
*/
import GetUser from "../../util/users";
import store from "../../components/store";
import axios from "axios";
import moment from "moment";
import Content from "../../../string_resources/member-rewards.json";
moment.locale(CURRENT_LANGUAGE);
export default {
  name: "member-rewards",
  data() {
    return {
      content: Content[CURRENT_LANGUAGE],
      userLoaded: false,
      user: {},
      currentPage: 1,
      pageSize: 100,
      rewardsHistory: [],
      tier: null,
    };
  },
  watch: {
    user: function () {
      this.userLoaded = true;
    },
  },
  methods: {
    getRewardHistory: function () {
      axios
        .post("/wp-admin/admin-ajax.php?action=pnf_get_rewards_history", {
          pageSize: this.pageSize,
          currentPage: this.currentPage,
          year: 0,
          language: CURRENT_LANGUAGE,
        })
        .then((result) => {
          this.rewardsHistory = result.data;
        });
    },
  },
  computed: {
    isLoggedIn: function () {
      return (
        store.getters.getAuthState && store.getters.getAuthUserRole === "member"
      );
    },
    pointsString: function () {
      var pts = this.user.reward_member.points_balance
        ? this.user.reward_member.points_balance
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : 0;
      return pts;
    },
    pointStays: function () {
      var pts = this.user.reward_member.stays_til_promotion
        ? parseInt(this.user.reward_member.stays_til_promotion)
        : 0;
      return pts;
    },
    plazaPremiumLink: function () {
      if (this.tier === 3) {
        return CURRENT_LANGUAGE === "en"
          ? "https://www.parknfly.ca/wp-content/uploads/2023/08/PPL-x-Parknfly_Voucher_Toronto_30D_Page_1-scaled.jpg"
          : "https://www.parknfly.ca/wp-content/uploads/2023/08/PPL-x-Parknfly_Voucher_Toronto_30D_Page_2-scaled.jpg";
      } else {
        return CURRENT_LANGUAGE === "en"
          ? "https://www.parknfly.ca/wp-content/uploads/2023/08/PPL-x-Parknfly_Voucher_Toronto_25D_Page_1-scaled.jpg"
          : "https://www.parknfly.ca/wp-content/uploads/2023/08/PPL-x-Parknfly_Voucher_Toronto_25D_Page_2-scaled.jpg";
      }
    },
  },
  filters: {
    capitalize(text) {
      return text ? text.charAt(0).toUpperCase() + text.slice(1) : " ";
    },
    formatEmpty(text) {
      return text ? text : "—";
    },
    filteredPoints(points) {
      return points !== 0
        ? points.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : "—";
    },
    earned(points) {
      return points > 0
        ? points.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : "—";
    },
    redeemed(points) {
      return points < 0
        ? points.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : "—";
    },
    formatDate(date) {
      return date ? moment(date).format("L") : "";
    },
  },
  created() {
    if (this.isLoggedIn) {
      GetUser().then((user) => {
        this.user = user;
        this.tier = this.user.reward_member.tier_status_id;
      });
    }
  },
  mounted() {
    this.getRewardHistory();
  },
};
</script>
