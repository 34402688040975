function UserPromise() {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: 'POST',
      url: '/wp-admin/admin-ajax.php?action=pnf_get_member_profile',
      success: function (data) {
        if (data.result.confirmation) {
          resolve(data.result.confirmation);
        }
      },
      error: function () {
        reject(false);
      },
    });
  });
}

export default UserPromise


