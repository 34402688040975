<template>
  <section>
    <div class="mt-5 mb-5">
      <p>{{ content.pendingMessage[0] }}</p>
      <p>{{ content.pendingMessage[1] }}</p>
    </div>
    <div v-if="loaded">
      <registration-form :user="user" :isConversion="true"></registration-form>
    </div>
    <div v-if="!loaded">
      <div class="text-center">
        <img
          src="/wp-content/themes/parknfly/resources/assets/images/loading.gif"
          width="100"
          height="100"
        />
      </div>
    </div>
  </section>
</template>

<script>
import GetPendingUser from "../util/userPending";
import RegistrationForm from "../components/registration-form.vue";
import Content from "../../string_resources/registration.json";

export default {
  name: "complete-registration",
  components: { RegistrationForm },
  data() {
    return {
      content: Content[CURRENT_LANGUAGE],
      loaded: false,
      userData: null,
      user: null,
    };
  },
  methods: {
    getUserInformation: function () {
      if (this.userData) {
        GetPendingUser(this.userData).then((user) => {
          this.user = user;
          this.loaded = true;
        });
      }
    },
  },

  created() {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has("from")) {
      this.fromOrigin = queryParams.get("from");
    }
    if (queryParams.has("d")) {
      this.userData = JSON.parse(queryParams.get("d"));
    }
    if (this.fromOrigin && this.userData) {
      this.getUserInformation();
    } else {
      this.loaded = true;
    }
  },
};
</script>
