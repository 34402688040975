import { router } from '../components/routes';
import VueRouter from 'vue-router';
import store from '../components/store';
import Vue from 'vue';

export default {
  init() {
      Vue.use(VueRouter);
      router.replace('/complete-registration/');

      window.app = new Vue({
        el: "#complete-registration",
        store,
        router,
      });
  },
  finalize() {
  },
};
