// import external dependencies
import 'jquery';

// Import everything from autoload
import "./autoload/_bootstrap.js"

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';
import support from './routes/support';
import singleLocation from './routes/singleLocation';
import profile from './routes/profile';
import emailSmsValidator from './routes/emailSmsValidator';
import rewardsRegistration from './routes/rewards-registration';
import forgotPassword from './routes/forgot-password';
import resetPassword from './routes/reset-password';
import washAndDetailingServices from './routes/washAndDetailingServices';
import rewards from './routes/rewards';
import completeRegistration from './routes/complete-registration';
import confirmEmail from './routes/confirmEmail';

// French
const inscriptionAuxRecompenses = rewardsRegistration;
const motDePasseOublie = forgotPassword;
const reinitialiserLeMotDePasse = resetPassword;
const soutien = support;
const servicesDeLavageEtDesthetiques = washAndDetailingServices;
const recompenses = rewards;

// DEV
// export const RECAPTCHA_API_KEY = '6LfBZtMiAAAAAEdibmTaM_2ESs00dqPP1vxzUIRy';
// PROD
export const RECAPTCHA_API_KEY = '6Lct6dgiAAAAAGR_ynf3vX_qr4j1prvnKefjkr_2';
/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
  // Location.
  singleLocation,
  // Member Profile
  profile,
  // FAQ Component for the support page
  support,
  soutien,
  // Page Contains Gravity Forms and requires emailSmsValidator
  emailSmsValidator,
  // Member registration
  rewardsRegistration,
  inscriptionAuxRecompenses,
  // Rewards Mailing Subscription
  rewards,
  recompenses,
  // ConfirmEmail
  confirmEmail,
  // Form for sending password email link
  forgotPassword,
  motDePasseOublie,
  // Form for resetting password via email link
  resetPassword,
  reinitialiserLeMotDePasse,
  // Additional Services page
  washAndDetailingServices,
  servicesDeLavageEtDesthetiques,
  // Complete Registration for Pending users
  completeRegistration,
});

// Load Events
jQuery(document).ready(() => {
  routes.loadEvents()
  // Careers Apply button email
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  let location = params.location;
  var applyButton = $("a#apply_btn");
  var jobName = $(".elementor-widget-container > h2.elementor-heading-title").text();
  var emailAddress = `dg_hr_${location}@parknfly.ca`;
  if(applyButton.length > 0) {
    var href = `mailto:${emailAddress}?subject=Job application for ${jobName}(${location})`;
    $(applyButton).attr('href', href);
  }

});




