<template>
  <section>
    <div class="rewards-history my-4" v-if="!loading">
      <h1 class="smaller my-4">
        {{ content.sectionLabels.currentReservations }}
      </h1>
      <template>
        <div class="table-wrapper" v-if="reservations.length > 0">
          <div
            class="row table-header-grey"
            style="margin-right: 0; margin-left: 0"
          >
            <!-- fieldLabels: {
     "dateReserved": "Date Reserved",
      "reservationNumber": "Reservation Number",
      "checkInDate": "Check in date",
      "checkoutDate": "Check out date"
            },-->
            <div class="col-3">
              <strong>{{ content.tableLabels.dateReserved }}</strong>
            </div>
            <div class="col-3">
              <strong>{{ content.tableLabels.reservationNumber }}</strong>
            </div>
            <div class="col-3">
              <strong>{{ content.tableLabels.checkInDate }}</strong>
            </div>
            <div class="col-3">
              <strong>{{ content.tableLabels.checkoutDate }}</strong>
            </div>
          </div>

          <div
            style="margin-right: 0; margin-left: 0"
            class="row table-body-grey bg-white"
            v-for="data in reservations"
            :key="data.reservation.confirmedReservationID"
          >
            <div class="col-3">
              {{ data.reservation.resDate | formatDate }}
            </div>
            <div class="col-3">
              <a
                :href="createLinks(data.reservation.retrievalLink)"
                target="_blank"
                >{{ data.reservation.confirmedReservationID }}
              </a>
            </div>
            <div class="col-3">
              {{ data.reservation.openDate | formatDate  }}
            </div>
            <div class="col-3">
              {{ data.reservation.closeDate | formatDate }}
            </div>
          </div>
        </div>

        <div class="text-center" v-if="reservations.length === 0">
          <p class="mt-5">
            <em> {{ content.noReservation }} </em>
          </p>
        </div>
      </template>
    </div>
    <div v-if="loading" class="text-center mt-5 pt-5">
      <img
        src="/wp-content/themes/parknfly/resources/assets/images/loading.gif"
        width="100"
        height="100"
      />
    </div>
  </section>
</template>

<script>
/*
  global
    CURRENT_LANGUAGE
*/
import GetUser from "../../util/users";
import store from "../../components/store";
import axios from "axios";
import moment from "moment";
import Content from "../../../string_resources/member-reservations.json";
moment.locale(CURRENT_LANGUAGE);
export default {
  name: "reservations",
  data() {
    return {
      content: Content[CURRENT_LANGUAGE],
      user: {},
      currentPage: 1,
      pageSize: 12,
      reservations: [],
      language: CURRENT_LANGUAGE,
      loading: true,
    };
  },
  watch: {},
  methods: {
    getReservationList: function () {
      axios
        .post("/wp-admin/admin-ajax.php?action=pnf_reservationList", {langauge: CURRENT_LANGUAGE})
        .then((result) => {
          if (result.status === 200 && result.data.result.status === "OK") {
            this.reservations =
              result.data.result.confirmation.activeFutureReservations || [];
          }
          this.loading = false;
        });
    },
    createLinks: function (link) {
      var index = link.indexOf("retrievalId");
      var parameters = link.substring(index + 11);
      var url = `${
        this.language == "en" ? "" : "/fr"
      }/confirmation/?confirmationId${parameters}`;
      return url;
    },
  },
  computed: {
    isLoggedIn: function () {
      return (
        store.getters.getAuthState && store.getters.getAuthUserRole === "member"
      );
    },
  },
  filters: {
    formatDate(date) {
      return date ? moment.utc(date).format("L") : "";
    },
  },
  created() {
    if (this.isLoggedIn) {
      GetUser().then((user) => {
        this.user = user;
      });
    }
  },

  mounted() {
    this.getReservationList();
         document.addEventListener("visibilitychange", function () {
      if (document.hidden) {
       // user went to another window/tab
      } else {
       // user came back from another window/tab
        location.reload();
      }
    });
  },
};
</script>
