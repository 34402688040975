<template v-if="user">
  <section>
    <div class="my-4">
      <h1 class="smaller">{{content.sectionLabels.changePassword}}</h1>
      <p>{{content.changePasswordIntro}}</p>
    </div>
    <div class="row align-items-start">
      <div class="col-md-8 mb-3 mb-md-0">
        <div class="two-col-form col-12 p-4 bg-white rounded ignore-margin border">
          <div class="form-row justify-content-between">
            <div class="form-group mb-3 required">
              <label for="field-current-password">{{content.fieldLabels.currentPassword}}</label>
              <input
                class="form-control form-control-lg"
                v-model="currentPassword"
                type="password"
                name="current-password"
                id="field-current-password"
                required
              />
            </div>
            <div class="form-group"></div>
          </div>
          <password-fields class="justify-content-between" ref="passwordFields"></password-fields>
        </div>
      </div>
      <div class="col-md-4">
        <div class="bg-white p-3 rounded border">
          <div class="mt-2">
            <h3>{{content.needHelp}}</h3>
          </div>
          <div class="mb-2">
            <p>
              {{content.registrationSupport[0]}}
              <a
                href="mailto:loyalty@parknfly.ca"
              >{{content.registrationSupport[1]}}</a>
            </p>
          </div>
          <div class="my-3">
            <a
              class="btn btn-lg text-white btn-lg-text w-100 h-100 p-3 rounded"
              @click="changePassword"
            >{{content.buttonLabels.changePassword}}</a>
          </div>
          <div>
            <template v-for="(error,index) in passwordErrors">
              <p :key="index" class="text-red">{{error}}</p>
            </template>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
/*
  global
    CURRENT_LANGUAGE
*/
import GetUser from "../../util/users";
import store from "../../components/store";
import Warning from "../../components/warning-banner.vue";
import axios from "axios";
import PasswordFields from "../../components/password-fields.vue";
import Content from "../../../string_resources/member-password.json";

export default {
  name: "member-password",
  components: { PasswordFields },
  data() {
    return {
      content: Content[CURRENT_LANGUAGE],
      user: {},
      passwordErrors: [],
      currentPassword: "",
      password: "",
      confirmPassword: "",
    };
  },
  methods: {
    changePassword: function() {
      this.passwordErrors = [];
      let errors = this.$refs.passwordFields.checkPassword();
      if (errors) {
        if (errors.indexOf("no-match") > -1) {
          this.passwordErrors.push(
            this.$refs.passwordFields.content.error.passwordMatch
          );
        }
        if (errors.indexOf("bad-format") > -1) {
          this.passwordErrors.push(
            this.$refs.passwordFields.content.error.passwordRequirements
          );
        }
      } else {
        axios
          .post("/wp-admin/admin-ajax.php?action=pnf_change_password", {
            custId: this.user.custId,
            currentPassword: this.currentPassword,
            newPassword: this.$refs.passwordFields.password,
          })
          .then(result => {
            if (result.data.errors) {
              Warning.warn(result.data.errors[0], 5);
            } else {
              Warning.message(this.content.passwordUpdated, 5);
            }
          });
      }
    },
  },
  computed: {
    isLoggedIn: function() {
      return store.getters.getAuthState && store.getters.getAuthUserRole === "member";
    },
  },
  filters: {
    capitalize(text) {
      return text ? text.charAt(0).toUpperCase() + text.slice(1) : " ";
    },
  },
  created() {
    if (this.isLoggedIn) {
      GetUser().then(user => {
        this.user = user;
      });
    }
  },
};
</script>
