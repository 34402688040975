<template>
  <div class="w-100">
    <div id="recaptcha1"></div>
    <div v-if="step === 1" class="form-group col-12 px-1">
      <p v-if="!phoneOnlyVersion">
        {{
          contactType === "E"
            ? content.statusLabelEmail
            : content.statusLabelSMS
        }}
      </p>
      <a
        class="btn py-2 px-4 mb-2 text-white"
        @click="sendVerificationCode()"
        @keyup.enter="sendVerificationCode()"
        tabindex="0"
        >{{ content.validateButtonLabel }}</a
      >
    </div>
    <div v-if="step === 2">
      <div class="mb-0">
        <p>
          <strong>
            {{ contactType === "S" ? displayCellphone : emailAddress }}
            <span class="text-red">{{ content.unconfirmed }}</span>
          </strong>
        </p>
      </div>
      <p>
        {{
          contactType === "S" ? content.phoneInstructions : content.instructions
        }}
      </p>
      <div class="form-group col-12 col-md-6 pl-0 pr-0 pr-md-1">
        <input
          class="form-control form-control-lg my-0"
          :placeholder="content.inputLabel"
          maxlength="6"
          v-model="verificationCode"
          @keyup.enter="validateVerificationCode()"
          type="text"
        />
      </div>
      <div v-if="!error">
        <a
          class="btn py-2 px-4 text-white mb-4"
          @click="validateVerificationCode()"
          @keyup.enter="validateVerificationCode()"
          >{{ content.submitLabel }}</a
        >
      </div>
      <div v-if="!error">
        <a class="py-2 blue-link" @click="resetComponent()">{{
          content.cancelValidation
        }}</a>
      </div>
    </div>
    <div v-if="step === 3" class="form-group">
      <div>
        <p>
          <strong>
            {{
              contactType === "S" || phoneOnlyVersion
                ? displayCellphone
                : emailAddress
            }}
            <span class="text-green">{{ content.confirmed }}</span>
          </strong>
        </p>
      </div>
      <div class="d-flex mt-4" v-if="phoneOnlyVersion">
        <!-- <div class="big-checkbox --align-top mr-3" tabindex="0" v-show="!isValidEmailAddress" > </div> -->
        <input
          class="big-checkbox --align-top mr-3"
          type="checkbox"
          v-model="sendToEmailRequested"
          @mouseup="handleSendToEmailRequestedClick"
          @keyup.enter="handleSendToEmailRequestedClick"
          :aria-label="content.sendToEmail"
        />
        <p class="w-100">{{ content.sendToEmail }}</p>
      </div>
    </div>
    <div v-if="loading" class="loading-spinner">
      <img
        src="/wp-content/themes/parknfly/resources/assets/images/loading.gif"
        width="100"
        height="100"
      />
    </div>
    <div v-if="error">
      <p class="text-red">{{ errorMessage }}</p>
      <a
        v-if="step > 1"
        class="btn py-2 px-4 mr-4 text-white mb-4"
        @click="validateVerificationCode()"
        >{{ content.resubmit }}</a
      >
      <a
        v-if="step > 1"
        class="btn py-2 px-4 text-white mb-4"
        @click="resetComponent()"
        @keyup.enter="resetComponent()"
        >{{ content.requestNew }}</a
      >
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Content from "../../string_resources/validation-code.json";
import Validation from "../util/formValidation";

/*
  global
    CURRENT_LANGUAGE
    grecaptcha
    window
  */

export default {
  name: "validation-code",
  props: {
    initStep: { default: 1, type: Number },
    emailAddressId: { default: "", type: String },
    confirmEmailAddressId: { default: "", type: String },
    phoneNumberId: { default: "", type: String },
    communicationMethodName: { default: "", type: String },
    communicationMethod: { default: "S", type: String },
    verifiedHashId: { default: "", type: String },
    contactValidationHash: { default: "", type: String },
    bothContactsAccepted: { default: false, type: Boolean },
    phoneOnlyVersion: { default: false, type: Boolean },
    checkPhoneInUse: {default: true, type: Boolean},
  },

  data() {
    return {
      content: Content[CURRENT_LANGUAGE],
      loading: false, // Loading spinner
      error: false, // Error encountered
      errorMessage: "", // Error message
      ready: false, // Mounted
      verificationCode: null, // Verification Code entered by user
      step: 1, // Current step (of 3)
      emailAddress: "",
      cellPhoneNumber: "",
      displayCellphone: "",
      verificationCodeRequested: false,
      sendToEmail: false,
      sendToEmailRequested: false,
      contactType: "S",
      emailFieldBlurred: false,
    };
  },
  watch: {
    step: function () {
      if (this.step === 1) {
        $(".verification-hide").show();
        this.verificationCodeRequested = false;
      } else {
        this.displayCellphone = $("#" + this.phoneNumberId).val();
        $(".verification-hide").hide();
      }
    },
    sendToEmail: function () {
      this.contactType = this.sendToEmail ? "E" : "S";
      this.$emit("contactTypeChange", this.contactType);
    },
    emailAddress: function () {
      this.handleRequestForEmailChange();
    },
    sendToEmailRequested: function () {
      this.handleRequestForEmailChange();
    },
  },
  methods: {
    handleRequestForEmailChange: function () {
      this.sendToEmail = this.sendToEmailRequested && this.isValidEmailAddress;
    },
    clearError: function () {
      this.error = false;
      this.errorMessage = "";
      // Attempt to clear Gravity Forms error
      // get by css class'g-verified-email' and remove 'gfield_error'
      const verifiedEmailContainer =
        document.getElementsByClassName("g-verified-email");
      if (verifiedEmailContainer.length) {
        verifiedEmailContainer[0].classList.remove("gfield_error");
        // get child by css class 'validation_message' and remove element
        const validationMessageContainer =
          verifiedEmailContainer[0].querySelector(".validation_message");
        if (validationMessageContainer) {
          validationMessageContainer.parentNode.removeChild(
            validationMessageContainer
          );
        }
      }
    },
    handleSendToEmailRequestedClick: function () {
      $("#" + this.emailAddressId)
        .off("blur")
        .on("blur", () => {
          if (this.sendToEmailRequested) {
            this.emailFieldBlurred = !this.isValidEmailAddress;
            this.sendToEmailRequested = this.isValidEmailAddress;
          } else {
            this.emailFieldBlurred = false;
          }
        });

      if (this.emailAddress !== "" && this.isValidEmailAddress) {
        return;
      }

      if (this.emailFieldBlurred && this.emailAddress === "") {
        this.emailFieldBlurred = false;
        this.sendToEmailRequested = !this.sendToEmailRequested;
      } else {
        if (!this.sendToEmailRequested) {
          setTimeout(() => {
            $("#" + this.emailAddressId)[0].focus();
          }, 100);
        }
      }
    },
    resetComponent: function () {
      this.clearError();
      this.step = 1;
      this.verificationCode = null;
      if (this.contactType === "S") {
        $("#" + this.phoneNumberId).val("");
        this.$emit("phone-validation-reset");
      } else {
        $("#" + this.emailAddressId).val("");
      }
    },
    setRequiredField: function () {
      this.resetRequiredField();
      if (this.bothContactsAccepted) {
        this.setPhoneRequired();
        this.setEmailRequired();
      } else {
        if (this.contactType === "S") {
          this.setPhoneRequired();
        } else {
          this.setEmailRequired();
        }
      }
    },
    setPhoneRequired: function () {
      $("#" + this.phoneNumberId)
        .attr("required", true)
        .parent(".form-group")
        .addClass("required");
    },
    setEmailRequired: function () {
      $("#" + this.emailAddressId)
        .attr("required", true)
        .parent(".form-group")
        .addClass("required");
    },
    resetRequiredField: function () {
      $("#" + this.phoneNumberId)
        .attr("required", false)
        .parent(".form-group")
        .removeClass("required");
      $("#" + this.emailAddressId)
        .attr("required", false)
        .parent(".form-group")
        .removeClass("required");
    },
    setError: function (message) {
      this.error = true;
      this.errorMessage = message || this.content.generalError;
    },
    lockFields: function () {
      // Commented out as disabled attribute was affecting gravity form submission
      // // Email Textbox
      // document.getElementById(this.emailAddressId).disabled = true;
      // // Cell Phone Textbox
      // if (this.phoneNumberId !== '') {
      //   document.getElementById(this.phoneNumberId).disabled = true;
      // }
      // // Contact Preference Radio Buttons
      // if (this.communicationMethodName !== '') {
      //   let radios = document.querySelectorAll('input[type=radio][name="'+this.communicationMethodName+'"]');
      //   Array.prototype.forEach.call(radios, function(radio) {
      //     radio.disabled = true;
      //   });
      // }
    },
    sendVerificationCode: function () {
      this.clearError();
      if (
        this.contact === "" ||
        (this.contactType === "E" && !this.isValidEmailAddress) ||
        (this.contactType === "S" && !this.validatePhoneNumber())
      ) {
        this.setError(
          this.contactType === "E"
            ? this.content.error.email
            : this.contactType === "S"
            ? this.content.error.phone
            : this.content.contactError
        );
        return;
      }
      // Trigger Google Recaptcha. Callback is onSubmitSendValidation
      grecaptcha.execute(this.widgetId1);
      this.$emit("verification-started");
    },
    onSubmitSendValidation: function () {
      // Callback Triggered by successful Google Recaptcha Widget
      // This method sends the "contact" and Google Recaptcha result to the "Send Verification Code" web service
      if (
        grecaptcha.getResponse(this.widgetId1) &&
        !this.verificationCodeRequested
      ) {
        this.verificationCodeRequested = true;
        this.loading = true;
        this.clearError();
        axios
          .post(
            "/wp-admin/admin-ajax.php?action=pnf_send_verification_code",
            {
              contact: this.contact,
              checkPhoneInUse: this.checkPhoneInUse,
              recaptcha: grecaptcha.getResponse(this.widgetId1),
            },
            {
              timeout: 10000,
            }
          )
          .then((response) => {
            this.loading = false;
            if (response.data.result.status == "OK") {
              this.loading = false;
              this.step = 2;
            } else {
              if (response.data.result.error_code === "VALIDATION_ERROR") {
                let validationMsg =
                  CURRENT_LANGUAGE == "en"
                    ? response.data.result.errors.error_message_en
                    : response.data.result.errors.error_message_fr;
                this.setError(validationMsg);
              } else {
                this.setError(this.content.contactError);
              }
            }
            this.$emit("step-one-error");
            grecaptcha.reset(this.widgetId1);
          })
          .catch((error) => {
            this.verificationCodeRequested = false;
            this.loading = false;
            this.setError();
            /* eslint-disable no-console */
            console.error(error);
            /* eslint-enable no-console */
            grecaptcha.reset(this.widgetId1);
          });
      } else {
        grecaptcha.reset(this.widgetId1);
        grecaptcha.execute(this.widgetId1);
      }
    },
    validateVerificationCode: function () {
      // Triggered by user entering Verification Code and Submitting
      // This method sends the "contact" and "Verification Code" to the "Validate Verification Code" web service
      // Successful Verifcation returns a hash
      this.loading = true;
      this.clearError();
      axios
        .post(
          "/wp-admin/admin-ajax.php?action=pnf_validate_verification_code",
          {
            contact: this.contact,
            verification_code: this.verificationCode,
          },
          {
            timeout: 10000,
          }
        )
        .then((response) => {
          this.loading = false;
          if (response.data.result.status == "OK") {
            this.loading = false;
            this.step = 3;
            document.getElementById(this.verifiedHashId).value =
              response.data.result.hash;
            this.$emit("hash-received");
            this.lockFields();
          } else {
            this.setError(this.content.verficationError);
          }
          grecaptcha.reset(this.widgetId1);
        })
        .catch((error) => {
          this.loading = false;
          this.setError();
          /* eslint-disable no-console */
          console.error(error);
          /* eslint-enable no-console */
          grecaptcha.reset(this.widgetId1);
        });
    },
    validatePhoneNumber: function () {
      return Validation.phoneNumber.test(this.cellPhoneNumber);
    },
  },
  computed: {
    widgetId1: function () {
      const $this = this;
      return grecaptcha.render("recaptcha1", {
        sitekey: "6Lf7w7wUAAAAAPfqR-bSiLHRFmcu-dEZgLPpkkQs",
        callback: $this.onSubmitSendValidation,
        size: "invisible",
      });
    },
    contact: function () {
      return this.contactType === "S" || this.phoneOnlyVersion
        ? this.phoneWithoutCharacters
        : this.emailAddress;
    },
    phoneWithoutCharacters: function () {
      return this.cellPhoneNumber.replace(/[^0-9]/g, "");
    },
    isValidEmailAddress: function () {
      return this.emailAddress
        ? Validation.email.test(this.emailAddress)
        : false;
    },
  },
  mounted() {
    const $this = this;
    this.step = this.initStep;

    // Phone Number Textbox ID is required.
    if (this.phoneNumberId === "" && this.emailAddressId === "") {
      /* eslint-disable no-console */
      console.error(
        "Phone number input ID or email address input ID not set for Validation Code Vue Component"
      );
      /* eslint-enable no-console */
      return;
    }

    if (this.communicationMethod === "E") {
      this.contactType = this.communicationMethod;
    }

    // Bind change event of Phone Number Textbox
    if (this.phoneNumberId !== "") {
      $this.cellPhoneNumber = document.getElementById(this.phoneNumberId).value;
      document
        .getElementById(this.phoneNumberId)
        .addEventListener("input", function () {
          $this.cellPhoneNumber = this.value;
        });
    }

    // Bind change event of Email Address Textbox if available
    if (this.emailAddressId !== "") {
      $this.emailAddress = document.getElementById(this.emailAddressId).value;
      document
        .getElementById(this.emailAddressId)
        .addEventListener("input", function () {
          $this.emailAddress = this.value;
        });
    }

    // Bind change event of Contact Preference Radio Buttons if available
    if (this.communicationMethodName !== "" && !this.bothContactsAccepted) {
      this.contactType = document.querySelector(
        "input[name=" + this.communicationMethodName + "]:checked"
      ).value;

      let radios = document.querySelectorAll(
        'input[type=radio][name="' + this.communicationMethodName + '"]'
      );

      Array.prototype.forEach.call(radios, function (radio) {
        radio.addEventListener("change", function () {
          $this.contactType = this.value;
          $this.setRequiredField();
        });
      });
    }

    // Bind Google Recaptcha Callback
    this.ready = true;
    $this.setRequiredField();
  },
};
</script>
