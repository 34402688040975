import Vue from 'vue';
import ValidationCode from '../components/validation-code.vue';

export default {
  init() {

    // Need to wait for Google Recaptcha to load for Gravity Form implementation
    // Cannot use callback as it is not available until reserve.vue has rendered (non-Gravity Forms implementation)
    $(document).ready(function () {
      if (document.getElementsByClassName("g-verified-email").length) {
        $('.g-verified-email').append('<div class="d-flex flex-column justify-content-center align-items-center email-verification-loader"><div class="mini-loader">Loading...</div><div>Loading Email Verification...</div></div>');
        $('.g-verified-email input').hide();
        setTimeout(function () {
          $('.g-verified-email .email-verification-loader').remove();

          // Determine if g-verify-email-address has 1 or 2 input boxes
          // Determine if Cell Phone number is available and if so ensure Contact Preference is available
          // Default is only single email address field

          // On load, if hash contains a value then assume email already validate: show step 3 and lock fields
          // This will handle gravity forms post back
          // Reservue.vue is an ajax request, so no post back

          // Used for storing and validating the Hash
          const verifiedEmailContainer = document.getElementsByClassName("g-verified-email")[0];
          const verifiedEmailInput = verifiedEmailContainer.querySelector('input');
          const verifiedEmailInputParent = verifiedEmailInput.parentElement;

          // Email Address Textbox
          const verifiedEmailAddressContainer = document.getElementsByClassName("g-verify-email-address")[0];
          const verifiedEmailAddressInput = verifiedEmailAddressContainer.querySelector('input');

          // Inject <div> for the Vue App
          let vueElement = document.createElement('div');
          vueElement.setAttribute("id", "verificationApp");
          verifiedEmailInputParent.appendChild(vueElement);
          verifiedEmailInput.style.display = "none";

          window.verificationApp = new Vue({
            components: { ValidationCode},
            data: {
              step: (verifiedEmailInput.value == "" ? 1 : 3),
              emailAddressId: verifiedEmailAddressInput.id,
              verifiedHashId: verifiedEmailInput.id,
            },
            el: "#verificationApp",
            template: `<validation-code
              v-bind:initStep='step'
              v-bind:emailAddressId='emailAddressId'
              v-bind:verifiedHashId='verifiedHashId'
              communicationMethod="E"
              >
              </validation-code>`,
          });
        }, 3000);
      }
    });

  },
};
