<template>
  <section>
    <div class="my-4">
      <h1 class="smaller">{{content.sectionLabels.changePassword}}</h1>
      <p>{{content.changePasswordIntro}}</p>
    </div>
    <div class="row margin-fix align-items-start">
      <div class="row col-md-8 mb-3 mb-md-0">
        <div class="two-col-form col-11 p-4 bg-white rounded ignore-margin border">
          <password-fields class="justify-content-between" ref="passwordFields"></password-fields>
        </div>
      </div>
      <div class="col-md-4">
        <div class="row bg-white p-3 rounded border">
          <div class="col-12 mt-2">
            <h3>{{content.needHelp}}</h3>
          </div>
           <div class="col-12 mb-2">
              <p v-if="userType !== 'agent'">
                {{ content.registrationSupport[0] }}
                <a href="mailto:loyalty@parknfly.ca">{{
                  content.registrationSupport[1]
                }}</a>
              </p>
               <p v-if="userType === 'agent'">
                {{ content.registrationSupportAgent[0] }}
                <a href="mailto:tasupport@parknfly.ca">{{
                  content.registrationSupportAgent[1]
                }}</a>
              </p>
            </div>
          <div class="col-12 my-3">
            <a
              class="btn btn-lg text-white btn-lg-text w-100 h-100 p-3 rounded"
              @click="changePassword"
            >{{content.buttonLabels.changePassword}}</a>
          </div>
          <div class="col-12">
            <template v-for="(error,index) in passwordErrors">
              <p :key="index" class="text-red">{{error}}</p>
            </template>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Warning from "./warning-banner.vue";
import axios from "axios";
import PasswordFields from "./password-fields.vue";

export default {
  name: "reset-password",
  components: { PasswordFields },
  props: {
    content: { default: () => {}, type: Object },
    custId: { default: "", type: String },
    userType: { default: "", type: String },
  },
  data() {
    return {
      passwordErrors: [],
      password: "",
      confirmPassword: "",
    };
  },
  methods: {
    changePassword: function() {
      this.passwordErrors = [];
      let errors = this.$refs.passwordFields.checkPassword();
      if (errors) {
        if (errors.indexOf("no-match") > -1) {
          this.passwordErrors.push(
            this.$refs.passwordFields.content.error.passwordMatch
          );
        }
        if (errors.indexOf("bad-format") > -1) {
          this.passwordErrors.push(
            this.$refs.passwordFields.content.error.passwordRequirements
          );
        }
      } else {
        axios
          .post("/wp-admin/admin-ajax.php?action=pnf_reset_password", {
            custId: this.custId,
            newPassword: this.$refs.passwordFields.password,
            userType: this.userType,
          })
          .then(result => {
            if (result.data.errors) {
              Warning.warn(result.data.errors[0], 5);
            } else {
              Warning.message(this.content.passwordUpdated, 5);
              setTimeout(() => (window.location.href = "/"), 5000)
            }
          });
      }
    },
  },
  mounted() {},
};
</script>
