<template>
  <section>
    <member-subscription-form></member-subscription-form>
  </section>
</template>
<script>
import MemberSubscriptionForm from '../components/member-subscription-form.vue';
export default {
  name: "rewardSubscription",
  components: { MemberSubscriptionForm },
  data() {
    return {
    };
  },
  watch: {},
  mounted() {},
};
</script>
