import DefaultView from '../views/default.vue';
import QuoteView from '../views/quote.vue';
import ReserveView from '../views/reserve.vue';
import ThankYouView from '../views/thank-you.vue';
import ThankYouMemberView from '../views/thank-you-member.vue'
import VueRouter from 'vue-router';
import MemberDefault from '../views/member-profile/default.vue';
import MemberEdit from '../views/member-profile/edit.vue';
import MemberPassword from '../views/member-profile/password.vue';
import MemberRewards from '../views/member-profile/rewards.vue';
import Register from '../views/register.vue';
import Reservations from '../views/member-profile/reservations.vue';
import UserForgotPassword from '../views/forgot-password/forgot-password.vue';
import UserResetPassword from '../views/forgot-password/reset-password.vue';
import RewardSubscription from '../views/rewards-subscription.vue';
import CompleteRegistration from '../views/complete-registration.vue';
import ConfirmEmail from '../views/confirm-email.vue';

const routes = [
  { path: '/', component: DefaultView },
  { path: '/confirm-email', component: ConfirmEmail },
  { path: '/rewards', component: RewardSubscription },
  { path: '/quote', component: QuoteView },
  { path: '/reserve', component: ReserveView },
  { path: '/thank-you', component: ThankYouView },
  { path: '/thank-you-member', component: ThankYouMemberView },
  { path: '/members', component: MemberDefault },
  { path: '/members/edit', component: MemberEdit },
  { path: '/members/password', component: MemberPassword },
  { path: '/members/rewards', component: MemberRewards },
  { path: '/members/reservations', component: Reservations },
  { path: '/rewards-registration', component: Register },
  { path: '/forgot-password', component: UserForgotPassword },
  { path: '/reset-password', component: UserResetPassword },
  {path:'/complete-registration', component: CompleteRegistration},
  {
    path: '*',
    component: {
      name: 'not-found-blurb',
      template: `<h2>Not Found</h2>`,
    },
  },
];

export const router = new VueRouter({
  mode: 'abstract',
  routes,
  //scrollBehavior not available with abstract mode (using router.beforeEach)
});

router.beforeEach((to, from, next) => {
  var staticContent = document.getElementById('location_static_content');
  if (to.path == "/") {
    window.scrollTo(0, 0);
    if (staticContent)
      staticContent.style.display = 'flex';
  } else {
    if (staticContent)
      staticContent.style.display = 'none';
  }
  next();
})
