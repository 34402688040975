<template>
  <section>
    <div class="container my-6">
      <div class="row">
        <div class="col-sm-12 form-container">
          <div
            class="form-header green-underline mb-2 d-flex pl-4 pr-4 justify-content-between"
          >
            <h1>
              {{ quote.locationName | formatLocationName }}
              {{ content.offerConfirmation }}
            </h1>
          </div>
          <div class="row p-4">
            <div class="col-sm-12 text-center d-block d-md-none mb-4">
              <img
                src="https://admin.parknfly.ca/wp-content/uploads/2023/12/qr-code.png"
                width="200"
              />
              <p class="small font-italics text-center">
                {{ content.downloadOrVisit }} <br />
                <a href="www.parknfly.ca/app">www.parknfly.ca/app</a>
              </p>
            </div>
            <div class="col-md-8 col-sm-12">
              <h3 class="mb-3">
                {{ firstName }}, {{ content.almostDone }} <br />
                {{ content.download }}
              </h3>
              <p class="mb-3">{{ content.messages[0] }}</p>
              <p class="mb-3">{{ content.messages[1] }}</p>
              <p class="mb-3">{{ content.messages[2] }}</p>
            </div>
            <div class="col-md-4 text-center d-none d-md-block">
              <div class="text-center">
                <img
                  src="https://admin.parknfly.ca/wp-content/uploads/2023/12/qr-code.png"
                  width="150"
                  class="mb-2"
                />
              </div>
              <p class="small font-italics text-center">
                {{ content.downloadOrVisit }} <br />
                <a href="www.parknfly.ca/app">www.parknfly.ca/app</a>
              </p>
            </div>
          </div>
          <div class="row p-4">
            <div class="col-sm-12">
              <h2 class>
                {{ quote.locationName | formatLocationName }}
                {{ reserveContent.offer }}
              </h2>
            </div>
          </div>
          <div class="row inner-form p-4">
            <div class="col-md-6 rewards__left-details-container">
              <h3 class="mb-3">{{ reserveContent.parkingDetailsLabel }}</h3>
              <div class="row h-100">
                <div class="col-6">
                  <p class="field__label">{{ reserveContent.dropOffLabel }}</p>
                  <p
                    class="booking__label d-none d-sm-block d-md-none d-xxl-block"
                  >
                    {{ dropOff.format("MMMM D, YYYY") }}
                  </p>
                  <p
                    class="booking__label d-block d-sm-none d-md-block d-xxl-none"
                  >
                    {{ dropOff.format("MM/D/YY") }}
                  </p>
                  <p class="field__lower-text">
                    {{ dropOff.format("h:mm A") }}
                  </p>
                </div>
                <div class="col-6">
                  <p class="field__label">{{ reserveContent.pickUpLabel }}</p>
                  <p
                    class="booking__label d-none d-sm-block d-md-none d-xxl-block"
                  >
                    {{ pickUp.format("MMMM D, YYYY") }}
                  </p>
                  <p
                    class="booking__label d-block d-sm-none d-md-block d-xxl-none"
                  >
                    {{ pickUp.format("MM/D/YY") }}
                  </p>
                  <p class="field__lower-text">{{ pickUp.format("h:mm A") }}</p>
                </div>
                <div class="col-12">
                  <p class="field__label">
                    {{ reserveContent.parkingOptionLabel }}
                  </p>
                  <p class="booking__label">
                    {{ selectedParkingLot | formatLocationTitle }}
                  </p>
                  <p class="mb-0 field__label font-weight-normal">
                    {{ selectedParkingLot.address }}
                  </p>
                </div>
              </div>
            </div>
            <div
              class="col-sm-12 col-md-6 pl-3 rewards__right-details-container"
            >
              <h3 class="mb-3">{{ reserveContent.estimatedCostLabel }}</h3>
              <div
                v-for="(quoteLine, index) in nonTaxQuoteLines"
                v-bind:key="'nonTaxQuoteLine' + index"
                class="row mb-1"
              >
                <template v-if="quoteLine.revenueType === 'P'">
                  <div class="col-8">
                    <p>
                      <strong
                        >{{ quoteLine.quantity }}-{{
                          translate(
                            lang,
                            quoteLine.descEnglish,
                            quoteLine.descFrench
                          )
                        }}</strong
                      >
                    </p>
                  </div>
                  <div class="col-4">
                    <p>
                      <strong>{{
                        parseAndSum([quoteLine.charge]) | formatCurrency
                      }}</strong>
                    </p>
                  </div>
                </template>

                <template v-else-if="quoteLine.revenueType === 'D'">
                  <div class="col-8 text-red">
                    <p>
                      {{
                        translate(
                          lang,
                          quoteLine.descEnglish,
                          quoteLine.descFrench
                        )
                      }}
                    </p>
                  </div>
                  <div class="col-4 text-red">
                    <p>
                      {{
                        parseAndSum([quoteLine.charge])
                          | formatCurrency
                          | dollarSignOnDiscount
                      }}
                    </p>
                  </div>
                </template>

                <template v-else-if="quoteLine.revenueType === 'S'">
                  <div class="col-8">
                    <p class="green-underline-link">
                      {{
                        translate(
                          lang,
                          quoteLine.descEnglish,
                          quoteLine.descFrench
                        )
                      }}
                      <template v-if="/fuel/gi.test(quoteLine.descEnglish)">
                        <a
                          class="ml-3 tooltip-marker"
                          data-toggle="tooltip"
                          data-placement="auto"
                          :title="reserveContent.tooltips.fuelSurcharge"
                          ><span class="icon-pnf-support-questions"></span
                        ></a>
                      </template>
                      <template v-else>
                        <a
                          class="ml-3 tooltip-marker"
                          data-toggle="tooltip"
                          data-placement="auto"
                          :title="
                            reserveContent.tooltips.additionalServiceLineItem
                          "
                          ><span class="icon-pnf-support-questions"></span
                        ></a>
                      </template>
                    </p>
                  </div>
                  <div class="col-4">
                    <p>
                      {{ parseAndSum([quoteLine.charge]) | formatCurrency }}
                    </p>
                  </div>
                </template>

                <template v-else>
                  <div class="col-8">
                    <p>
                      {{ quoteLine.quantity }}
                      {{
                        translate(
                          lang,
                          quoteLine.descEnglish,
                          quoteLine.descFrench
                        )
                      }}
                    </p>
                  </div>
                  <div class="col-4">
                    <p>
                      ({{ parseAndSum([quoteLine.charge]) | formatCurrency }})
                    </p>
                  </div>
                </template>
              </div>
              <div class="row mb-1">
                <template v-if="checkAdditionalServices.needsSUVSurcharge">
                  <div class="col-8">
                    <p class="green-underline-link">
                      {{ reserveContent.suvSurchargeLineLabel }}
                      <a
                        class="ml-3 tooltip-marker"
                        data-toggle="tooltip"
                        data-placement="auto"
                        :title="reserveContent.tooltips.suvSurchargeLineItem"
                        ><span class="icon-pnf-support-questions"></span
                      ></a>
                    </p>
                  </div>
                  <div class="col-4">
                    <p>
                      {{
                        parseAndSum([service.surcharge.rate]) | formatCurrency
                      }}
                    </p>
                  </div>
                </template>

                <div class="col-8">
                  <p>{{ reserveContent.subTotalLineLabel }}</p>
                </div>
                <div class="col-4">
                  <p>
                    {{
                      parseAndSum([
                        quote.totalBeforeTax,
                        checkAdditionalServices.vehicleSurcharge.rate,
                      ]) | formatCurrency
                    }}
                  </p>
                </div>
              </div>

              <div
                v-for="(quoteLine, index) in taxQuoteLines"
                v-bind:key="'taxQuoteLine' + index"
                class="row mb-1"
              >
                <template
                  v-if="RegExp(/(parking\stax)/i).test(quoteLine.descEnglish)"
                >
                  <div class="col-8">
                    <p>
                      {{ quoteLine.quantity }}
                      {{
                        translate(
                          lang,
                          quoteLine.descEnglish,
                          quoteLine.descFrench
                        )
                      }}
                      <a
                        class="ml-3 tooltip-marker"
                        data-toggle="tooltip"
                        data-placement="auto"
                        :title="reserveContent.tooltips.parkingTax"
                        ><span class="icon-pnf-support-questions"></span
                      ></a>
                    </p>
                  </div>
                  <div class="col-4">
                    <p>
                      {{ parseAndSum([quoteLine.charge]) | formatCurrency }}
                    </p>
                  </div>
                </template>
                <template v-else>
                  <div class="col-8">
                    <p>
                      {{
                        translate(
                          lang,
                          quoteLine.descEnglish,
                          quoteLine.descFrench
                        )
                      }}
                    </p>
                  </div>
                  <div class="col-4">
                    <p>
                      {{
                        parseAndSum([
                          quoteLine.charge,
                          checkAdditionalServices.vehicleSurcharge.tax,
                        ]) | formatCurrency
                      }}
                    </p>
                  </div>
                </template>
              </div>

              <div class="row">
                <div class="col-8">
                  <p>
                    <strong>{{
                      reserveContent.estimatedChargesLineLabel
                    }}</strong>
                  </p>
                </div>
                <div class="col-4">
                  <p>
                    <strong>{{
                      parseAndSum([
                        quote.quoteTotal,
                        checkAdditionalServices.vehicleSurcharge.total,
                      ]) | formatCurrency
                    }}</strong>
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <p class="text-red mb-0">{{ reserveContent.rateWarningMember }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="row p-4">
            <div class="col-sm-12">
              <p class="small "><strong>{{content.conditionsBold}}: </strong>{{content.conditions}}</p>
              <p class="small "><strong>{{content.contractBold}}: </strong>{{content.contract}}</p>
              <p class="small font-italic">{{ content.terms }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
/*
  global
    CURRENT_LANGUAGE
    parkingLotsList
*/
import { router } from "../components/routes";
import Content from "../../string_resources/thank-you-member.json";
import ReserveContent from "../../string_resources/reserve.json";
import GetUser from "../util/users";
import store from "../components/store";
import * as util from "../util/shared";
import moment from "moment";
moment.locale(CURRENT_LANGUAGE);

export default {
  name: "thankYouMemberView",
  data() {
    return {
      content: Content[CURRENT_LANGUAGE],
      reserveContent: ReserveContent[CURRENT_LANGUAGE],
      confirmationId: 0,
      translate: util.translate,
      retrievalId: this.$store.getters.getRetrievalId,
      points: this.$store.getters.getRewardsPoints,
      dropOff: moment(this.$store.getters.getDropOff, "YYYY-M-D h:m"),
      pickUp: moment(this.$store.getters.getPickUp, "YYYY-M-D h:m"),
      language: CURRENT_LANGUAGE,
      rewardsSubscribe: null,
      rewardsUpdateRequests: 0,
      showReservationSent: this.$store.getters.getAuthUserRole !== "agent",
    };
  },
  beforeCreate: function () {
    this.$store = store;
  },
  methods: {
    parseAndSum: function (numberArr) {
      return numberArr.reduce(
        (a, b) => a + Number(`${b}`.replace(/,|\$/g, "")),
        0
      );
    },
    startOver: function (evt) {
      evt.preventDefault();
      router.replace("/default");
    },
    checkRewards: function () {
      GetUser().then((user) => {
        if (
          user.reward_member.points_balance !== this.points ||
          this.rewardsUpdateRequests < 10
        ) {
          this.points = user.reward_member.points_balance ;
          this.$store.dispatch("setRewardsPoints",user.reward_member.points_balance );
          clearTimeout(this.rewardsSubscribe);
        } else {
          this.rewardsUpdateRequests++;
          this.rewardsSubscribe = setTimeout(this.checkRewards, 2000);
        }
      });
    },
  },
  computed: {
    selectedParkingLot: function () {
      return parkingLotsList.filter(
        (d) => d.location_id == this.quote.locationId
      )[0];
    },
    checkAdditionalServices: function () {
      const _this = this;
      const conditions = {
        hasAddedServices: !!_this.service,
        needsSUVSurcharge: !!(
          _this.service &&
          _this.service.surcharge &&
          _this.service.surcharge.rate &&
          (_this.vehicle.type === 2 || _this.vehicle.type === 4)
        ), // Vehicle is SUV or Truck
      };
      const charges =
        (conditions.hasAddedServices ? _this.service.rate : 0) +
        (conditions.needsSUVSurcharge ? _this.service.surcharge.rate : 0);
      const surchargeRate = conditions.needsSUVSurcharge
        ? _this.service.surcharge.rate
        : 0;
      const surchargeTax = conditions.needsSUVSurcharge
        ? _this.service.surcharge.rate * 0.13
        : 0;
      const vehicleSurcharge = {
        rate: surchargeRate,
        tax: surchargeTax,
        total: surchargeTax + surchargeRate,
      };
      const tax = charges * 0.13;
      return {
        ...conditions,
        charges,
        tax,
        vehicleSurcharge,
        total: Number(charges) + Number(tax),
      };
    },
    nonTaxQuoteLines: function () {
      return this.quote.quoteLines.filter(
        (quoteLine) => quoteLine.revenueType !== "T"
      );
    },
    taxQuoteLines: function () {
      return this.quote.quoteLines.filter(
        (quoteLine) => quoteLine.revenueType === "T"
      );
    },
    currentUser: function () {
      if (this.$store.getters.getAuthState) {
        let name = this.$store.getters.getAuthUser;
        name = name ? decodeURIComponent(name.replace(/\+/g, " ")) : name;

        if (!this.isReferral) {
          name = name.split(" ");
          name = { firstName: name[0], lastName: name[1] };
        } else {
          name = { firstName: name, lastName: "" };
        }
        return name;
      }
    },
    firstName: function () {
      var user = this.currentUser;
      return user ? user.firstName : " ";
    },
    lang: function () {
      return CURRENT_LANGUAGE;
    },
    formOptions: function () {
      return {
        airlines: this.$store.getters.getAirlines,
        color: this.$store.getters.getVehicleColours,
        type: this.$store.getters.getVehicleTypes,
        make: this.$store.getters.getVehicleMakes,
        provinces: this.$store.getters.getVehicleProvinces,
      };
    },
    showAirportDetails: function () {
      if (this.activeLocation) {
        return this.activeLocation.city !== "Halifax";
      }
      return false;
    },
    checkAdditionalServices: function () {
      const _this = this;
      const conditions = {
        hasAddedServices: !!_this.service,
        needsSUVSurcharge: !!(
          _this.service &&
          _this.service.surcharge &&
          _this.service.surcharge.rate &&
          (_this.vehicle.type === 2 || _this.vehicle.type === 4)
        ), // Vehicle is SUV or Truck
      };
      const charges =
        (conditions.hasAddedServices ? _this.service.rate : 0) +
        (conditions.needsSUVSurcharge ? _this.service.surcharge.rate : 0);
      const surchargeRate = conditions.needsSUVSurcharge
        ? _this.service.surcharge.rate
        : 0;
      const surchargeTax = conditions.needsSUVSurcharge
        ? _this.service.surcharge.rate * 0.13
        : 0;
      const vehicleSurcharge = {
        rate: surchargeRate,
        tax: surchargeTax,
        total: surchargeTax + surchargeRate,
      };
      const tax = charges * 0.13;
      return {
        ...conditions,
        charges,
        tax,
        vehicleSurcharge,
        total: Number(charges) + Number(tax),
      };
    },
    quote: function () {
      return this.$store.getters.getSelectedQuote;
    },
    isLoggedIn: function () {
      return (
        this.$store.getters.getAuthState &&
        this.$store.getters.getAuthUserRole === "member"
      );
    },
    phoneNumberDigits: function () {
      return this.phoneNumber.replace(/[^0-9]/g, "");
    },
    selectedParkingLot: function () {
      return parkingLotsList.filter(
        (d) => d.location_id == this.quote.locationId
      )[0];
    },
    freeDaysAvailable: function () {
      return this.quote.redeemableDays
        ? this.quote.redeemableDays <= this.quote.maxDaysToRedeem
          ? this.quote.redeemableDays
          : this.quote.maxDaysToRedeem
        : 0;
    },
    maxDaysToRedeem: function () {
      return this.quote.maxDaysToRedeem
        ? this.quote.maxDaysToRedeem
        : this.quote.redeemableDays
        ? this.quote.redeemableDays
        : 0;
    },
    isDisabled: function () {
      this.checkCanSubmitForm();
      return !this.canSubmitForm;
    },
    contactValidationNeeded: function () {
      return false;
    },
    isValidPhoneNumber: function () {
      return true;
    },
    isValidEmailAddress: function () {
      return Validation.email.test(this.emailAddress);
    },
    flightNumber: function () {
      return this.flight.number.replace(/\D/g, "");
    },
    canRedeemDays: function () {
      return this.isLoggedIn && (this.freeDaysAvailable || this.allowUndo);
    },
    valetServices() {
      if (this.selectedParkingLot.valet_services) {
        let list = this.selectedParkingLot.valet_services.tiers;
        return list != undefined && list.length > 0 ? list : false;
      }
      return false;
    },
    loading() {
      return this.$store.getters.getLoading;
    },
  },
  filters: {
    formatLocationName(text) {
      text = text.toString();
      let formattedText = text.replace(/^[a-zàâçéèêëîïôûùüÿñæœ]+\s/i, "");
      return formattedText.trim() == ""
        ? text.replace(/^([A-Z]+\w+)([A-Z])/, "$1-$2")
        : formattedText.replace(/^([A-Z]+\w+)([A-Z])/, "$1-$2");
    },
    formatDiscount(discount) {
      return discount == 0 ? "N/A" : discount;
    },
    formatPhoneNumber(phoneNumber) {
      var cleaned = ("" + phoneNumber.toString()).replace(/\D/g, "");
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return phoneNumber;
    },
    capitalize(text) {
      return text ? text.charAt(0).toUpperCase() + text.slice(1) : " ";
    },
    formatCurrency(text) {
      try {
        const parsedNumberText = `${text}`.replace(/,|\$/g, "");
        return Number(parsedNumberText).toLocaleString("en-CA", {
          style: "currency",
          currency: "CAD",
        });
      } catch (err) {
        return err ? text : "";
      }
    },
    padRight(text) {
      if (text != undefined) {
        text = text.toString();
        return text.charAt(text.length - 2) == "." ? text + "0" : text;
      }
    },

    formatLocationTitle: (val) =>
      util.OfferFilters[CURRENT_LANGUAGE].formatLocationTitle(val),
    dollarSignOnDiscount: (val) => val.replace("-$?", "-$"),
  },
  created() {
    this.confirmationId = this.$store.getters.getConfirmationId;
    this.retrievalId = this.$store.getters.getRetrievalId;
  },
  mounted() {
    this.checkRewards();
    this.service = this.quote.service;
  },
};
</script>
