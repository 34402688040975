<template>
  <section>
    <div class="container my-6">
      <div class="row">
        <div class="col-sm-12 form-container">
          <div
            class="form-header green-underline mb-2 d-flex justify-content-between"
          >
            <h1>{{ content.emailConfirmation }}</h1>
          </div>
          <div class="row inner-form" v-if="loading">
            <p class="text-center col-12">{{ content.validatingMesssage }}</p>
            <div class="col-12">
              <div class="text-center my-2">
                <img
                  src="/wp-content/themes/parknfly/resources/assets/images/loading.gif"
                  width="65"
                  height="65"
                />
              </div>
            </div>
          </div>
          <div class="row inner-form" v-if="!loading && !showError">
            <h3 class="col-sm-12">{{ content.emailConfirmationHeader }}</h3>
            <p class="col-sm-12">{{ content.confirmationMessage }}</p>
            <p class="col-sm-12">
              {{ content.askSupport[0] }}
              <a
                class="blue-link inherit-font"
                :href="language === 'en' ? '/support/' : '/fr/soutien/'"
                target="_blank"
                >{{ content.askSupport[1] }}</a
              >
            </p>
            <div class="col-sm-12 mb-4 mt-4">
              <a
                class="btn btn--md mr-4 mb-4"
                :href="`${language == 'en' ? '/' : '/fr'}`"
                >{{ content.home }}</a
              >
            </div>
          </div>
          <div class="row inner-form" v-if="!loading && showError">
            <p class="col-sm-12">{{ content.errors.generic }}</p>
            <p class="col-sm-12">
              {{ content.askSupport[0] }}
              <a
                class="blue-link inherit-font"
                :href="language === 'en' ? '/support/' : '/fr/soutien/'"
                target="_blank"
                >{{ content.askSupport[1] }}</a
              >
            </p>
            <div class="col-sm-12 mb-4 mt-4">
              <a
                class="btn btn--md mr-4 mb-4"
                :href="`${language == 'en' ? '/' : '/fr'}`"
                >{{ content.home }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
/*
  global
    CURRENT_LANGUAGE
*/
import Content from "../../string_resources/confirm-email.json";
import axios from "axios";
export default {
  name: "confirmEmail",
  data() {
    return {
      content: Content[CURRENT_LANGUAGE],
      loading: true,
      language: CURRENT_LANGUAGE,
      key: null,
      showError: false,
    };
  },
  watch: {},
  methods: {
    getUrlParameter: function (sParam) {
      var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split("&"),
        sParameterName,
        i;

      for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split("=");

        if (sParameterName[0] === sParam) {
          return sParameterName[1] === undefined
            ? true
            : decodeURIComponent(sParameterName[1]);
        }
      }
      return false;
    },
  },
  mounted() {
    var params = new URLSearchParams(window.location.search);
    this.key = params.get("key");
    this.key = this.key.replace(/\s/g, "+");
    this.key = encodeURIComponent(this.key);
    axios
      .post("/wp-admin/admin-ajax.php?action=pnf_validate_email", {
        key: this.key,
      })
      .then((result) => {
        if (result.data.result.status === "Error") {
          this.showError = true;
          this.loading = false;
        } else {
          window.location.href =
            CURRENT_LANGUAGE === "en"
              ? "/email-confirmation-success/"
              : "/fr/email-confirmation-success/"
        }
      })
      .catch(() => {
        this.showError = true;
        this.loading = false;
      });
  },
};
</script>
