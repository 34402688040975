<template>
  <div class="row inner-form" :class="{ 'collapse collapsed': !displayOnly }">
    <div v-if="preload" class="loading-spinner">
      <img
        src="/wp-content/themes/parknfly/resources/assets/images/loading.gif"
        width="100"
        height="100"
      />
    </div>
    <div v-if="!preload && hasRates" class="col-12" style="overflow-x: auto">
      <p class="d-block d-sm-none">{{ content.valetTable.mobile }}</p>
      <table
        class="table table-bordered bg-light additional-services-table mb-3"
      >
        <thead>
          <tr class="table-dark border-light">
            <th class="border-light" scope="col">
              {{ content.valetTable.service }}
            </th>
            <th
              v-if="hasTier('bronze')"
              class="text-center border-light"
              scope="col"
            >
              {{ content.valetTable.bronze }}
            </th>
            <th
              v-if="hasTier('silver')"
              class="text-center border-light"
              scope="col"
            >
              {{ content.valetTable.silver }}
            </th>
            <th
              v-if="hasTier('gold')"
              class="text-center border-light"
              scope="col"
            >
              {{ content.valetTable.gold }}
            </th>
            <th
              v-if="hasTier('platinum')"
              class="text-center border-light"
              scope="col"
            >
              {{ content.valetTable.platinum }}
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="service in valetServices">
            <tr scope="row" v-bind:key="service.title">
              <td v-html="service.title" scope="col"></td>
              <td
                v-if="hasTier('bronze')"
                scope="col"
                class="position-relative"
              >
                <span v-if="service.bronze" class="big-green-check"></span>
              </td>
              <td
                v-if="hasTier('silver')"
                scope="col"
                class="position-relative"
              >
                <span v-if="service.silver" class="big-green-check"></span>
              </td>
              <td v-if="hasTier('gold')" scope="col" class="position-relative">
                <span v-if="service.gold" class="big-green-check"></span>
              </td>
              <td
                v-if="hasTier('platinum')"
                scope="col"
                class="position-relative"
              >
                <span v-if="service.platinum" class="big-green-check"></span>
              </td>
            </tr>
          </template>
          <tr scope="row">
            <td scope="col"></td>
            <td
              v-for="serviceItem in additionalServicePricing"
              class="text-center"
              scope="col"
              :key="
                serviceItem ? serviceItem.name + serviceItem.serviceId : null
              "
            >
              <template v-if="!serviceAdded && serviceItem">
                <div class="mt-1">
                  ${{ serviceItem.rate }} + {{ content.taxLine }}
                </div>
                <p v-if="serviceItem.surcharge">
                  <small v-if="serviceItem.surcharge.rate"
                    >({{ content.valetTable.surcharge }}: ${{
                      serviceItem.surcharge.rate
                    }})</small
                  >
                  <small v-if="!serviceItem.surcharge.rate">&nbsp;</small>
                </p>
                <a
                  class="btn py-2 px-4 mb-2 text-white"
                  @click="alterServices('add', serviceItem)"
                  v-if="!displayOnly"
                  >{{ content.valetTable.add }}</a
                >
              </template>
              <template v-if="serviceAdded && serviceItem">
                <div class="mt-1">
                  ${{ serviceItem.rate }} + {{ content.taxLine }}
                </div>
                <p v-if="serviceItem.surcharge">
                  <small v-if="serviceItem.surcharge.rate"
                    >({{ content.valetTable.surcharge }}: ${{
                      serviceItem.surcharge.rate
                    }})</small
                  >
                  <small v-if="!serviceItem.surcharge.rate">&nbsp;</small>
                </p>
                <template v-if="!displayOnly">
                  <a
                    v-if="serviceAdded.serviceId != serviceItem.serviceId"
                    class="btn py-2 px-4 mb-2 text-white disabled"
                    >{{ content.valetTable.add }}</a
                  >
                  <a
                    v-if="serviceAdded.serviceId == serviceItem.serviceId"
                    class="btn py-2 px-4 mb-2 text-white"
                    @click="alterServices('remove')"
                    >{{ content.valetTable.remove }}</a
                  >
                </template>
              </template>

              <template v-if="!serviceItem">
                <p class="d-block mt-2 mb-2 text-red text-center">
                  {{ content.valetTable.noDetailingService }}
                </p>
              </template>
            </td>
          </tr>
        </tbody>
      </table>

      <p class="smaller-text">{{ content.valetTable.surchargeMessage }}</p>
    </div>
    <div v-if="!preload && !hasRates" class="col-12">
      <p class="d-block mt-5 mb-5 text-red text-center">
        {{ content.valetTable.noDetailingService }}
      </p>
    </div>
  </div>
</template>
<script>
/*
  global
    CURRENT_LANGUAGE
    parkingLotsList
*/
import moment from "moment";
import Content from "../../string_resources/reserve.json";
import axios from "axios";
import store from "../components/store";

moment.locale(CURRENT_LANGUAGE);
export default {
  name: "additionalServices",
  props: {
    displayOnly: { default: false, type: Boolean },
    locationId: { default: "", type: String },
  },
  data() {
    return {
      content: Content[CURRENT_LANGUAGE],
      service: false,
      additionalServicePricing: [],
      preload: true,
      selectedLocation:
        this.$store.getters.getLocation.ID || CURRENT_LANGUAGE === "en"
          ? 20
          : 100,
      hasRates: false,
    };
  },
  watch: {
    quote: function (quote) {
      if (this.displayOnly) {
        return;
      }
      if (quote && this.service) {
        if (
          !this.quote.service ||
          this.quote.service.serviceId !== this.service.serviceId
        ) {
          this.quote.service = this.service;
        }
      }
    },
  },
  methods: {
    getServiceRates: function () {
      let $this = this;
      if (!this.isValet) return;

      let ratesPayload = {
        services: $this.selectedParkingLot.valet_services.service_ids,
        estimate: this.displayOnly,
        args: {
          city: this.selectedParkingLot.city,
          openDate: moment().format("Y-M-D H:mm"),
          closeDate: moment().add(7, "days").format("Y-M-D H:mm"),
          groupCouponId: "0",
        },
      };

      this.$store.dispatch("fetchServiceRates", ratesPayload).then((d) => {
        if (d) {
          // Convert service pricing object to array for data filtering
          let serviceIdArr = Object.values(
            $this.selectedParkingLot.valet_services.service_ids["Car"]
          );

          serviceIdArr.map((currentService, index) => {
            if (currentService) {
              //Map services to rendering property
              let service = d.data.response.filter(
                (s) => s.serviceId == currentService
              )[0];
              if (service) {
                service.name = $this.getServiceNameFromId(currentService);
                let surcharges =
                  $this.selectedParkingLot.valet_services.service_ids[
                    "SUV, Van & Truck"
                  ];
                if (surcharges) {
                  // Convert surcharge object to array for data filtering
                  let surchargeList = Object.values(surcharges);
                  // Assign the appropriate surcharge to the service given its index
                  service.surcharge = d.data.response.filter(
                    (s) => s.serviceId == surchargeList[index]
                  )[0];
                }
              }
              // Bind results to vue prop for rendering
              $this.$set($this.additionalServicePricing, index, service);
              $this.preload = false;
            }
          });
        }
        for (var i = 0; i < $this.additionalServicePricing.length; i++) {
          $this.hasRates =
            $this.hasRates ||
            typeof $this.additionalServicePricing[i] !== "undefined";
        }
        if (!$this.hasRates) {
          $("#expand-valet-services-link").hide();
          $("#reserveDetailingUnavailable").show();
        }
      });
    },
    getServiceNameFromId: function (id) {
      let serviceList = this.selectedParkingLot.valet_services.service_ids.Car;
      //returns string of service level for content string to be displayed
      return Object.entries(serviceList)
        .filter((s) => s[1] == id)[0][0]
        .replace("_id", "");
    },
    hasTier: function (tier) {
      // Check if a service has a certain tier in order to render that tier column
      return this.valetServices
        ? !!this.valetServices.filter((s) => s[tier]).length
        : false;
    },
    alterServices: function (alterType, passedService = false) {
      let routeArgs;
      let callback;
      let $this = this;
      if (this.displayOnly) {
        return;
      }
      switch (alterType) {
        case "add":
          routeArgs = [
            "/wp-admin/admin-ajax.php?action=pnf_add_service_to_quote",
            {
              quoteId: this.quote.quoteId,
              serviceId: passedService.serviceId,
            },
          ];
          callback = () => {
            this.service = passedService;
            this.quote.service = passedService;
          };
          break;
        case "remove":
          routeArgs = [
            "/wp-admin/admin-ajax.php?action=pnf_remove_service_from_quote",
            {
              quoteId: this.quote.quoteId,
              serviceId: this.quote.service.serviceId,
            },
          ];
          callback = () => {
            this.service = null;
            this.quote.service = null;
          };
      }

      let ajaxProps = [...routeArgs, { timeout: 10000 }];

      return new Promise((resolve, reject) => {
        axios
          .post(...ajaxProps)
          .then((response) => {
            if (callback) {
              callback(response);
              const quoteLines =
                response.data.result.confirmation.confirmedQuoteLines;
              this.$store.dispatch("updateSelectedQuote", {
                ...this.quote,
                ...response.data.result.confirmation,
                quoteLines,
              });
              $this.$emit("serviceChanged", $this.service);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  computed: {
    serviceAdded: function () {
      if (this.displayOnly) {
        return;
      }
      return this.service
        ? this.service
        : this.quote.service
        ? this.quote.service
        : false;
    },
    quote: function () {
      if (this.displayOnly) {
        return;
      }
      return this.$store.getters.getSelectedQuote;
    },
    selectedParkingLot: function () {
      return parkingLotsList.filter(
        (d) =>
          d.location_id ==
          (this.locationId ? this.locationId : this.quote.locationId)
      )[0];
    },
    isValet() {
      return this.selectedParkingLot.valet_services != null;
    },
    valetServices() {
      if (this.selectedParkingLot.valet_services) {
        let list = this.selectedParkingLot.valet_services.tiers;
        return list != undefined && list.length > 0 ? list : false;
      }
      return false;
    },
  },
  mounted() {
    this.getServiceRates();
  },
  beforeCreate: function () {
    this.$store = store;
  },
};
</script>
