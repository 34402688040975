<template >
  <section>
    <div class="my-4">
      <h1 class="smaller">{{ content.sectionLabels.editProfile }}</h1>
      <p>{{ content.editProfileIntro }}</p>
    </div>
    <form
      id="registration-form"
      @submit="saveProfile"
      v-if="user && userLoaded"
    >
      <div class="row">
        <div class="two-col-form row ignore-margin col-12 col-lg-8 m-0">
          <div class="inner-form bg-white rounded p-4 border col-12">
            <div class="form-row justify-content-between">
              <div class="col-12 padding-fix mt-3 mb-4">
                <h2>{{ content.sectionLabels.information }}</h2>
                <p class="form-group required mb-0">
                  <label>{{ content.fieldLabels.required }}</label>
                </p>
              </div>
              <div class="form-group mt-0 required">
                <label for="field-firstName">{{
                  content.fieldLabels.firstName
                }}</label>
                <input
                  class="form-control form-control-lg"
                  v-model="userFormData.firstName"
                  type="text"
                  name="firstName"
                  id="field-firstName"
                  required
                  validate-name
                />
              </div>
              <div class="form-group mt-0 required">
                <label for="field-lastName">{{
                  content.fieldLabels.lastName
                }}</label>
                <input
                  class="form-control form-control-lg"
                  v-model="userFormData.lastName"
                  type="text"
                  name="lastName"
                  id="field-lastName"
                  required
                  validate-name
                />
              </div>
            </div>
            <div class="form-row justify-content-between">
              <div class="form-group mt-0 required w-100">
                <label for="field-address-postal">{{
                  content.fieldLabels.postal
                }}</label>
                <input
                  class="form-control form-control-lg"
                  v-model="userFormData.postalCode"
                  type="text"
                  name="postalCode"
                  id="field-address-postal"
                  required
                  validate-postal
                />
              </div>
            </div>
            <div class="inner-form border-top py-4">
              <div class="form-row justify-content-between mb-4">
                <h2 class="mb-0">{{ content.sectionLabels.signIn }}</h2>
                <span class="flex-spacer"></span>
                <button
                  class="btn btn-outline btn-sm"
                  v-if="isEmailChanged"
                  @click="resetEmail()"
                >
                  Reset Email
                </button>
                <button
                  @click="resetPhoneNumber()"
                  class="btn btn-outline btn-sm ml-3"
                  v-if="isCellPhoneChanged"
                >
                  Reset Phone
                </button>
              </div>
              <div
                class="justify-content-between"
                :class="{ 'form-row': isEmailChanged }"
              >
                <div class="form-group mt-0 required">
                  <label for="field-email">{{
                    content.fieldLabels.email
                  }}</label>
                  <input
                    class="form-control form-control-lg"
                    v-model="userFormData.email"
                    type="email"
                    maxlength="100"
                    name="email"
                    id="field-email"
                  />
                </div>
                <div class="form-group mt-0 required" v-if="isEmailChanged">
                  <label for="field-email">{{
                    content.fieldLabels.confirmEmail
                  }}</label>
                  <input
                    class="form-control form-control-lg"
                    v-model="confirmEmail"
                    type="email"
                    maxlength="100"
                    name="email"
                    required="true"
                    id="field-confirm-email"
                  />
                </div>
              </div>
              <div v-show="!isEmailValid">
                <p class="text-red mb-0">
                  {{ content.error.confirmEmail }}
                </p>
              </div>

              <div class="mt-5">
                <div class="form-group mt-0 required">
                  <label for="field-cellNumber"
                    >{{ content.fieldLabels.phoneNumber }}
                  </label>
                  <input
                    class="form-control form-control-lg"
                    type="tel"
                    name="cellNumber"
                    id="field-cellNumber"
                    v-mask="'(###) ###-####'"
                    v-model="userFormData.cellNumber"
                    :disabled="phoneNumberDisabled"
                    @focus="showError.phone = false"
                    @blur="showError.phone = !isValidPhoneNumber"
                    :aria-label="content.fieldLabels.phoneNumberPH"
                  />
                </div>
              </div>
              <div
                class="form-row justify-content-between mt-4 mb-2"
                v-if="isCellPhoneChanged"
              >
                <input type="hidden" id="verified-email-hash" required />
                <validation-code
                  emailAddressId="field-email"
                  verifiedHashId="verified-email-hash"
                  phoneNumberId="field-cellNumber"
                  bothContactsAccepted
                  contactType="E"
                  v-on:hash-received="storeHash"
                  v-on:phone-validation-reset="resetPhoneNumber"
                  v-on:verification-started="disablePhoneNumber"
                  v-on:step-one-error="enablePhoneNumber"
                />
              </div>
            </div>
            <div class="inner-form py-4 border-top">
              <div class="form-row">
                <h2>{{ content.sectionLabels.additionalInfo }}</h2>
              </div>
              <div class="form-row justify-content-between">
                <div class="form-group mt-0">
                  <label for="field-aeroplan-number"
                    >{{ content.fieldLabels.aeroplanNumber }}
                  </label>
                  <input
                    class="form-control form-control-lg"
                    v-model="userFormData.aeroplanNumber"
                    type="text"
                    name="aeroplanNumber"
                    id="field-aeroplan-number"
                  />
                </div>
                <div class="form-group mt-0">
                  <label for="field-corporate-discount-number"
                    >{{ content.fieldLabels.corporateDiscountNumber }}
                  </label>
                  <input
                    class="form-control form-control-lg"
                    v-model="userFormData.corporateDiscountNumber"
                    type="number"
                    name="corporateDiscountNumber"
                    id="field-corporate-discount-number"
                  />
                </div>
              </div>
              <div class="form-row justify-content-between">
                <div class="form-group mt-0">
                  <label for="field-location">{{
                    content.fieldLabels.location
                  }}</label>
                  <div class="select-wrapper --large">
                    <select
                      class="form-control form-control-lg"
                      v-model="userFormData.preferredCityId"
                      name="location"
                      id="field-location"
                      required
                    >
                      <option disabled hidden></option>
                      <template v-for="city in cities">
                        <option :key="city.city_id" :value="city.city_id">
                          {{ city.city_name | formatFrenchCharacters }}
                        </option>
                      </template>
                    </select>
                  </div>
                </div>
                <div class="form-group mt-0">
                  <label for="preferred-language">{{
                    content.fieldLabels.language
                  }}</label>
                  <div class="select-wrapper --large">
                    <select
                      class="form-control form-control-lg"
                      id="preferred-language"
                      v-model="userFormData.preferredLanguage"
                    >
                      <option disabled hidden></option>
                      <option value="English">English</option>
                      <option value="Français">Français</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <!-- Vehicle Sections -->
            <div id="vehicle-section" class="inner-form py-4 border-top">
              <div
                class="form-row d-flex justify-content-between align-items-center"
              >
                <h2 class="mb-0">{{ content.sectionLabels.vehicleInfo }}</h2>
                <button
                  type="button"
                  class="btn"
                  v-if="!showVehicleForm"
                  @click="setFormToAddNewVehicle()"
                >
                  Add New Vehicle
                </button>
              </div>
              <div
                class="form-row justify-content-between"
                v-if="showVehicleForm"
              >
                <div class="form-group mt-0 required">
                  <label for="vehicle-make">{{
                    content.fieldLabels.vehicleMake
                  }}</label>
                  <div class="select-wrapper --large">
                    <select
                      class="form-control form-control-lg"
                      id="vehicle-make"
                      reqiured
                      v-model="vehicleFormData.makeId"
                    >
                      <option disabled selected value="-1">
                        {{ content.fieldLabels.pleaseSelect }}
                      </option>
                      <template v-for="make in formOptions.make">
                        <option
                          v-bind:key="'make' + make.vehicle_make_id"
                          v-bind:value="make.vehicle_make_id"
                        >
                          {{ make.make_name | capitalize }}
                        </option>
                      </template>
                    </select>
                  </div>
                </div>
                <div class="form-group mt-0 required">
                  <label for="vehicle-type">{{
                    content.fieldLabels.vehicleType
                  }}</label>
                  <div class="select-wrapper --large">
                    <select
                      class="form-control form-control-lg"
                      id="vehicle-type"
                      reqiured
                      v-model="vehicleFormData.typeId"
                    >
                      <option disabled selected value="-1">
                        {{ content.fieldLabels.pleaseSelect }}
                      </option>
                      <template v-for="type in formOptions.type">
                        <option
                          v-bind:key="'type' + type.vehicle_type_id"
                          v-bind:value="type.vehicle_type_id"
                        >
                          {{ type.body_type | capitalize }}
                        </option>
                      </template>
                    </select>
                  </div>
                </div>
                <div class="form-group mt-0 required">
                  <label for="vehicle-color">{{
                    content.fieldLabels.vehicleColor
                  }}</label>
                  <div class="select-wrapper --large">
                    <select
                      class="form-control form-control-lg"
                      id="vehicle-color"
                      reqiured
                      v-model="vehicleFormData.colorId"
                    >
                      <option disabled selected value="-1">
                        {{ content.fieldLabels.pleaseSelect }}
                      </option>
                      <template v-for="color in formOptions.color">
                        <option
                          v-bind:key="'color' + color.vehicle_colour_id"
                          v-bind:value="color.vehicle_colour_id"
                        >
                          {{ color.colour | capitalize }}
                        </option>
                      </template>
                    </select>
                  </div>
                </div>
                <div class="form-group mt-0 required">
                  <label for="license-number">{{
                    content.fieldLabels.plateNumber
                  }}</label>
                  <input
                    id="license-number"
                    class="form-control form-control-lg"
                    type="text"
                    reqiured
                     :disabled="vehicleFormData.vehicleId !== 0"
                    maxlength="10"
                    v-model="vehicleFormData.licenseNumber"
                  />
                </div>
                <div class="form-group mt-0 required">
                  <label for="vehicle-province">{{
                    content.fieldLabels.province
                  }}</label>
                  <div class="select-wrapper --large">
                    <select
                      class="form-control form-control-lg"
                      id="vehicle-province"
                      :disabled="vehicleFormData.vehicleId !== 0"
                      reqiured
                      v-model="vehicleFormData.licenseProvinceId"
                      :aria-label="content.fieldLabels.province"
                    >
                      <option disabled selected value="-1">
                        {{ content.fieldLabels.pleaseSelect }}
                      </option>
                      <template v-for="province in formOptions.provinces">
                        <option
                          v-bind:key="province.province_id"
                          v-bind:value="province.province_id"
                        >
                          {{ province.province_name | capitalize }}
                        </option>
                      </template>
                    </select>
                  </div>
                </div>
                <div class="form-group col-12 col-md-6 mt-2 mb-3">
                  <div class="inline-block">
                    <span class="d-inline-block">
                      <span class="text-red">*</span>
                      {{ content.fieldLabels.electricVehicle }}
                    </span>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="vehicle-electric"
                        id="vehicle-electric"
                        value="Y"
                        v-model="vehicleFormData.electronicVehicle"
                        :aria-label="content.fieldLabels.yes"
                        required
                      />
                      <label
                        class="form-check-label text-medium"
                        for="vehicle-electric"
                        >{{ content.fieldLabels.yes }}</label
                      >
                      <input
                        class="form-check-input ml-4"
                        type="radio"
                        name="vehicle-electric"
                        id="vehicle-electric"
                        value="N"
                        v-model="vehicleFormData.electronicVehicle"
                        :aria-label="content.fieldLabels.no"
                        required
                      />
                      <label
                        class="form-check-label text-medium"
                        for="vehicle-electric"
                        >{{ content.fieldLabels.no }}</label
                      >
                    </div>
                  </div>
                </div>
                <div class="form-group col-12 my-4">
                  <button
                    type="button"
                    class="btn btn-lg text-white"
                    v-if="currenEditVehicleIndex < 0"
                    @click="addVehicleToData"
                    :disabled="!isVehicleFormValid()"
                  >
                    {{ content.buttonLabels.addVehicle }}
                  </button>
                  <button
                    type="button"
                    class="btn btn-lg text-white"
                    v-if="currenEditVehicleIndex >= 0"
                    @click="updateVehicle"
                    :disabled="!isVehicleFormValid()"
                  >
                    Update Vehicle
                  </button>

                  <button
                    type="button"
                    class="btn cancel-button-text ml-2"
                    @click="cancelVehicleForm"
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <!-- Vehicle Table -->
              <div>
                <template
                  v-if="
                    this.userFormData.vehicles &&
                    this.userFormData.vehicles.length > 0
                  "
                >
                  <div class="table-responsive mt-4">
                    <table class="table">
                      <thead class="thead-grey">
                        <tr>
                          <th scope="col">
                            {{ content.fieldLabels.vehicleMake }}
                          </th>
                          <th scope="col">
                            {{ content.fieldLabels.vehicleType }}
                          </th>
                          <th scope="col">
                            {{ content.fieldLabels.vehicleColor }}
                          </th>
                          <th scope="col">
                            {{ content.fieldLabels.plateNumber }}
                          </th>
                          <th scope="col" class="no-border">
                            {{ content.fieldLabels.province }}
                          </th>
                          <th scope="col" class="no-border">
                            {{ content.fieldLabels.electricVehicleHeader }}
                          </th>
                          <th scope="col" class="no-border">&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody class>
                        <tr
                          v-for="(vehicle, index) in this.userFormData.vehicles"
                          :key="index"
                        >
                          <template v-if="vehicle != null">
                            <td>{{ getMakeName(vehicle.makeId) }}</td>
                            <td>{{ getTypeName(vehicle.typeId) }}</td>
                            <td>{{ getColourName(vehicle.colorId) }}</td>
                            <td>{{ vehicle.licenseNumber }}</td>
                            <td>
                              {{ getProvinceName(vehicle.licenseProvinceId) }}
                            </td>
                            <td>
                              {{
                                vehicle.electronicVehicle === "Y"
                                  ? content.fieldLabels.yes
                                  : content.fieldLabels.no
                              }}
                            </td>
                            <td
                              class="d-flex align-items-center justify-content-between"
                            >
                              <a
                                v-if="!showVehicleForm"
                                class="blue-link inherit-font pl-2 pr-2 mr-1"
                                @click="editVehicle(vehicle, index)"
                                ><i class="fa fa-pencil" aria-hidden="true"></i
                              ></a>
                              <a
                                v-if="!showVehicleForm"
                                class="blue-link inherit-font pl-2 pr-2"
                                @click="deleteVehicle(vehicle)"
                                ><i class="fa fa-trash-o" aria-hidden="true"></i
                              ></a>
                            </td>
                          </template>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </template>
                <div
                  v-if="this.userFormData.vehicles.length === 0"
                  class="text-center mt-5 mb-5 text-italics"
                >
                  <em> {{ content.fieldLabels.noVehicles }} </em>
                </div>
              </div>
              <div class="form-row col-12 pt-4 border-top px-0 w-100">
                <div class="col-12 padding-fix mt-4 pt-2">
                  <h2>{{ content.sectionLabels.notificationSettings }}</h2>
                </div>
                <p>
                  {{ content.fieldLabels.notificationMessage }}
                </p>

                <p>
                  <strong
                    >{{ content.fieldLabels.reservationCommunication }}:</strong
                  >
                  <br />
                  <em class="small">{{ content.fieldLabels.mustSelectOne }}</em>
                </p>

                <div class="form-row justify-content-between w-100 mb-3">
                  <div class="form-group d-flex mt-0">
                    <label
                      id="viaEmailComminication"
                      class="d-flex align-items-center"
                    >
                      <input
                        class="big-checkbox --align-top mr-3 d-block"
                        type="checkbox"
                        v-model="userFormData.resCommbyEmail"
                      />

                      {{ content.fieldLabels.viaEmail }}
                    </label>
                  </div>
                  <div class="form-group mt-0 d-flex">
                    <label class="d-flex align-items-center">
                      <input
                        class="big-checkbox --align-top mr-3 d-block"
                        type="checkbox"
                        v-model="userFormData.resCommbySMS"
                      />
                      {{ content.fieldLabels.viaSMS }}
                    </label>
                  </div>
                </div>
                <p>
                  <strong>{{ content.fieldLabels.newsAndOffer }}: </strong
                  ><br />
                </p>
                <div class="form-row justify-content-between w-100 mb-5">
                  <div class="form-group d-flex mt-0">
                    <label
                      id="viaEmailComminication"
                      class="d-flex align-items-center"
                    >
                      <input
                        class="big-checkbox --align-top mr-3 d-block"
                        type="checkbox"
                        v-model="userFormData.marketbyEmail"
                      />

                      {{ content.fieldLabels.viaEmail }}
                    </label>
                  </div>
                  <div class="form-group mt-0 d-flex">
                    <label class="d-flex align-items-center">
                      <input
                        class="big-checkbox --align-top mr-3 d-block"
                        type="checkbox"
                        v-model="userFormData.marketbySMS"
                      />
                      {{ content.fieldLabels.viaSMS }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 mt-4 mx-auto m-lg-0">
          <div class="row p-3 rounded bg-white border sticky-top m-0">
            <div class="col-12 mt-4">
              <h3>{{ content.needHelp }}</h3>
            </div>
            <div class="col-12 mb-4">
              <p>
                {{ content.registrationSupport[0] }}
                <a href="mailto:loyalty@parknfly.ca">{{
                  content.registrationSupport[1]
                }}</a>
              </p>
            </div>
             <div
              class="col-12 mt-3"
              v-show="formErrors.phoneNotValidated && isCellPhoneChanged"
            >
              <p class="text-red mt-4 mb-0">
                {{ content.error.validationNeeded }}
              </p>
            </div>
            <div class="col-12 mt-3" v-show="formErrors.resComm">
              <p class="text-red mt-4 mb-0">
                {{ content.error.commError }}
              </p>
            </div>
            <div class="col-12 my-3" v-if="!submitting">
              <input
                type="submit"
                class="btn btn-lg text-white btn-lg-text w-100 h-100 p-3 rounded"
                :value="content.buttonLabels.update"
              />
            </div>


            <div class="col-12 my-2" v-if="submitting">
              <div class="text-center">
                <img
                  src="/wp-content/themes/parknfly/resources/assets/images/loading.gif"
                  width="65"
                  height="65"
                />
              </div>
            </div>
            <div class="col-12"></div>
          </div>
        </div>
      </div>
    </form>
    <div v-if="!user && !userLoaded">
      <div class="my-2">
        <div class="text-center">
          <img
            src="/wp-content/themes/parknfly/resources/assets/images/loading.gif"
            width="100"
            height="100"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
/*
  global
    CURRENT_LANGUAGE
    window
*/
import GetUser from "../../util/users";
import store from "../../components/store";
import axios from "axios";
import Alert from "../../components/warning-banner.vue";
import * as util from "../../util/shared";
import Content from "../../../string_resources/member-edit.json";
import { mask } from "vue-the-mask";
import * as Cookies from "js-cookie";
import Validation from "../../util/formValidation";
import ValidationCode from "../../components/validation-code.vue";
const DEFAULT_VEHICLE = {
  vehicleId: 0,
  licenseNumber: null,
  licenseProvinceId: null,
  makeId: null,
  typeId: null,
  colorId: null,
  electronicVehicle: null,
};

export default {
  name: "member-edit",
  directives: { mask },
  components: { ValidationCode },
  data() {
    return {
      content: Content[CURRENT_LANGUAGE],
      userLoaded: false,
      user: null,
      addingVehicle: false,
      showError: {
        phone: false,
        email: false,
        postal: false,
      },
      userFormData: {
        rewardId: null,
        firstName: null,
        lastName: null,
        postalCode: null,
        email: null,
        cellNumber: null,
        aeroplanNumber: null,
        corporateDiscountNumber: null,
        vehicles: [],
        preferredCityId: null,
        preferredLanguage: null,
        marketbyEmail: null,
        marketbySMS: null,
        resCommbyEmail: null,
        resCommbySMS: null,
      },
      formIsValid: false,
      loadUserLoop: null,
      vehiclesToDelete: [],
      vehiclesToAdd: [],
      vehicleInfoHasBeenAdded: false,
      cities: null,
      // Vehicle Form Related Data
      vehicleFormData: { ...DEFAULT_VEHICLE },
      showVehicleForm: false,
      submitting: false,
      contactValidationHash: "",
      confirmEmail: "",
      phoneNumberDisabled: false,
      currenEditVehicleIndex: -1,
      formErrors: {
        phoneNotValidated: false,
        resComm: false,
      },
    };
  },
  watch: {
    user: function (user) {
      if (user) {
        this.userLoaded = true;
        setTimeout(util.styleDropdowns, 1000);
      }
    },
    userLoaded: function () {
      if (window.location.search === "?edit&vs") {
        setTimeout(
          () => document.getElementById("vehicle-section").scrollIntoView(),
          500
        );
      }
    },
  },
  methods: {
    disablePhoneNumber: function () {
      this.phoneNumberDisabled = true;
    },
    enablePhoneNumber: function () {
      this.phoneNumberDisabled = false;
    },
    resetEmail: function () {
      this.userFormData.email = this.user.customer.email_address;
    },
    resetPhoneNumber: function () {
      this.userFormData.cellNumber = this.user.customer.cellphone;
      this.enablePhoneNumber();
      this.contactValidationHash = "";
    },
    storeHash: function () {
      this.contactValidationHash = document.getElementById(
        "verified-email-hash"
      ).value;
    },
    capitalize(text) {
      return text ? text.charAt(0).toUpperCase() + text.slice(1) : " ";
    },
    addVehicleToData() {
      const vehicle = { ...this.vehicleFormData };
      this.userFormData.vehicles.push(vehicle);
      this.resetVehicleForm();
      this.showVehicleForm = false;
    },
    isVehicleFormValid() {
      return (
        this.vehicleFormData.licenseNumber !== null &&
        this.vehicleFormData.licenseProvinceId !== null &&
        this.vehicleFormData.makeId !== null &&
        this.vehicleFormData.typeId !== null &&
        this.vehicleFormData.electronicVehicle !== null &&
        this.vehicleFormData.colorId !== null
      );
    },
    cancelVehicleForm() {
      this.resetVehicleForm();
      this.showVehicleForm = false;
    },
    resetVehicleForm() {
      this.vehicleFormData = { ...DEFAULT_VEHICLE };
      this.currenEditVehicleIndex = -1;
    },
    deleteVehicle(vehicle) {
      this.userFormData.vehicles = this.userFormData.vehicles.filter(
        (v) => v.vehicleId !== vehicle.vehicleId
      );
    },
    editVehicle(vehicle, index) {
      this.currenEditVehicleIndex = index;
      this.vehicleFormData = { ...vehicle };
      this.showVehicleForm = true;
    },
    updateVehicle() {
      const vehicle = { ...this.vehicleFormData };
      this.userFormData.vehicles[this.currenEditVehicleIndex] = vehicle;
      this.resetVehicleForm();
      this.showVehicleForm = false;
    },
    setFormToAddNewVehicle() {
      this.showVehicleForm = true;
      this.resetVehicleForm();
    },
    saveProfile: function (e) {
      e.preventDefault();
      this.selectRequired();
      this.validateName();
      this.validatePhoneNumber();
      this.validatePlanNumber();
      this.validatePostalCode();
      this.formIsValid = $("#registration-form")[0].reportValidity();
      if (!this.isValidCommunication) {
        this.formErrors.resComm = true;
        return;
      } else {
        this.formErrors.resComm = false;
      }

      if (this.isCellPhoneChanged && this.contactValidationHash === "") {
        this.formErrors.phoneNotValidated = true;
        return;
      } else {
        this.formErrors.phoneNotValidated = false;
      }
      if (
        this.isEmailChanged &&
        this.userFormData.email !== this.confirmEmail
      ) {
        return;
      }
      if (this.formIsValid) {
        this.updateUser();
      }
    },
    selectRequired: function () {
      $("select[required]").each(() => {
        if ($(this).value == -1) {
          $(this).attr("isvalid", false);
        } else {
          $(this).attr("isvalid", true);
        }
      });
    },
    validatePhoneNumber: function () {
      $("[validate-phone]").each((index, el) => {
        var valid = el.value.replace(/[^0-9]/g, "").length == 10;
        if (valid) {
          el.setCustomValidity("");
        } else {
          el.setCustomValidity(this.content.error.phone);
          if (typeof el.onblur != "function") {
            el.onblur = this.validatePhoneNumber;
          }
        }
      });
    },
    validateEmail: function () {
      const re = Validation.email;
      return re.test(this.user.email);
    },
    validateName: function () {
      $("[validate-name]").each((index, el) => {
        var valid = Validation.name.test(el.value);
        if (valid) {
          el.setCustomValidity("");
        } else {
          el.setCustomValidity(this.content.error.name);
          if (typeof el.onblur != "function") {
            el.onblur = this.validateName;
          }
        }
      });
    },
    validatePlanNumber: function () {
      $("[validate-plan-number]").each((index, el) => {
        var valid = Validation.planNumber.test(el.value);
        if (valid) {
          el.setCustomValidity("");
        } else {
          el.setCustomValidity(this.content.error.discountNumber);
          if (typeof el.onblur != "function") {
            el.onblur = this.validatePlanNumber;
          }
        }
      });
    },
    validatePostalCode: function () {
      $("[validate-postal]").each((index, el) => {
        var valid = Validation.postal.test(el.value.toUpperCase());
        if (valid) {
          el.setCustomValidity("");
        } else {
          el.setCustomValidity(this.content.error.postalCode);
          if (typeof el.onblur != "function") {
            el.onblur = this.validatePostalCode;
          }
        }
      });
    },
    updateUser: function () {
      this.submitting = true;
      const needCheck = {
        email: this.isEmailChanged,
        aeroplan: this.isAeroplanChanged,
        corporateDiscountNumber: this.isCorporateDiscountChanged,
      };
      const data = {
        userData: { ...this.userFormData },
        needCheck: needCheck,
      };
      data.userData.cellNumber = this.phoneNumberDigits;
      data.userData.marketbyEmail = data.userData.marketbyEmail ? "Y" : "N";
      data.userData.marketbySMS = data.userData.marketbySMS ? "Y" : "N";
      data.userData.resCommbyEmail = data.userData.resCommbyEmail ? "Y" : "N";
      data.userData.resCommbySMS = data.userData.resCommbySMS ? "Y" : "N";
      axios
        .post("/wp-admin/admin-ajax.php?action=pnf_update_user", data, {
          timeout: 30000,
        })
        .then((res) => {
          if (res.data.status === "Error") {
            if (res.data.error.field === "AeroplanNumber") {
              Alert.warn(
                CURRENT_LANGUAGE == "fr"
                  ? res.data.error["messageFrench"]
                  : res.data.error["messageEnglish"],
                5
              );
            } else {
              res.data.error[0]
                ? Alert.warn(res.data.error[0], 5)
                : Alert.warn(this.content.error.generic);
            }
            this.submitting = false;
          } else if (res.data.status === "VALIDATION_ERROR") {
            Alert.warn(
              CURRENT_LANGUAGE == "fr"
                ? res.data.errors.error_message_fr
                : res.data.errors.error_message_en,
              5
            );
            this.submitting = false;
          } else {
            Cookies.set(
              "pnf-user",
              `${data.userData.firstName}+${data.userData.lastName}`
            );
            store.dispatch(
              "setAuthUser",
              `${data.userData.firstName}+${data.userData.lastName}`
            );
            Alert.toast(this.content.response.profileUpdated);
            this.submitting = false;
            this.$router.replace("/members/");
          }
        });
    },
    loadUser: function () {
      if (this.isLoggedIn) {
        clearInterval(this.loadUserLoop);
        GetUser().then((user) => {
          this.user = user;
          this.parseParkerMemberToForm(user);
        });
      }
    },
    parseParkerMemberToForm(user) {
      const userData = {
        rewardId: user.customer.reward_id,
        firstName: user.customer.first_name,
        lastName: user.customer.last_name,
        postalCode: user.customer.postal_code,
        email: user.customer.email_address,
        cellNumber: user.customer.cellphone,
        aeroplanNumber: user.customer.aeroplan_number,
        corporateDiscountNumber:
          user.reward_member.coupon_group_id !== 0
            ? user.reward_member.coupon_group_id
            : "",
        vehicles: [],
        preferredCityId: user.reward_member.preferred_city,
        preferredLanguage: user.customer.language_name,
        marketbyEmail:
          user.reward_member.market_by_email === "Y" ? true : false,
        marketbySMS: user.reward_member.market_by_sms === "Y" ? true : false,
        resCommbyEmail:
          user.reward_member.res_communication_email === "Y" ? true : false,
        resCommbySMS:
          user.reward_member.res_communication_sms === "Y" ? true : false,
      };
      if (user.customer_vehicle && user.customer_vehicle.length > 0) {
        for (let i = 0; i < user.customer_vehicle.length; i++) {
          const userVehicle = user.customer_vehicle[i];
          const vehicle = {
            vehicleId: userVehicle.vehicle_id,
            licenseNumber: userVehicle.license_plate,
            licenseProvinceId: userVehicle.province_id,
            makeId: userVehicle.make_id,
            typeId: userVehicle.body_type_id,
            colorId: userVehicle.colour_id,
            electronicVehicle: userVehicle.electronic_vehicle,
          };
          userData.vehicles.push(vehicle);
        }
      }
      this.userFormData = userData;
    },
    getColourName: function (id) {
      if (id && this.formOptions.color) {
        const color = this.formOptions.color.find(
          (m) => m.vehicle_colour_id === id
        );
        if (color) {
          return color.colour;
        }
      }
    },
    getTypeName: function (id) {
      if (id && this.formOptions.type) {
        const type = this.formOptions.type.find(
          (m) => m.vehicle_type_id === id
        );
        if (type) {
          return type.body_type;
        }
      }
    },
    getMakeName: function (id) {
      if (id && this.formOptions.make) {
        const make = this.formOptions.make.find(
          (m) => m.vehicle_make_id === id
        );
        if (make) {
          return make.make_name;
        }
      }
    },
    getProvinceName: function (id) {
      if (id && this.formOptions.provinces) {
        const province = this.formOptions.provinces.find(
          (m) => m.province_id === id
        );
        if (province) {
          return province.province_name;
        }
      }
    },
  },
  computed: {
    isEmailValid: function () {
      if (this.isEmailChanged) {
        return this.userFormData.email === this.confirmEmail;
      } else {
        return true;
      }
    },
    isEmailChanged: function () {
      if (this.user) {
        return this.user.customer.email_address !== this.userFormData.email;
      }
      return false;
    },
    isCellPhoneChanged: function () {
      if (this.user) {
        return this.user.customer.cellphone !== this.phoneNumberDigits;
      }
      return false;
    },
    isAeroplanChanged: function () {
      const formAero = this.userFormData.aeroplanNumber
        ? this.userFormData.aeroplanNumber.replace(/\s+/g, "")
        : "";
      const userAero = this.user.customer.aeroplan_number
        ? this.user.customer.aeroplan_number.replace(/\s+/g, "")
        : "";
      return formAero !== userAero;
    },
    isCorporateDiscountChanged: function () {
      const formDisc = this.userFormData.corporateDiscountNumber;
      const userDisc = this.user.reward_member.coupon_group_id;
      return formDisc ? formDisc !== userDisc : false;
    },

    isLoggedIn: function () {
      return (
        store.getters.getAuthState && store.getters.getAuthUserRole === "member"
      );
    },
    formOptions: function () {
      return {
        color: this.$store.getters.getVehicleColours,
        type: this.$store.getters.getVehicleTypes,
        make: this.$store.getters.getVehicleMakes,
        provinces: this.$store.getters.getVehicleProvinces,
      };
    },
    isValidPhoneNumber: function () {
      let phone = this.userFormData.cellNumber.replace(/[^0-9]/g, "");
      return phone.length == 10;
    },
    isValidEmailAddress: function () {
      if (this.emailAddress != "") {
        return this.validateEmail();
      } else {
        return true;
      }
    },
    formattedAeroplan: function () {
      if (
        typeof this.user.aeroplanNumber == "string" &&
        !this.user.aeroplanNumber.trim()
      ) {
        return null;
      }
      return `${this.user.aeroplanNumber}`;
    },
    phoneNumberDigits: function () {
      return this.userFormData.cellNumber.replace(/[^0-9]/g, "");
    },
    isValidCommunication: function () {
      return this.userFormData.resCommbyEmail || this.userFormData.resCommbySMS;
    },
  },
  filters: {
    capitalize(text) {
      return text ? text.charAt(0).toUpperCase() + text.slice(1) : " ";
    },
    formatFrenchCharacters: (val) =>
      util.OfferFilters[CURRENT_LANGUAGE].renderHtmlEscapedCharacters(val),
  },
  created() {
    this.loadUserLoop = setInterval(this.loadUser, 1000);
    axios
      .get("/wp-admin/admin-ajax.php?action=pnf_getCities")
      .then((result) => {
        this.cities = result.data.result.cities;
      });
  },
  beforeCreate: function () {
    this.$store = store;
  },
};
</script>
