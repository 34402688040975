<template>
  <section class="account-card" v-if="userLoaded">
    <div class="flip-card">
      <div class="content" :class="{ 'back-show': showBack }" @click="toggle()">
        <div class="front" :class="[cardClass]">
          <div class="logo-container">
            <img
              src="/wp-content/themes/parknfly/resources/assets/images/png-rewards-white.svg"
              class="rewards-logo"
            />
          </div>
          <div class="reward-number">
            {{ user.reward_member.parker_reward_number }}
          </div>
          <div class="bardcode-logo">
            <img
              src="/wp-content/themes/parknfly/resources/assets/images/barcode.svg"
              class="logo"
            />
          </div>
        </div>
        <div class="back" :class="[cardClass]">
          <div class="barcode-container">
            <svg id="barcode"></svg>
          </div>
          <div class="account-name">
            {{ user.customer.first_name }} {{ user.customer.last_name }}
          </div>
          <div class="member-since">{{content.memberSince}} {{ memberSince }}</div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
/*
  global
    CURRENT_LANGUAGE
*/
import moment from "moment";
import Content from "../../../string_resources/member-breadcrumbs.json";
import GetUser from "../../util/users";
import JsBarcode from "jsbarcode";
export default {
  name: "account-card",
  data() {
    return {
      content: Content[CURRENT_LANGUAGE],
      user: null,
      userLoaded: false,
      showBack: false,
    };
  },
  watch: {
    user: function (user) {
      if (user) {
        this.userLoaded = true;
      }
    },
  },
  computed: {
    cardClass: function () {
      if (this.user) {
        let tier = this.user.reward_member.tier_status_id;
        switch (tier) {
          case 1:
            return "green";
          case 2:
            return "gold";
          case 3:
            return "platinum";
        }
      }
      return "";
    },
    memberSince: function () {
      if (this.user) {
        var memberSince = moment(this.user.reward_member.enrollment_date);
        return memberSince.format("MMMM YYYY");
      }
    },
  },
  methods: {
    toggle: function () {
      this.showBack = !this.showBack;
    },
    toggleBack: function () {
      this.showBack = true;
    },
    toggleFront: function () {
      this.showBack = false;
    },
    generateBarcode: function () {
      setTimeout(() => {
        if (this.user) {
          JsBarcode("#barcode", this.user.reward_member.parker_reward_number, {
            format: "itf",
            displayValue: false,
            width: 2.8,
            height: 80,
          });
        }
      }, 1000);
    },
  },
  mounted() {
    GetUser().then((user) => {
      this.user = user;
      this.generateBarcode();
    });
  },
};
</script>
