<template >
  <section>
    <div v-if="userLoaded">
      <div class="my-4">
        <h2>{{ content.sectionLabels.profile }}</h2>
        <p>{{ content.profileIntro }}</p>
      </div>
      <div class="button-container pb-4 mb-4 border-bottom">
        <router-link to="/members/edit" class="btn btn--md mb-4">{{
          content.editProfile
        }}</router-link>
        <router-link to="/members/password" class="ml-sm-2 btn btn--md mb-4">{{
          content.changePassword
        }}</router-link>
      </div>
      <div class="mb-4" v-if="userCustomer">
        <h1 class="smaller">{{ content.sectionLabels.information }}</h1>
        <div class="row my-4">
          <div class="col-sm-4 mb-4">
            <h3>{{ content.fieldLabels.name }}</h3>
            <div>
              {{ userCustomer.first_name }} {{ userCustomer.last_name }}
            </div>
          </div>
          <div class="col-sm-4 mb-4">
            <h3>{{ content.fieldLabels.aeroplanNumber }}</h3>
            <div>{{ userCustomer.aeroplan_number | formatEmpty }}</div>
          </div>
          <div class="col-sm-4 mb-4">
            <h3>{{ content.fieldLabels.location }}</h3>
            <div>{{ getCityName(userRewardMember.preferred_city) }}</div>
          </div>
          <div class="col-sm-4 mb-4">
            <h3>{{ content.fieldLabels.postal }}</h3>
            <div>{{ userCustomer.postal_code }}</div>
          </div>

          <div class="col-sm-4 mb-4">
            <h3>{{ content.fieldLabels.phoneNumber }}</h3>
            <div>{{ userCustomer.cellphone | phone | formatEmpty }}</div>
          </div>
          <div class="col-sm-4 mb-4">
            <h3>{{ content.fieldLabels.corporateDiscountNumber }}</h3>
            <div>
              {{ userRewardMember.coupon_group_id | formatEmpty }}
            </div>
          </div>
          <div class="col-sm-4 mb-4" v-if="userCustomer.preferences">
            <h3>{{ content.fieldLabels.language }}</h3>
            <div>{{ userCustomer.language_name }}</div>
          </div>
          <div class="col-sm-4 mb-4">
            <h3>{{ content.fieldLabels.email }}</h3>
            <div>{{ userCustomer.email_address }}</div>
          </div>
        </div>
        <template
          v-if="
            userCustomerVehicle &&
            userCustomerVehicle.length > 0 &&
            userCustomerVehicle != null
          "
        >
          <div class="row my-4">
            <div class="col-12">
              <div class="border-top"></div>
            </div>
          </div>
          <div class="row my-4">
            <div class="col-12">
              <h2>{{ content.sectionLabels.vehicleInfo }}</h2>
            </div>
          </div>
          <div
            class="row table-header-grey row-6"
            style="margin-left: 0; margin-right: 0"
          >
            <div class="col-2">
              <strong>{{ content.fieldLabels.vehicleMake }}</strong>
            </div>
            <div class="col-2">
              <strong>{{ content.fieldLabels.vehicleType }}</strong>
            </div>
            <div class="col-2">
              <strong>{{ content.fieldLabels.vehicleColor }}</strong>
            </div>
            <div class="col-2">
              <strong>{{ content.fieldLabels.plateNumber }}</strong>
            </div>
            <div class="col-2">
              <strong>{{ content.fieldLabels.province }}</strong>
            </div>
            <div class="col-2">
              <strong>{{ content.fieldLabels.electricVehicleHeader }}</strong>
            </div>
          </div>
          <div
            class="row table-body-grey row-6"
            v-for="vehicle in userCustomerVehicle"
            :key="vehicle.vehicle_id"
            style="margin-left: 0; margin-right: 0"
          >
            <div class="col-2">{{ getMakeName(vehicle.make_id) }}</div>
            <div class="col-2">{{ getTypeName(vehicle.body_type_id) }}</div>
            <div class="col-2">{{ getColourName(vehicle.colour_id) }}</div>
            <div class="col-2">{{ vehicle.license_plate }}</div>
            <div class="col-2">{{ getProvinceName(vehicle.province_id) }}</div>
            <div class="col-2">
              {{
                vehicle.electronic_vehicle === "Y"
                  ? content.fieldLabels.yes
                  : content.fieldLabels.no
              }}
            </div>
          </div>
        </template>
      </div>
    </div>
    <div v-if="!userLoaded">
      <div class="my-2">
        <div class="text-center">
          <img
            src="/wp-content/themes/parknfly/resources/assets/images/loading.gif"
            width="100"
            height="100"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
/*
  global
    CURRENT_LANGUAGE
*/
import GetUser from "../../util/users";
import axios from "axios";
import Content from "../../../string_resources/member-profile.json";
import store from "../../components/store";

export default {
  name: "member-profile",
  data() {
    return {
      content: Content[CURRENT_LANGUAGE],
      userLoaded: false,
      userCustomer: null,
      userCustomerVehicle: null,
      userRewardMember: null,
      firstName: "",
      lastName: "",
      address: {},
      email: "",
      userVehicles: "",
      cellNumber: "",
      aeroplanNumber: 0,
      corporateDiscountNumber: 0,
      preferences: {},
      locations: {},
      loadUserLoop: null,
      cities: null,
    };
  },
  methods: {
    loadUser: function () {
      if (this.isLoggedIn) {
        clearInterval(this.loadUserLoop);
        GetUser().then((user) => {
          this.userCustomer = user.customer;
          this.userCustomerVehicle = user.customer_vehicle;
          if (user.reward_member) {
            this.userRewardMember = user.reward_member;
          } else {
            this.userRewardMember = {};
          }
          this.userLoaded = true;
        });
      }
    },
    getCityName: function (id) {
      if (id) {
        const city = this.cities.find((m) => m.city_id === id);
        if (city) {
          return city.city_name;
        }
      }
    },
    getColourName: function (id) {
      if (id && this.vehicleOptions.color) {
        const color = this.vehicleOptions.color.find(
          (m) => m.vehicle_colour_id === id
        );
        if (color) {
          return color.colour;
        }
      }
    },
    getTypeName: function (id) {
      if (id && this.vehicleOptions.type) {
        const type = this.vehicleOptions.type.find(
          (m) => m.vehicle_type_id === id
        );
        if (type) {
          return type.body_type;
        }
      }
    },
    getMakeName: function (id) {
      if (id && this.vehicleOptions.make) {
        const make = this.vehicleOptions.make.find(
          (m) => m.vehicle_make_id === id
        );
        if (make) {
          return make.make_name;
        }
      }
    },
    getProvinceName: function (id) {
      if (id && this.vehicleOptions.provinces) {
        const province = this.vehicleOptions.provinces.find(
          (m) => m.province_id === id
        );
        if (province) {
          return province.province_name;
        }
      }
    },
  },
  beforeCreate: function () {
    this.$store = store;
  },
  computed: {
    isLoggedIn: function () {
      return (
        store.getters.getAuthState && store.getters.getAuthUserRole === "member"
      );
    },
    vehicleOptions: function () {
      return {
        color: this.$store.getters.getVehicleColours,
        type: this.$store.getters.getVehicleTypes,
        make: this.$store.getters.getVehicleMakes,
        provinces: this.$store.getters.getVehicleProvinces,
      };
    },
  },
  filters: {
    capitalize(text) {
      return text ? text.charAt(0).toUpperCase() + text.slice(1) : " ";
    },
    phone(phone) {
      return phone
        ? phone
            .replace(/[^0-9]/g, "")
            .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
        : "";
    },
    formatLanguage(lng) {
      return lng == "E " ? "English" : "Français";
    },
    formatEmpty(text) {
      //return text && text.trim() ? text : '—';
      return text ? text : "—";
    },
  },
  mounted() {
    this.loadUserLoop = setInterval(this.loadUser, 1000);
    axios
      .post(
        "/wp-admin/admin-ajax.php?action=pnf_get_parking_lots",
        {},
        { timeout: 3000 }
      )
      .then((result) => {
        this.locations = result.data;
      });
    axios
      .get("/wp-admin/admin-ajax.php?action=pnf_getCities")
      .then((result) => {
        this.cities = result.data.result.cities;
      });
  },
};
</script>
