<template>
  <reset-password-form :content="content" :custId="custId" :userType="type"></reset-password-form>
</template>

<script>
/*
  global
    CURRENT_LANGUAGE
*/
import ResetPasswordForm from "../../components/reset-password-form.vue";
import Content from "../../../string_resources/reset-password.json";
import { getUrlParameter } from "../../util/shared";

export default {
  name: "reset-password",
  components: { ResetPasswordForm },
  data() {
    return {
      content: Content[CURRENT_LANGUAGE],
      custId: "",
      type: "",
    };
  },
  created() {
    this.custId = getUrlParameter("key");
    this.type = getUrlParameter("type");
  },
};
</script>
