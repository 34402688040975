<template>
  <section>
    <div v-if="!showSuccess">
      <form
        id="subscribeForm"
        class="mt-5 mb-5"
        @submit="submitForm"
        v-if="!verifyingCode"
      >
        <div class="two-col-form row margin-fix">
          <div class="inner-form col-lg-8 col-12 m-auto">
            <div class="form-row justify-content-between mb-0">
              <div class="form-group required pr-sm-3 pr-0">
                <input
                  class="form-control form-control-lg w-100"
                  v-model="form.firstName"
                  type="text"
                  name="firstName"
                  id="field-firstName"
                  required
                  validate-name
                  :aria-label="content.formLabels.firstName"
                  :placeholder="'*' + content.formLabels.firstName"
                />
              </div>
              <div class="form-group required pl-sm-3 pr-0">
                <input
                  class="form-control form-control-lg w-100"
                  v-model="form.lastName"
                  type="text"
                  name="lastName"
                  id="field-lastName"
                  required
                  validate-name
                  :aria-label="content.formLabels.lastName"
                  :placeholder="'*' + content.formLabels.lastName"
                />
              </div>
            </div>
          </div>
          <div class="inner-form col-lg-8 col-12 m-auto">
            <div class="form-row justify-content-between mb-0">
              <div class="form-group required pr-sm-3 pr-0">
                <input
                  class="form-control form-control-lg w-100"
                  v-model="form.email"
                  type="email"
                  name="email"
                  id="field-email"
                  required
                  validate-email
                  :aria-label="content.formLabels.email"
                  :placeholder="'*' + content.formLabels.email"
                />
              </div>
              <div class="form-group required pl-sm-3 pr-0">
                <input
                  class="form-control form-control-lg w-100"
                  v-model="form.confirmEmail"
                  type="email"
                  name="confirmEmail"
                  id="field-confirm-email"
                  required
                  validate-email
                  :aria-label="content.formLabels.confirmEmail"
                  :placeholder="'*' + content.formLabels.confirmEmail"
                />
              </div>
            </div>
          </div>
          <div class="inner-form col-lg-8 col-12 m-auto text-center">
            <p>{{ content.statusLabelEmail }}</p>
          </div>

          <div
            class="inner-form col-lg-8 col-12 m-auto text-center"
            v-if="formErrors.confirmEmail"
          >
            <p class="text-danger">
              {{ content.formErrors.confirmEmail }}
            </p>
          </div>
          <div
            class="inner-form col-lg-8 col-12 m-auto text-center"
            v-if="formErrors.showGenericError"
          >
            <p class="text-danger">
              {{ content.formErrors.failed }}
            </p>
          </div>
          <div
            class="inner-form col-lg-8 col-12 m-auto text-center"
            v-if="formErrors.emailAlreadyRegistered"
          >
            <p class="text-danger">
              {{ content.formErrors.emailAlreadyRegistered }}
            </p>
          </div>
          <div
            class="inner-form col-lg-8 col-12 m-auto text-center"
            v-if="showSuccess"
          >
            <p class="text-success">
              {{ content.successMessage }}
            </p>
          </div>
          <div class="inner-form col-lg-8 col-12 m-auto text-center">
            <button
              v-show="!loading"
              type="submit"
              class="btn btn-lg text-white btn-lg-text w-50 h-100 p-3 rounded mt-4 text-capitalize"
            >
              {{ content.signUp }}
            </button>
            <div v-show="loading">
              <div class="text-center">
                <img
                  src="/wp-content/themes/parknfly/resources/assets/images/loading.gif"
                  width="50"
                  height="50"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      <form
        id="verifyEmailForm"
        class="mt-5 mb-5"
        @submit="verifyEmail"
        v-if="verifyingCode"
      >
        <div class="row margin-fix">
          <div class="inner-form col-lg-8 col-12 m-auto">
            <p class="text-center">{{ content.instructions }}</p>
            <p class="text-center">
              {{ content.formLabels.email }}:
              <strong> {{ form.email }} </strong>
            </p>

            <div class="form-row justify-content-between mb-0 mt-4">
              <div class="form-group required pr-sm-3 pr-0 w-100">
                <input
                  class="form-control form-control-lg w-100"
                  v-model="form.verificationCode"
                  type="text"
                  name="verificiationCode"
                  id="field-firstName"
                  required
                  :aria-label="content.formLabels.verificationCode"
                  :placeholder="'* ' + content.formLabels.verificationCode"
                />
              </div>
            </div>
          </div>

          <div
            class="inner-form col-lg-8 col-12 m-auto text-center"
            v-if="formErrors.verficationError"
          >
            <p class="text-danger">
              {{ content.formErrors.verficationError }}
            </p>
          </div>
          <div
            class="inner-form col-lg-8 col-12 m-auto text-center"
            v-if="formErrors.maxSix"
          >
            <p class="text-danger">
              {{ content.formErrors.maxSix }}
            </p>
          </div>
          <div
            class="inner-form col-lg-8 col-12 m-auto text-center"
            v-if="formErrors.showGenericError"
          >
            <p class="text-danger">
              {{ content.formErrors.failed }}
            </p>
          </div>
          <div
            class="inner-form col-lg-8 col-12 m-auto text-center"
            v-if="showSuccess"
          >
            <p class="text-success">
              {{ content.successMessage }}
            </p>
          </div>
          <div class="inner-form col-lg-8 col-12 m-auto text-center">
            <p class="text-center mb-0 mt-3" v-show="!loading">
              <a role="button" @click="cancelValidation()">
                {{ content.cancelValidation }}
              </a>
            </p>
            <button
              v-show="!loading"
              type="submit"
              class="btn btn-lg text-white btn-lg-text w-50 h-100 p-3 rounded mt-4 text-capitalize"
            >
              {{ content.verify }}
            </button>
            <div v-show="loading">
              <div class="text-center">
                <img
                  src="/wp-content/themes/parknfly/resources/assets/images/loading.gif"
                  width="50"
                  height="50"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div v-if="showSuccess">
      <p class="text-success text-center mt-5 mb-5">
        {{ content.successMessage }}
      </p>
    </div>
  </section>
</template>

<script>
/*
  global
    CURRENT_LANGUAGE
*/
import axios from "axios";
import { mask } from "vue-the-mask";
import { load } from "recaptcha-v3";
import { RECAPTCHA_API_KEY } from "../main";
import Content from "../../string_resources/reward-subscription.json";
export default {
  name: "member-subscription-form",
  directives: { mask },
  data() {
    return {
      content: Content[CURRENT_LANGUAGE],
      formErrors: {
        confirmEmail: false,
        verficationError: false,
        emailAlreadyRegistered: false,
        showGenericError: false,
        maxSix: false,
      },
      form: {
        firstName: "",
        lastName: "",
        email: "",
        confirmEmail: "",
        verificationCode: "",
        token: "",
        action: "",
        language: CURRENT_LANGUAGE,
      },
      isFormValid: false,
      loading: false,
      showSuccess: false,
      verifyingCode: false,
    };
  },
  watch: {},
  methods: {
    submitForm: function (e) {
      if (this.loading) {
        return;
      }
      this.resetError();
      this.loading = true;
      e.preventDefault();
      this.isFormValid = $("#subscribeForm")[0].reportValidity();
      this.validateEmail();
      if (this.isFormValid) {
        load(RECAPTCHA_API_KEY).then((recaptcha) => {
          recaptcha.execute("registerreward").then((token) => {
            this.form.token = token;
            this.form.action = "registerreward";
            this.handleSubscription();
          });
        });
      } else {
        this.loading = false;
      }
    },
    verifyEmail: function (e) {
      e.preventDefault();
      this.resetError();
      if (this.loading) {
        return;
      }
      if (this.form.verificationCode.length !== 6) {
        this.formErrors.maxSix = true;
        return;
      }

      this.loading = true;
      if (this.form.verificationCode.length > 5) {
        load(RECAPTCHA_API_KEY).then((recaptcha) => {
          recaptcha.execute("validatecode").then((token) => {
            this.form.token = token;
            this.form.action = "validatecode";
            this.handleValidation();
          });
        });
      } else {
        this.loading = false;
      }
    },
    handleSubscription: function () {
      axios
        .post("/wp-admin/admin-ajax.php?action=pnf_registerforreward", {
          email: this.form.email,
          firstname: this.form.firstName,
          lastname: this.form.lastName,
          token: this.form.token,
          action: "registerreward",
          language: CURRENT_LANGUAGE,
        })
        .then(() => {
          this.verifyingCode = true;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          var errorData = error.response.data;
          if (errorData && errorData.data) {
            if (errorData.data.errorCode === "already_registered") {
              this.formErrors.emailAlreadyRegistered = true;
            } else {
              this.formErrors.showGenericError = true;
            }
          } else {
            this.formErrors.showGenericError = true;
          }
        });
    },

    handleValidation: function () {
      axios
        .post("/wp-admin/admin-ajax.php?action=pnf_validateemailcode", {
          email: this.form.email,
          code: this.form.verificationCode,
          token: this.form.token,
          action: "validatecode",
        })
        .then(() => {
          this.loading = false;
          this.showSuccess = true;
        })
        .catch((error) => {
          this.loading = false;
          var errorCode = error.response.data;
          if (errorCode && errorCode.data.errorCode === "wrong_code") {
            this.formErrors.verficationError = true;
          } else {
            this.formErrors.showGenericError = true;
          }
        });
    },
    cancelValidation: function () {
      this.verifyingCode = false;
      this.form.verificationCode = "";
    },
    validateEmail: function () {
      var isValid = this.form.confirmEmail === this.form.email;
      this.formErrors.confirmEmail = !isValid;
      this.isFormValid = this.isFormValid && isValid;
    },
    resetError: function () {
      this.formErrors = {
        confirmEmail: false,
        verficationError: false,
        emailAlreadyRegistered: false,
        showGenericError: false,
      };
    },
    resetForm: function () {
      this.form = {
        firstName: "",
        lastName: "",
        email: "",
        confirmEmail: "",
        verificationCode: "",
        token: "",
        action: "",
        language: CURRENT_LANGUAGE,
      };
      this.isFormValid = false;
      this.loading = false;
    },
  },
  computed: {},
  filters: {},
  created() {},
  mounted() {},
};
</script>
