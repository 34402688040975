<template>
  <forgot-password-form :content="content" :userType="type"></forgot-password-form>
</template>

<script>
/*
  global
    CURRENT_LANGUAGE
*/
import ForgotPasswordForm from "../../components/forgot-password-form.vue";
import Content from "../../../string_resources/forgot-password.json";
import { getUrlParameter } from "../../util/shared";

export default {
  name: "forgot-password",
  components: { ForgotPasswordForm },
  data() {
    return {
      content: Content[CURRENT_LANGUAGE],
      type: "",
    };
  },
  created() {
    this.type = getUrlParameter("type");
  },
};
</script>
