import { router } from '../components/routes';
import VueRouter from 'vue-router';
import store from '../components/store';
import Vue from 'vue';

export default {
  init() {
      //location-details component app
      Vue.use(VueRouter);
      router.replace('/reset-password');

      window.app = new Vue({
        el: "#reset-password",
        store,
        router,
      });

  },
  finalize() {
  },
};
