export function translate(lang, en, fr) {
  return lang == "en" ? en : fr;
}
export function getUrlParameter(name) {
  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  var results = regex.exec(location.search);
  return results === null ? "" : decodeURIComponent(results[1]);
}

function renderHtmlEscapedCharacters(val) {
  const textArea = document.createElement("textarea");
  textArea.innerHTML = val;
  return textArea.value;
}
export function removeNavigationForApp() {
  $("header.banner").remove();
  $("footer.global-footer").remove();
  $("#mobile-en").remove();
  $("#mobile-fr").remove();
}

export function showTierUI(tier) {
  switch (tier) {
    // 1 Green
    case 1:
      break;
    // 2 Gold
    case "2":
      $("body").addClass("tier-gold");
      break;
    case 2:
      $("body").addClass("tier-gold");
      break;
    // 3 Platinum
    case 3:
      $("body").addClass("tier-platinum");
      break;
    case "3":
      $("body").addClass("tier-platinum");
      break;
    default:
      return;
  }
}

export function resetTierUI() {
  $("body").removeClass("tier-gold").removeClass("tier-platinum");
}

export const OfferFilters = {
  en: {
    formatOfferName(val) {
      return val.title.replace(/^[a-zàâçéèêëîïôûùüÿñæœ]+\s/i, "");
    },
    formatOfferId(val) {
      return (
        `pills-${val.title.replace(/(^\w+\s)(\w+)(\s\w\s)?(.+)/, "$2-$3")}-tier`
          .toLowerCase()
          .replace(/\s/g, "")
          .replace(/--/g, "-") + `-${val.location_id}`
      );
    },
    formatOfferType(val) {
      return val.title.replace(/(^\w+\s)(.+)(\sair.+)/i, "$2").toLowerCase();
    },
    formatLocationTitle(val) {
      return val.title;
    },
    renderHtmlEscapedCharacters(val) {
      return renderHtmlEscapedCharacters(val);
    },
  },
  fr: {
    formatOfferName(val) {
      return renderHtmlEscapedCharacters(val.title);
    },
    formatOfferId(val) {
      return `pills-${renderHtmlEscapedCharacters(val.title)
        .replace(/(^.+roport\s)(.+)(\s(d('|’)|de\s).+)/i, "$2")
        .toLowerCase()
        .replace(/\s/, "-")
        .toLowerCase()}-tier-${val.location_id}`;
    },
    formatOfferType(val) {
      return renderHtmlEscapedCharacters(val.title)
        .replace(/(^.+roport\s)(.+)(\s(d('|’)|de\s).+)/i, "$2")
        .toLowerCase();
    },
    formatLocationTitle(val) {
      return renderHtmlEscapedCharacters(val.title);
    },
    renderHtmlEscapedCharacters(val) {
      return renderHtmlEscapedCharacters(val);
    },
  },
};

// Style watcher for dropdown background
export function styleDropdowns() {
  $(function () {
    $("select").on("change", (event) => {
      let dropdown = event.target;
      dropdown.selectedIndex
        ? $(dropdown).addClass("has-value")
        : $(dropdown).removeClass("has-value");
    });
  });
}
