<template>
  <div>
    <div class="form-row">
      <div class="form-group required">
        <input
          class="form-control form-control-lg"
          v-model="password"
          :type="showPassword ? 'text' : 'password'"
          name="password"
          id="field-password"
          required
          validate-password
          @blur="clearValidation"
          :placeholder="'* ' + content.fieldLabels.password"
          :aria-label="'* ' + content.fieldLabels.password"
        />
      </div>
      <div class="form-group required">
        <input
          class="form-control form-control-lg"
          v-model="confirmPassword"
          :type="showPassword ? 'text' : 'password'"
          name="confirm-password"
          id="field-confirm-password"
          required
          @blur="clearValidation"
          :placeholder="'* ' + content.fieldLabels.confirmPassword"
          :aria-label="'* ' + content.fieldLabels.confirmPassword"
        />
      </div>
    </div>
    <div class="form-row">
      <div class="form-group d-flex mt-0">
        <label id="viaEmailComminication" class="d-flex align-items-center">
          <input
            class="big-checkbox --align-top mr-3 d-block"
            type="checkbox"
            v-model="showPassword"
          />

          {{ content.fieldLabels.showPassword }}
        </label>
      </div>
    </div>
  </div>
</template>
<script>
/*
  global
    CURRENT_LANGUAGE
*/
import Content from "../../string_resources/password-fields.json";

export default {
  name: "password-fields",
  data() {
    return {
      content: Content[CURRENT_LANGUAGE],
      passwordErrors: [],
      password: "",
      confirmPassword: "",
      showPassword: false,
    };
  },
  methods: {
    validatePassword: function () {
      $("[validate-password]").each((index, el) => {
        var passwordError = this.checkPassword();
        if (!passwordError) {
          el.setCustomValidity("");
        } else {
          if (passwordError == "no-match") {
            el.setCustomValidity(this.content.error.passwordMatch);
          } else if (passwordError == "bad-format") {
            el.setCustomValidity(this.content.error.passwordRequirements);
          }
        }
      });
    },
    clearValidation: (e) => {
      e.target.setCustomValidity("");
    },
    checkPassword: function () {
      //Must have 1 Upper, 1 Lower, 1 Number (Special Char optional)
      let regex = RegExp(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/g
      );
      let isGoodPassword = regex.test(this.password);
      if (this.password != this.confirmPassword) {
        return "no-match";
      } else if (!isGoodPassword) {
        return "bad-format";
      } else {
        return false;
      }
    },
  },
};
</script>
