/*
  global
  locationsList
*/
import Vue from 'vue';
import VueRouter from 'vue-router';
import { router } from '../components/routes';
import LocationDetails from '../components/location-details.vue';
import store from '../components/store';
import * as Cookies from "js-cookie";
export default {
  init() {
    //Seed Data
    var activeLocation = locationsList.filter(l => l.active)[0];
    //https://github.com/js-cookie/js-cookie
    var cookieOptions = { path: '/', expires: 365 };
    Cookies.set('Location', activeLocation.city, cookieOptions);
    Cookies.set('Language', activeLocation.language, cookieOptions);
    store.dispatch('setLocation', activeLocation);

    $.post("/setup.php", {}, function (data) {
      if (data.result.status == "OK") {

        //activeLocation.timezone
        store.dispatch('setDropOff', data.result.timezones[activeLocation.timezone].drop_off);
        store.dispatch('setPickUp', data.result.timezones[activeLocation.timezone].pick_up);
        Vue.use(VueRouter);
        router.replace('/');
        window.app = new Vue({
          el: "#app",
         // store,
          router,
        });


        //location-details component app
        new Vue({
          components: { LocationDetails },
          el: '#location-details',
          store,
          template: "<location-details></location-details>",
        });
      }
    })
      .fail(function () {

      });
  },
};
