<template>
  <section>
    <div class="mt-5 mb-5" v-if="!newRegistration">
      <p>{{ content.newRegistration }}</p>
    </div>
    <div class="mt-5 mb-5" v-if="newRegistration">
      <p>{{ content.newTransition[0] }}</p>
      <p>{{ content.newTransition[1] }}</p>
      <p>{{ content.newTransition[2] }} <a :href="rewardLink">{{content.newTransition[3]}}</a></p>
    </div>
    <registration-form :isConversion="newRegistration"></registration-form>
  </section>
</template>

<script>
/*
  global
    CURRENT_LANGUAGE
*/
import RegistrationForm from "../components/registration-form.vue";
import Content from "../../string_resources/registration.json";

export default {
  name: "member-register",
  components: { RegistrationForm },
  data() {
    return {
      content: Content[CURRENT_LANGUAGE],
      newRegistration: false,
    };
  },
  computed: {
    rewardLink: function() {
      return CURRENT_LANGUAGE === "en" ? '/rewards-transition/' : '/fr/transition-des-recompenses/'
    }
  },
  created() {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has("status")) {
      this.newRegistration = queryParams.get("status") === "new";
    }
  },
};
</script>
