<template >
<div>
  <member-profile></member-profile>
</div>
</template>

<script>
import MemberProfile from './profile.vue';
import Breadcrumbs from './breadcrumbs.vue';

export default {
  name: 'memberDefault',
  components: {
    MemberProfile,
    Breadcrumbs,
  },
}

</script>
