import Support from '../components/support.vue';
import Vue from 'vue';
import store from '../components/store';
export default {
  init(){
    //location-details component app
    new Vue({
      components:{Support},
      el: '#support',
      store,
      template:"<support></support>",
    });
},
  finalize(){

  },
}
