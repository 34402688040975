<template v-if='visible'>
  <div class="warning-bar" v-bind:class="{animateFade: hide == false}">
    <div class="warning-bar__container">
      <div class="warning-bar__icon">
        <img src="/wp-content/uploads/2019/09/warning-banner-icon-2x.png" alt="!" v-show="alert" />
      </div>
      <div class="warning-bar__close__mobile" @click="closeWarning">
        <span class="icon-close"></span>
      </div>
      <div class="warning-bar__message" v-html="warningMsg"></div>
      <div class="warning-bar__close" @click="closeWarning">
        <span class="icon-close"></span>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
var Warning = {
  name: "Warning",
  data() {
    return {
      visible: false,
      hide: false,
      timer: undefined,
      warningMsg: "",
      duration: 5,
      alert: true,
    };
  },
  methods: {
    showWarning: function() {
      this.visible = true;
      setTimeout(() => {
        this.hide = false;
      }, 500);
      this.timer = setTimeout(() => {
        this.closeWarning();
      }, 1000 * this.duration);
    },
    closeWarning: function() {
      clearTimeout(this.timer);
      this.hide = true;
      setTimeout(() => {
        this.$destroy();
        this.$el.parentNode.removeChild(this.$el);
        this.visible = false;
      }, 1000);
    },
  },
  mounted() {
    this.showWarning();
  },
  warn(msg, duration = 5) {
    this.toast(msg, duration, true);
  },
  message(msg, duration = 5) {
    this.toast(msg, duration, false);
  },
  toast(msg, duration = 5, alert = false) {
    var WarningClass = Vue.extend(Warning);

    if (Array.isArray(msg)) {
      var aggregateMsg = "";
      msg.forEach(function(alert) {
        aggregateMsg += `<div><strong>${alert.title}:</strong> ${alert.content}</div>`;
      });
      msg = aggregateMsg;
    }

    var warning = new WarningClass();
    warning.$data.warningMsg = msg;
    warning.$data.duration = duration;
    warning.$data.alert = alert;
    warning.$mount();
    document.getElementsByTagName("body")[0].appendChild(warning.$el);
  },
};

export default Warning;
</script>
