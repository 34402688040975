<template>
  <div class="bread-crumbs py-2">
    <router-link class="active black-link mr-2" to="/members/">{{
      content.profile
    }}</router-link>
    <router-link class="black-link mr-2" to="/members/reservations">{{
      content.reservations
    }}</router-link>
    <router-link class="black-link mr-2" to="/members/rewards">{{
      content.rewards
    }}</router-link>
  </div>
</template>
<script>
/*
  global
    CURRENT_LANGUAGE
*/
import Content from "../../../string_resources/member-breadcrumbs.json";
export default {
  name: "breadcrumbs",
  data() {
    return {
      content: Content[CURRENT_LANGUAGE],
    };
  },
  methods: {
    getUrlParameter: function (sParam) {
      var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split("&"),
        sParameterName,
        i;

      for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split("=");

        if (sParameterName[0] === sParam) {
          return sParameterName[1] === undefined
            ? true
            : decodeURIComponent(sParameterName[1]);
        }
      }
      return false;
    },
  },
  mounted() {
    var tab = this.getUrlParameter("tab");
    if (tab === "reservation") {
      this.$router.push({ path: "/members/reservations" });
    }
    if (tab === "rewards") {
      this.$router.push({ path: "/members/rewards" });
    }
  },
};
</script>
