import * as Cookies from "js-cookie";
import store from "../components/store";
import {
  router,
} from "../components/routes";
import Vue from "vue";
import MemberWidget from "../components/member-widget.vue";
import Warning from "../components/warning-banner.vue";
import {
  load,
} from "recaptcha-v3";
import {
  RECAPTCHA_API_KEY,
} from "../main";
import {
  getUrlParameter,
  removeNavigationForApp,
  resetTierUI,
  showTierUI,
} from "../util/shared";
export default {
  init() {
    if (window.$ == undefined) {
      window.$ = jQuery;
    }
    var from = getUrlParameter("from");
    if (from === "app") {
      removeNavigationForApp();
      $('body').addClass('from-app')
    }
    var welcomeId = getUrlParameter("welcomeid");
    if (welcomeId) {
      $('#rewardIdShow').html(welcomeId);
    }

    var welcomeUser = getUrlParameter("welcomeuser");
    if (welcomeUser) {
      $('#userShow').html(welcomeUser);
    }
    // Rewards Table Interactive
    var tierSID = getUrlParameter("sid");
    if (tierSID) {
      $('.rewards-table.tier-table.interactive').addClass('highlight-tier highlight-' + tierSID);
      $('.rewards-table.tier-table.interactive').removeClass('interactive');
    }

    $('.rewards-table.interactive thead th').on('mouseenter', function () {
      var index = $(this).index()
      $(`.rewards-table.interactive thead th:nth-child(${index + 1})`).addClass('show-tier-color');
      $(`.rewards-table.interactive tbody td:nth-child(${index + 1})`).addClass('show-tier-color');
    }).on('mouseleave', function () {
      $('.rewards-table.interactive thead th').removeClass('show-tier-color');
      $('.rewards-table.interactive tbody td').removeClass('show-tier-color');
    });

  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    $(".nav-primary__trigger").on("click", function () {
      $(".overlay").addClass("open");
      return false;
    });

    $(".overlay__close-menu").on("click", function () {
      $(".overlay").removeClass("open");
      return false;
    });

    $("#loginModalCenter").on("shown.bs.modal", function () {
      $("#header_login_username").trigger("focus");
    });

    $("#agentLoginModalCenter").on("shown.bs.modal", function () {
      $("#header_agent_login_username").trigger("focus");
    });

    var header_login_form = $("#header_login_form");
    header_login_form.on("submit", function (event) {
      $("#header_login_error").hide();

      event.preventDefault();
      event.stopPropagation();
      load(RECAPTCHA_API_KEY).then((recaptcha) => {
        recaptcha.execute("login").then((token) => {
          authenticate("header", token, "login");
        });
      });
    });

    var header_agent_login_form = $("#header_agent_login_form");
    header_agent_login_form.on("submit", function (event) {
      $("#header_agent_login_error").hide();

      event.preventDefault();
      event.stopPropagation();
      load(RECAPTCHA_API_KEY).then((recaptcha) => {
        recaptcha.execute("login").then((token) => {
          authenticate("header_agent", token, "login");
        });
      });
    });

    window.is_authenticated = function () {
      return (
        Cookies.get("pnf-user") != undefined &&
        Cookies.get("pnf-user-role") != undefined
      );
    };

    function set_authenticated_status() {
      if (window.is_authenticated()) {
        $("#nav_sign_in_btn").hide();

        let user = Cookies.get("pnf-user");
        store.dispatch("setAuthUser", user);
        let tier = Cookies.get("pnf-tier");
        if (user) {
          store.dispatch("setTier", tier);
          showTierUI(tier);
        }
        let role = Cookies.get("pnf-user-role");
        store.dispatch("setAuthUserRole", role);

        if (role == "member") {
          $("body").addClass("logged-in-member");
        }

        initMemberWidget();
        $("#member-widget").first().show();
      } else {
        store.dispatch("setAuthUser", null);
        store.dispatch("setAuthUserRole", null);
        $("#nav_sign_in_btn").show();
        $("#member-widget").first().hide();
        $("body").removeClass("logged-in-member");
      }
    }

    set_authenticated_status();

    function authenticate(prefix, token, action) {
      var post_data = {
        username: $("#" + prefix + "_login_username").val(),
        password: $("#" + prefix + "_login_password").val(),
        token: token,
        action: action,
        _wpnonce: $("#_wpnonce").val(),
        _wp_http_referer: $("#_wp_http_referer").val(),
      };

      let actionName =
        prefix === "header_agent" ?
        "pnf_authenticate_travel_agent" :
        "pnf_authenticate";

      $.ajax({
        type: "POST",
        url: "/wp-admin/admin-ajax.php?action=" + actionName,
        data: JSON.stringify(post_data),
        beforeSend: function () {
          $("#" + prefix + "_spinner_image").show();
          $("#" + prefix + "_login_submit").prop("disabled", true);
        },
        success: function (d) {
          var data = {};
          if (typeof data === 'string') {
            data = JSON.parse(data);
          } else {
            data = d;
          }
          if (data.result.status == "OK") {
            var uri = window.location.toString();

            if (data.registrationStatus === "NewRegistration") {
              handleNewRegistration("rewards-registration");
              return;
            }

            if (data.registrationStatus === "Pending") {
              handleCompleteRegistration(data.result);
              return;
            }

            if (data.result.user.discountNumber != undefined) {
              let coupon = store.getters.getCoupon;
              // Set store value if not already set
              if (coupon == 0) {
                store.dispatch("setCoupon", data.result.user.discountNumber);
              }
              // User could have manually cleared the input box without submitting, so store would be unaffected
              if (!$("#DiscountCode").val()) {
                $("#DiscountCode").val(data.result.user.discountNumber);
              }
            }

            if (typeof window.loginCallback === "string") {
              switch (window.loginCallback) {
                case "agentJustRegistered":
                  return (window.location.href =
                    window.CURRENT_LANGUAGE === "en" ? "/" : "/fr");
                default:
                  break;
              }
            }

            if (uri.indexOf("members") > 0) {
              window.location.href = uri;
              return;
            }

            if (prefix === "header_agent") {
              let lang = window.CURRENT_LANGUAGE === "en" ? "/" : "/fr";
              window.location = `${lang}?referralid=${data.result.user.referralId}&coupon=${data.result.user.coupon_group_id}`;
              return;
            }

            set_authenticated_status();
            $("#loginModalCenter").modal("hide");
            $("#" + prefix + "_spinner_image").hide();
            $("#" + prefix + "_login_submit").prop("disabled", false);
            handleQuoteRedirectOnAuthChange("login");
          } else {
            $("#" + prefix + "_spinner_image").hide();
            $("#" + prefix + "_login_submit").prop("disabled", false);
            $("#" + prefix + "_login_error").show();
          }
        },
        error: function () {
          $("#" + prefix + "_spinner_image").hide();
          $("#" + prefix + "_login_submit").prop("disabled", false);
          $("#" + prefix + "_login_error").show();
        },
      });
    }

    // Bind reset state event to header click
    $("[data-refresh-state]")
      .off("click")
      .on("click", (e) => {
        if (window.app != undefined) {
          e.preventDefault();
          store.dispatch("clearQuotes").then(() => {
            window.location.href = e.currentTarget.href;
          });
        }
      });

    window.logout = function () {
      $.ajax({
        type: "POST",
        url: "/wp-admin/admin-ajax.php?action=pnf_logout",
        beforeSend: function () {
          $("#nav_spinner_image").show();
          $("[href$=userSignOut]").first().prop("disabled", true);
        },
        success: function (data) {

          // const isReferral =
          //   store.state.userRole === "corporate" ||
          //   store.state.userRole === "agent";

          $("#nav_spinner_image").hide();
          sessionStorage.removeItem("discount");
          $("[href$=userSignOut]").prop("disabled", false);
          if (data.result.status == "OK") {
            Cookies.remove("pnf-user");
            Cookies.remove("pnf-user-role");
            Cookies.remove("pnf-tier");
            store.dispatch("setAuthUserRole", null);
            store.dispatch("setTier", null);
            resetTierUI();
            store
              .dispatch("setAuthUser", null)
              .then(() => set_authenticated_status());
            store.dispatch("setRewardsPoints", 0);
            window.MemberWidget.$destroy();
            // if (!isReferral) {
            //   handleQuoteRedirectOnAuthChange("logout");
            // }
          } else {
            $("#member-widget").show();
          }
          window.location.replace("/");
        },
        error: function () {
          $("#nav_spinner_image").hide();
          $("[href$=userSignOut]").first().prop("disabled", false);
          $("#nav_sign_out_error").show();
        },
      });
    };

    // Inner Accordion - see Support page for example
    $(".inner-accordion .inner-accordion-question").click(function () {
      $(this).next().slideToggle;
    });

    // Style watcher for dropdown background
    $("select").on("change", (event) => {
      let dropdown = event.target;
      dropdown.selectedIndex ?
        $(dropdown).addClass("has-value") :
        $(dropdown).removeClass("has-value");
    });

    function watchLanguage() {
      window.addEventListener("load", function () {
        store.dispatch("setLanguage", window.CURRENT_LANGUAGE);
      });
    }
    watchLanguage();

    function initMemberWidget() {
      // Member Widget Component
      window.MemberWidget = new Vue({
        components: {
          MemberWidget,
        },
        el: "#member-widget",
        store,
        template: "<member-widget></member-widget>",
      });
    }

    function handleNewRegistration() {
      var origin = window.location.origin;
      var url = window.CURRENT_LANGUAGE === "en" ? "/rewards-registration?status=new" : "/fr/inscription-aux-recompenses?status=new"
      window.location.href = origin + url;
      return;
    }

    function handleCompleteRegistration(data) {
      var origin = window.location.origin;
      var url =  window.CURRENT_LANGUAGE === "en" ? "/complete-registration" : "/fr/complete-registration";
      var load = {
        rewardNumber: data.user.rewardNumber,
        custId: data.user.custId,
        token: data.user.token,
      };
      var stringify = JSON.stringify(load);
      url += "?from=web&d=" + encodeURIComponent(stringify);
      window.location.href = origin + url;
      return;
    }

    function handleQuoteRedirectOnAuthChange(type) {
      const permissionedRoutes = {
        guest: new RegExp(
          /(rewards-registration)|(forgot-password)|(reset-password)|(reinitialiser-le-mot-de-passe)|(inscription-aux-recompenses)|(mot-de-passe-oublie)/
        ),
        member: new RegExp(/(member|membres)/),
      };
      if (store.getters.getQuoteCount !== 0) {
        store.dispatch("clearQuotes").then(() => {
          store.dispatch("setQuotes").then(
            (response) => {
              if (response.data.result.status == "OK") {
                router.replace("/quote");
              } else {
                if (
                  response.data.result.errors &&
                  response.data.result.errors.length > 0
                ) {
                  Warning.warn(response.data.result.errors.join("<br/>"));
                }
              }
            },
            (error) => {
              Warning.warn(error);
            }
          );
        });
      }
      // Check if user type is allowed on current page; redirect home otherwise
      if (
        (type === "login" &&
          permissionedRoutes.guest.test(window.location.pathname)) ||
        (type === "logout" &&
          permissionedRoutes.member.test(window.location.pathname))
      ) {
        window.location.pathname = "/";
      }
    }
  },
};
