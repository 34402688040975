import Vue from "vue";

const patterns = {
  email: RegExp(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i),
  emailOptional: RegExp(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i),
  password: RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/g),
  phoneNumber: RegExp(/^\([2-9]\d{2}\)\s[2-9]\d{2}-\d{4}$/),
  name: RegExp(/^[ÀÁÂÃÄÅÆçÈÉÊËÌÍÎÏñÒÓÔÕÖÙÚÛÜÝŸàáâãäåæçèéêëìíîïñòóôõöùúûüýÿA-Z \-']{0,50}$/i),
  planNumber: RegExp(/^\d{0,14}$/),
  aeroplanNumber: RegExp(/^\d{9}$/),
  preferredCity: RegExp(/^\d+$/),
  aeroplanNumberOptional: RegExp(/^$|^(\d{9})$/),
  postal: RegExp(/([ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z] ?[0-9][ABCEGHJ-NPRSTV-Z][0-9])|^\d{5}(-\d{4})?$/i),
  postalOptional: RegExp(/^$|([ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z]\s?[0-9][ABCEGHJ-NPRSTV-Z][0-9])|^\d{5}$/i),
}

export const Validator = Vue.directive("validate", (el, binding) => {
  binding.value.condition = typeof binding.value.condition === "undefined" ? true : binding.value.condition;
  const isValid = binding.value.pattern ? patterns[binding.value.pattern].test(el.value) : true;

  if( el.value === "" && binding.value.optional) {
    return el.setCustomValidity('');
  }
  else if (!isValid) {
    return el.setCustomValidity(binding.value.msg);
  }
  else {
    return el.setCustomValidity('')
  }
})

export default patterns;
