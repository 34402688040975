<template>
  <section>
    <div v-if="quote" v-show="canRedeemDays" class="reward-bar border">
      <div class="row align-items-center reward-bar__inner">
        <div
          v-show="!allowUndo"
          class="col-xl-7 col-12 reward-bar__desc-container"
        >
          <p
            v-html="content.redeemRewardsLabel"
            class="field__label reward-bar__desc-intro"
          ></p>
          <p class="reward-bar__desc">
            {{ content.pointsRedeemable[0] }} {{ quote.availablePoints }}
            {{ content.pointsRedeemable[1] }} {{ freeDaysAvailable }}
            {{ content.pointsRedeemable[2] | pluralize(freeDaysAvailable) }}
          </p>
          <p class="reward-bar__desc text-center" v-show="allowUndo">
            {{ content.pointsRedeemed[0] }} {{ freeDaysUsed }}
            {{ content.pointsRedeemed[1] | pluralize(freeDaysUsed) }}
            {{ content.pointsRedeemed[2] }}
          </p>
        </div>
        <div
          v-show="allowUndo"
          class="col-xl-10 col-12 reward-bar__desc-container"
        >
          <p class="reward-bar__desc text-center mt-1" v-show="allowUndo">
            {{ content.pointsRedeemed[0] }} {{ freeDaysUsed }}
            {{ content.pointsRedeemed[1] | pluralize(freeDaysUsed) }}
            {{ content.pointsRedeemed[2] }}
          </p>
        </div>
        <div
          v-show="!allowUndo"
          class="col-xl-3 col-md-6 col-12 text-center reward-bar__point-container"
        >
          <div class="reward-bar__point-toggle">
            <button
              class="blue-circle-btn dec-btn"
              v-show="!applyingRewards"
              v-bind:class="{
                disabled: freeDaysUsed > freeDaysAvailable || freeDaysUsed <= 0,
              }"
              @click="handlePointChange(-1)"
            >
              -
            </button>
            <span>{{ freeDaysUsed }}</span>
            <span class="free-days-subtext">{{
              content.freeDays | pluralize(freeDaysUsed)
            }}</span>
            <button
              v-show="!applyingRewards"
              class="blue-circle-btn inc-btn"
              v-bind:class="{ disabled: freeDaysUsed >= freeDaysAvailable }"
              @click="handlePointChange(1)"
            >
              +
            </button>
          </div>
        </div>
        <div
          class="col-xl-2 col-md-6 col-12 btn--full-col"
          v-show="!allowUndo && !applyingRewards"
          @click="alterQuote('freeDays')"
        >
          <button class="btn btn--big">{{ content.apply }}</button>
        </div>
        <div
          class="col-xl-2 col-12 btn--full-col"
          v-show="allowUndo && !applyingRewards"
          @click="alterQuote('undoFreeDays')"
        >
          <button class="btn btn--big">{{ content.undo }}</button>
        </div>
        <div class="col-xl-2 col-12" v-show="applyingRewards">
          <div class="text-center">
            <img
              src="/wp-content/themes/parknfly/resources/assets/images/loading.gif"
              width="50"
              height="50"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-show="loading" class="text-center mt-5">
      <h3 class="mb-3">{{ content.loading }}</h3>
      <img
        src="/wp-content/themes/parknfly/resources/assets/images/loading.gif"
        width="100"
        height="100"
      />
    </div>
    <div
      v-if="quote"
      class="row margin-fix"
      v-bind:class="{ 'mt-5': canRedeemDays == false }"
    >
      <div class="col-xl-8 col-12 form-container mb-4">
        <div
          class="form-header green-underline margin-fix row justify-content-between align-items-center"
        >
          <div class="col-xl-8 col-lg-12 padding-fix">
            <h1 class>
              {{ quote.locationName | formatLocationName }} {{ content.offer }}
            </h1>
          </div>
          <div class="col-xl-3 col-lg-12 padding-fix">
            <router-link to="/quote" class="blue-link mb-2">{{
              content.changeSelection
            }}</router-link>
          </div>
        </div>
        <div class="row inner-form">
          <div class="col-md-6 rewards__left-details-container">
            <h3 class="mb-3">{{ content.parkingDetailsLabel }}</h3>
            <div class="row h-100">
              <div class="col-6">
                <p class="field__label">{{ content.dropOffLabel }}</p>
                <p
                  class="booking__label d-none d-sm-block d-md-none d-xxl-block"
                >
                  {{ dropOff.format("MMMM D, YYYY") }}
                </p>
                <p
                  class="booking__label d-block d-sm-none d-md-block d-xxl-none"
                >
                  {{ dropOff.format("MM/D/YY") }}
                </p>
                <p class="field__lower-text">{{ dropOff.format("h:mm A") }}</p>
              </div>
              <div class="col-6">
                <p class="field__label">{{ content.pickUpLabel }}</p>
                <p
                  class="booking__label d-none d-sm-block d-md-none d-xxl-block"
                >
                  {{ pickUp.format("MMMM D, YYYY") }}
                </p>
                <p
                  class="booking__label d-block d-sm-none d-md-block d-xxl-none"
                >
                  {{ pickUp.format("MM/D/YY") }}
                </p>
                <p class="field__lower-text">{{ pickUp.format("h:mm A") }}</p>
              </div>
              <div class="col-12">
                <p class="field__label">{{ content.parkingOptionLabel }}</p>
                <p class="booking__label">
                  {{ selectedParkingLot | formatLocationTitle }}
                </p>
                <p class="mb-0 field__label font-weight-normal">
                  {{ selectedParkingLot.address }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 pl-3 rewards__right-details-container">
            <h3 class="mb-3">{{ content.estimatedCostLabel }}</h3>
            <div
              v-for="(quoteLine, index) in nonTaxQuoteLines"
              v-bind:key="'nonTaxQuoteLine' + index"
              class="row mb-1"
            >
              <template v-if="quoteLine.revenueType === 'P'">
                <div class="col-8">
                  <p>
                    <strong
                      >{{ quoteLine.quantity }}-{{
                        translate(
                          lang,
                          quoteLine.descEnglish,
                          quoteLine.descFrench
                        )
                      }}</strong
                    >
                  </p>
                </div>
                <div class="col-4">
                  <p>
                    <strong>{{
                      parseAndSum([quoteLine.charge]) | formatCurrency
                    }}</strong>
                  </p>
                </div>
              </template>

              <template v-else-if="quoteLine.revenueType === 'D'">
                <div class="col-8 text-red">
                  <p>
                    {{
                      translate(
                        lang,
                        quoteLine.descEnglish,
                        quoteLine.descFrench
                      )
                    }}
                  </p>
                </div>
                <div class="col-4 text-red">
                  <p>
                    {{
                      parseAndSum([quoteLine.charge])
                        | formatCurrency
                        | dollarSignOnDiscount
                    }}
                  </p>
                </div>
              </template>

              <template v-else-if="quoteLine.revenueType === 'S'">
                <div class="col-8">
                  <p class="green-underline-link">
                    {{
                      translate(
                        lang,
                        quoteLine.descEnglish,
                        quoteLine.descFrench
                      )
                    }}
                    <template v-if="/fuel/gi.test(quoteLine.descEnglish)">
                      <a
                        class="ml-3 tooltip-marker"
                        data-toggle="tooltip"
                        data-placement="auto"
                        :title="content.tooltips.fuelSurcharge"
                        ><span class="icon-pnf-support-questions"></span
                      ></a>
                    </template>
                    <template v-else>
                      <a
                        class="ml-3 tooltip-marker"
                        data-toggle="tooltip"
                        data-placement="auto"
                        :title="content.tooltips.additionalServiceLineItem"
                        ><span class="icon-pnf-support-questions"></span
                      ></a>
                    </template>
                  </p>
                </div>
                <div class="col-4">
                  <p>{{ parseAndSum([quoteLine.charge]) | formatCurrency }}</p>
                </div>
              </template>

              <template v-else>
                <div class="col-8">
                  <p>
                    {{ quoteLine.quantity }}
                    {{
                      translate(
                        lang,
                        quoteLine.descEnglish,
                        quoteLine.descFrench
                      )
                    }}
                  </p>
                </div>
                <div class="col-4">
                  <p>
                    ({{ parseAndSum([quoteLine.charge]) | formatCurrency }})
                  </p>
                </div>
              </template>
            </div>
            <div class="row mb-1">
              <template v-if="checkAdditionalServices.needsSUVSurcharge">
                <div class="col-8">
                  <p class="green-underline-link">
                    {{ content.suvSurchargeLineLabel }}
                    <a
                      class="ml-3 tooltip-marker"
                      data-toggle="tooltip"
                      data-placement="auto"
                      :title="content.tooltips.suvSurchargeLineItem"
                      ><span class="icon-pnf-support-questions"></span
                    ></a>
                  </p>
                </div>
                <div class="col-4">
                  <p>
                    {{ parseAndSum([service.surcharge.rate]) | formatCurrency }}
                  </p>
                </div>
              </template>

              <div class="col-8">
                <p>{{ content.subTotalLineLabel }}</p>
              </div>
              <div class="col-4">
                <p>
                  {{
                    parseAndSum([
                      quote.totalBeforeTax,
                      checkAdditionalServices.vehicleSurcharge.rate,
                    ]) | formatCurrency
                  }}
                </p>
              </div>
            </div>

            <div
              v-for="(quoteLine, index) in taxQuoteLines"
              v-bind:key="'taxQuoteLine' + index"
              class="row mb-1"
            >
              <template
                v-if="RegExp(/(parking\stax)/i).test(quoteLine.descEnglish)"
              >
                <div class="col-8">
                  <p>
                    {{ quoteLine.quantity }}
                    {{
                      translate(
                        lang,
                        quoteLine.descEnglish,
                        quoteLine.descFrench
                      )
                    }}
                    <a
                      class="ml-3 tooltip-marker"
                      data-toggle="tooltip"
                      data-placement="auto"
                      :title="content.tooltips.parkingTax"
                      ><span class="icon-pnf-support-questions"></span
                    ></a>
                  </p>
                </div>
                <div class="col-4">
                  <p>{{ parseAndSum([quoteLine.charge]) | formatCurrency }}</p>
                </div>
              </template>
              <template v-else>
                <div class="col-8">
                  <p>
                    {{
                      translate(
                        lang,
                        quoteLine.descEnglish,
                        quoteLine.descFrench
                      )
                    }}
                  </p>
                </div>
                <div class="col-4">
                  <p>
                    {{
                      parseAndSum([
                        quoteLine.charge,
                        checkAdditionalServices.vehicleSurcharge.tax,
                      ]) | formatCurrency
                    }}
                  </p>
                </div>
              </template>
            </div>

            <div class="row">
              <div class="col-8">
                <p>
                  <strong>{{ content.estimatedChargesLineLabel }}</strong>
                </p>
              </div>
              <div class="col-4">
                <p>
                  <strong>{{
                    parseAndSum([
                      quote.quoteTotal,
                      checkAdditionalServices.vehicleSurcharge.total,
                    ]) | formatCurrency
                  }}</strong>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <p class="text-red mb-0">{{ content.rateWarning }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <hr class="mb-0" />
          </div>
        </div>
        <form id="your-information" class="row inner-form pt-1 pb-3">
          <div class="col-sm-12">
            <h3 class="mt-3 mb-3 d-inline-block" v-if="!userIsTravelAgent">
              {{ content.yourInformationLabel }}
            </h3>
            <a
              class="ml-3 d-inline-block tooltip-marker vertical-middle"
              data-toggle="tooltip"
              data-placement="auto"
              :title="content.tooltips.contactUsage"
              style="margin-top: -0.5rem"
              v-if="!userIsTravelAgent"
              ><span class="icon-pnf-support-questions"></span
            ></a>

            <h3 class="mt-3 mb-3 d-inline-block" v-if="userIsTravelAgent">
              {{ content.clientInformation }}
            </h3>
            <a
              class="ml-3 d-inline-block tooltip-marker vertical-middle"
              data-toggle="tooltip"
              data-placement="auto"
              :title="content.tooltips.clientInformationDescription"
              style="margin-top: -0.5rem"
              v-if="userIsTravelAgent"
              ><span class="icon-pnf-support-questions"></span
            ></a>
            <template v-if="!isLoggedIn">
              <p class="mb-3" v-if="!userIsTravelAgent">
                {{ content.loginReminder[0] }}
                <a
                  href="#OpenMenu"
                  :title="content.tooltips.login"
                  data-toggle="modal"
                  data-target="#loginModalCenter"
                  >{{ content.loginReminder[1] }}</a
                >
                {{ content.loginReminder[2] }}
              </p>
               <p class="mb-3" v-if="userIsTravelAgent">
                {{ content.travelAgentClientReminder }}
              </p>
              <p class="mb-4">
                {{ content.mandatoryFields }}
              </p>
              <div class="form-row justify-content-between">
                <div class="form-group required col-12 col-md-6 mt-3 mb-3">
                  <input
                    class="form-control form-control-lg"
                    type="text"
                    id="firstName"
                    v-model="firstName"
                    required
                    validate-name
                    :aria-label="content.fieldLabels.firstName"
                    :placeholder="'* ' + content.fieldLabels.firstName"
                  />
                </div>
                <div class="form-group required col-12 col-md-6 mt-3 mb-3">
                  <input
                    class="form-control form-control-lg"
                    type="text"
                    id="lastName"
                    required
                    v-model="lastName"
                    validate-name
                    :aria-label="content.fieldLabels.lastName"
                    :placeholder="'* ' + content.fieldLabels.lastName"
                  />
                </div>

                <div class="form-group col-12 col-md-6 mt-4 mb-3">
                  <input
                    class="form-control form-control-lg"
                    type="text"
                    id="postal-code"
                    v-model="postal"
                    validate-postal
                    :aria-label="content.fieldLabels.postal"
                    :placeholder="content.fieldLabels.postal"
                  />
                </div>
                <div
                  class="form-group col-12 col-md-6 mt-4 mb-4"
                  v-if="activeLocation.aeroplan_offered"
                >
                  <input
                    class="form-control form-control-lg"
                    v-model="aeroplanNumber"
                    type="text"
                    name="aeroplanNumber"
                    id="field-aeroplan-number"
                    data-server-name="Aeroplan"
                    validate-aeroplan
                    :placeholder="content.fieldLabels.aeroplanNumber"
                    :aria-label="content.fieldLabels.aeroplanNumber"
                    :disabled="waitingForAeroplanValidation"
                  />
                  <small
                    class="d-block ml-2 mt-2"
                    v-if="waitingForAeroplanValidation"
                  >
                    {{ content.fieldLabels.validationLoading }}
                  </small>
                  <small class="d-block ml-2 mt-2" v-if="aeroplanInvalid">
                    {{ content.errorMsg.discountNumber }}
                  </small>
                </div>
              </div>
              <div class="row" v-if="!isLoggedIn && !userIsTravelAgent">
                <div class="col-sm-12">
                  <hr class="mb-0" />
                </div>
              </div>
              <div class="my-4" v-if="!isLoggedIn && !userIsTravelAgent">
                <h3>
                  {{ content.fieldLabels.phoneNumber }}
                </h3>
                <span class="verification-hide">{{
                  content.phoneValidationWarning
                }}</span>
              </div>
              <p
                v-if="userIsTravelAgent"
                style="font-weight: bold; margin-top: 1em"
              >
                {{ content.enterEmailTravel }}
              </p>
              <div
                class="form-row justify-content-between mb-2"
                v-if="!isLoggedIn"
              >
                <div class="form-group col-12 col-md-6 mb-3">
                  <input
                    class="form-control form-control-lg"
                    type="text"
                    maxlength="100"
                    id="email-address"
                    v-model="emailAddress"
                    validate-emailoptional
                    :aria-label="content.fieldLabels.email"
                    :placeholder="content.fieldLabels.email"
                  />
                  <!-- v-validate="{pattern:'emailOptional', optional: true, msg: content.errorMsg.email}"  -->
                </div>
                <div class="form-group col-12 col-md-6 mb-3">
                  <input
                    class="form-control form-control-lg"
                    type="text"
                    maxlength="100"
                    id="confirm-address"
                    v-model="confirmEmailAddress"
                    validate-emailoptional
                    :aria-label="content.fieldLabels.confirmEmail"
                    :placeholder="content.fieldLabels.confirmEmail"
                  />
                  <!-- v-validate="{pattern:'emailOptional', optional: true, msg: content.errorMsg.email}"  -->
                </div>
                <div
                  class="col-12 mb-3"
                  v-if="
                    (emailAddress.length > 0 ||
                      confirmEmailAddress.length > 0) &&
                    emailAddress !== confirmEmailAddress &&
                    !isLoggedIn
                  "
                >
                  <p class="text-red">
                    {{ content.errorMsg.confirmEmail }}
                  </p>
                </div>
                <div
                  class="form-group col-12 col-md-6 verification-hide"
                  v-if="!userIsTravelAgent"
                >
                  <input
                    class="form-control form-control-lg"
                    id="phone-number"
                    type="tel"
                    v-mask="'(###) ###-####'"
                    v-model="phoneNumber"
                    :aria-label="content.fieldLabels.phoneNumberPH"
                    :placeholder="`* ${content.fieldLabels.phoneNumberPH}`"
                  />
                </div>
              </div>
              <div
                class="form-row justify-content-between px-1"
                v-show="
                  phoneNumberDigits.length == 10 &&
                  !isLoggedIn &&
                  !userIsTravelAgent
                "
              >
                <input type="hidden" id="verified-email-hash" />
                <validation-code
                  emailAddressId="email-address"
                  verifiedHashId="verified-email-hash"
                  phoneNumberId="phone-number"
                  contactType="S"
                  v-bind:checkPhoneInUse="false"
                  phoneOnlyVersion
                  @contactTypeChange="(type) => (communicationMethod = type)"
                  v-on:hash-received="storeHash"
                  v-if="!isLoggedIn && !userIsTravelAgent"
                />
              </div>
              <div class="form-row" v-if="!userIsTravelAgent">
                <div class="col-11">
                  <div id="validation-code"></div>
                </div>
              </div>
            </template>
            <template v-if="isLoggedIn && !userCustomer">
              <div class="text-center mt-5">
                <img
                  src="/wp-content/themes/parknfly/resources/assets/images/loading.gif"
                  width="100"
                  height="100"
                />
              </div>
            </template>
            <template v-if="isLoggedIn && userCustomer">
              <p class="mb-4" v-html="content.isMemberInfoCorrect"></p>
              <p>
                <strong>{{ content.fieldLabels.name }}:</strong>
                <span v-if="userCustomer">
                  {{ userCustomer.first_name }}
                  {{ userCustomer.last_name }}</span
                >
              </p>
              <p>
                <strong>{{ content.fieldLabels.phone }}:</strong>
                <span v-if="userCustomer"> {{ userCustomer.cellphone }}</span>
              </p>
              <p>
                <strong>{{ content.fieldLabels.email }}:</strong>
                <span v-if="userCustomer">
                  {{ userCustomer.email_address }}</span
                >
              </p>
            </template>
          </div>
        </form>
        <div class="row">
          <div class="col-sm-12">
            <hr class="mb-0" />
          </div>
        </div>
        <template v-if="valetServices">
          <div id="valet-services" class="collapse show bg-light pt-3">
            <div
              class="row inner-form justify-content-between align-items-center"
            >
              <div class="col-sm-4">
                <img
                  class="img-fluid"
                  src="/wp-content/themes/parknfly/resources/assets/images/additional-services.jpg"
                  alt="Hand Wash"
                />
              </div>
              <div class="col-sm-8">
                <h3 class="mt-1 mb-3">{{ content.valetServicesLabel }}</h3>
                <p class="mt-1 mb-3">{{ content.valetServicesIntro }}</p>
                <div
                  id="collapse-valet-services-link"
                  style="display: none"
                  href="#valet-services-table"
                  data-toggle="collapse"
                >
                  <span
                    class="icon-up-chevron blue-link"
                    style="font-size: 0.75rem"
                  ></span>
                  <a class="blue-link inherit-font">{{
                    content.collapseValetServices
                  }}</a>
                  <a
                    class="ml-3 tooltip-marker"
                    data-toggle="tooltip"
                    data-placement="auto"
                    :title="content.tooltips.collapseServices"
                    ><span class="icon-pnf-support-questions"></span
                  ></a>
                </div>

                <div
                  id="expand-valet-services-link"
                  href="#valet-services-table"
                  data-toggle="collapse"
                >
                  <span
                    class="icon-down-chevron no-underline blue-link"
                    style="font-size: 0.75rem"
                  ></span>
                  <a class="blue-link inherit-font">{{
                    content.expandValetServices
                  }}</a>
                  <a
                    class="ml-3 tooltip-marker"
                    data-toggle="tooltip"
                    data-placement="auto"
                    :title="content.tooltips.additionalServices"
                    ><span class="icon-pnf-support-questions"></span
                  ></a>
                </div>
                <div
                  class="mt-2 mb-2 text-red"
                  id="reserveDetailingUnavailable"
                  style="display: none"
                >
                  {{ content.valetTable.noDetailingService }}
                </div>
              </div>
            </div>
            <additional-services
              id="valet-services-table"
              class="row inner-form collapse collapsed"
              @serviceChanged="updateServices"
            >
            </additional-services>
            <div class="row mt-2">
              <div class="col-sm-12">
                <hr class="mb-0" />
              </div>
            </div>
          </div>
        </template>
        <div
          id="expand-trip-details"
          class="row my-3 justify-content-center collapse"
        >
          <div class="col-sm-12 mb-4 text-center">
            <a
              href
              data-toggle="collapse"
              data-target="#trip-details"
              class="blue-link no-underline"
            >
              <span class="icon-down-chevron"></span>
              <a class="blue-link" href="#trip-details">{{
                content.expandFlightDetails
              }}</a>
            </a>
          </div>
        </div>
        <form id="vehicle-details" class="collapse show" @input="validateForm">
          <div class="row inner-form py-0">
            <div class="col-sm-12 py-4">
              <h3 class="mt-4 mb-2">{{ content.vehicleDetailsLabel }}</h3>
              <div
                v-if="
                  userCustomerVehicles &&
                  userCustomerVehicles.length > 0 &&
                  userCustomerVehicles[0] != null
                "
                class="form-row"
              >
                <p class="ml-1 mb-4">
                  {{ content.userVehicleSelectionIntro[0] }}
                  <a
                    class="blue-link inherit-font"
                    :href="content.profileEditVehiclesLink"
                    >{{ content.userVehicleSelectionIntro[1] }}</a
                  >
                  {{ content.userVehicleSelectionIntro[2] }}
                </p>
                <template v-for="(vehicle, index) in userCustomerVehicles">
                  <div
                    class="form-group mb-3 col-md-6 col-12"
                    :key="vehicle.vehicle_id"
                  >
                    <input
                      type="radio"
                      name="selectedVehicle"
                      v-model="selectedVehicle"
                      :id="'vehicle' + index"
                      :value="vehicle.vehicle_id"
                    />
                    <label :for="'vehicle' + index">
                      <strong>{{ getMakeName(vehicle.make_id) }}</strong>
                      {{ content.fieldLabels.licenseNumber }}
                      <strong>{{ vehicle.license_plate }}</strong>
                    </label>
                  </div>
                </template>
                <div class="form-group col-12">
                  <input
                    type="radio"
                    name="selectedVehicle"
                    id="selectedVehicleNS"
                    value
                  />
                  <label for="selectedVehicleNS">{{
                    content.fieldLabels.notSure
                  }}</label>
                </div>
              </div>
              <div
                v-if="
                  !userCustomerVehicles ||
                  userCustomerVehicles.length < 1 ||
                  userCustomerVehicles[0] == null
                "
              >
                <p class="mb-4">{{ content.vehicleDetailsExplanation }}</p>
                <div class="form-row justify-content-between my-4">
                  <div class="form-group col-12 col-md-6 mt-3 mb-3">
                    <div class="select-wrapper">
                      <select
                        class="form-control form-control-lg"
                        id="vehcile-make"
                        v-model="vehicle.make"
                        :required="vehicleInfoHasBeenAdded"
                        :aria-label="content.fieldLabels.vehicleMake"
                      >
                        <option disabled selected value>
                          {{
                            `${vehicleInfoHasBeenAdded ? "* " : ""}${
                              content.fieldLabels.vehicleMake
                            }`
                          }}
                        </option>
                        <template v-for="make in formOptions.make">
                          <option
                            v-bind:key="'make' + make.vehicle_make_id"
                            v-bind:value="make.vehicle_make_id"
                          >
                            {{ make.make_name | capitalize }}
                          </option>
                        </template>
                      </select>
                    </div>
                  </div>
                  <div class="form-group col-12 col-md-6 mt-3 mb-3">
                    <div class="select-wrapper">
                      <select
                        class="form-control form-control-lg"
                        id="vehicle-type"
                        v-model="vehicle.type"
                        :required="vehicleInfoHasBeenAdded"
                        :aria-label="content.fieldLabels.vehicleType"
                      >
                        <option selected disabled value>
                          {{
                            `${vehicleInfoHasBeenAdded ? "* " : ""}${
                              content.fieldLabels.vehicleType
                            }`
                          }}
                        </option>
                        <template v-for="type in formOptions.type">
                          <option
                            v-bind:key="'type' + type.vehicle_type_id"
                            v-bind:value="type.vehicle_type_id"
                          >
                            {{ type.body_type | capitalize }}
                          </option>
                        </template>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="form-row justify-content-between my-4">
                  <div class="form-group col-12 col-md-6 mt-3 mb-3">
                    <div class="select-wrapper">
                      <select
                        class="form-control form-control-lg"
                        id="vehicle-color"
                        v-model="vehicle.color"
                        :required="vehicleInfoHasBeenAdded"
                        :aria-label="content.fieldLabels.vehicleColor"
                      >
                        <option disabled selected value>
                          {{
                            `${vehicleInfoHasBeenAdded ? "* " : ""}${
                              content.fieldLabels.vehicleColor
                            }`
                          }}
                        </option>
                        <template v-for="color in formOptions.color">
                          <option
                            v-bind:key="'color' + color.vehicle_colour_id"
                            v-bind:value="color.vehicle_colour_id"
                          >
                            {{ color.colour | capitalize }}
                          </option>
                        </template>
                      </select>
                    </div>
                  </div>
                  <div class="form-group col-12 col-md-6 mt-3 mb-3">
                    <input
                      class="form-control form-control-lg"
                      type="text"
                      id="license-plate"
                      v-model="vehicle.plate"
                      maxlength="10"
                      :required="vehicleInfoHasBeenAdded"
                      :aria-label="content.fieldLabels.plateNumber"
                      :placeholder="`${vehicleInfoHasBeenAdded ? '* ' : ''}${
                        content.fieldLabels.plateNumber
                      }`"
                    />
                  </div>
                </div>
                <div class="form-row justify-content-between my-4">
                  <div class="form-group col-12 col-md-6 mt-3 mb-3">
                    <div class="select-wrapper">
                      <select
                        class="form-control form-control-lg"
                        id="vehicle-province"
                        v-model="vehicle.province"
                        :required="vehicleInfoHasBeenAdded"
                        :aria-label="content.fieldLabels.province"
                      >
                        <option disabled selected value>
                          {{
                            `${vehicleInfoHasBeenAdded ? "* " : ""}${
                              content.fieldLabels.province
                            }`
                          }}
                        </option>
                        <template v-for="province in formOptions.provinces">
                          <option
                            v-bind:key="province.province_id"
                            v-bind:value="province.province_id"
                          >
                            {{ province.province_name | capitalize }}
                          </option>
                        </template>
                      </select>
                    </div>
                  </div>
                  <div
                    class="form-group col-12 col-md-6 mt-2 mb-3"
                  >
                    <div class="inline-block">
                      <span class="d-inline-block">{{
                        `${vehicleInfoHasBeenAdded ? "* " : ""}${
                          content.fieldLabels.electricVehicle
                        }`
                      }}</span>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="vehicle-electric"
                          id="vehicle-electric"
                          :value="true"
                          v-model="vehicle.isEV"
                          :aria-label="content.fieldLabels.yes"
                          :required="vehicleInfoHasBeenAdded"
                        />
                        <label
                          class="form-check-label text-medium"
                          for="vehicle-electric"
                          >{{ content.fieldLabels.yes }}</label
                        >
                        <input
                          class="form-check-input ml-4"
                          type="radio"
                          name="vehicle-electric"
                          id="vehicle-electric"
                          :value="false"
                          v-model="vehicle.isEV"
                          :aria-label="content.fieldLabels.no"
                          :required="vehicleInfoHasBeenAdded"
                        />
                        <label
                          class="form-check-label text-medium"
                          for="vehicle-electric"
                          >{{ content.fieldLabels.no }}</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-row justify-content-between my-4">
                  <div class="form-group col-12 col-md-6 ml-0 ml-md-1">
                    <button
                      class="btn py-2 px-4 text-white"
                      type="reset"
                      @click="clearVehicleForm"
                      :disabled="
                        !vehicleInfoHasBeenAdded && vehicle.isEV === null
                      "
                    >
                      {{ content.fieldLabels.clear }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row my-2" v-if="showAirportDetails">
            <div class="col-sm-12" v-if="showAirportDetails">
              <hr />
            </div>
          </div>
          <div class="row inner-form mb-4">
            <div class="col-sm-12">
              <h3 class="mb-3">{{ content.flightDetailsLabel }}</h3>
              <p class="mb-3">{{ content.flightDetailsExplanation }}</p>
              <div class="form-row justify-content-between">
                <div class="form-group col-12 col-md-6 mt-3 mb-3">
                  <div class="select-wrapper">
                    <select
                      class="form-control form-control-lg"
                      id="flight-airline"
                      v-model="flight.airline"
                      :aria-label="content.fieldLabels.returnAirline"
                    >
                      <option disabled selected value>
                        {{ content.fieldLabels.returnAirline }}
                      </option>
                      <template v-for="airline in formOptions.airlines">
                        <option
                          v-bind:key="airline.airline_code"
                          v-bind:value="airline.airline_code"
                        >
                          {{ airline.airline_name }}
                        </option>
                      </template>
                    </select>
                  </div>
                </div>
                <div class="form-group col-12 col-md-6 mt-3 mb-3">
                  <input
                    class="form-control form-control-lg"
                    type="text"
                    id="flight-number"
                    v-model="flight.number"
                    :aria-label="content.fieldLabels.returnFlightNumber"
                    :placeholder="content.fieldLabels.returnFlightNumber"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="col-xl-4 col-12 confirm-box">
        <div class="sticky-top">
          <div class="callout">
            <h1 class="text-medium">{{ content.confirmationLabel }}</h1>
            <p class="mb-4">
              {{ content.paymentDue[0] }}
              <strong>{{
                parseAndSum([quote.quoteTotal]) | formatCurrency
              }}</strong>
              {{ content.paymentDue[1] }}
            </p>
            <div v-if="showExtraFrenchTerms()">
              <p>
                {{ content.termsAgreementExtraFrench[0] }}
                <a
                  :href="content.termsAgreementExtraFrench[1]"
                  target="_blank"
                  >{{ content.termsAgreementExtraFrench[2] }}</a
                >
              </p>
            </div>
            <p>
              {{ content.termsAgreement[0] }}
              <a :href="content.termsAgreement[1]" target="_blank">{{
                content.termsAgreement[2]
              }}</a>
            </p>
            <div v-if="!submittingQuote">
              <button
                class="btn btn--big grey-disable mt-4"
                :disabled="isDisabled"
                v-on:click="confirmReservation"
              >
                {{ content.getCoupon }}
              </button>
            </div>
            <div class="my-2" v-if="submittingQuote">
              <div class="text-center">
                <img
                  src="/wp-content/themes/parknfly/resources/assets/images/loading.gif"
                  width="65"
                  height="65"
                />
              </div>
            </div>
            <p
              class="text-red mt-4 mb-0"
              v-if="contactValidationNeeded && validatePhoneNumber()"
            >
              {{ content.errorMsg.validationNeeded }}
            </p>
            <div
              id="errorMessage"
              class="alert alert-danger d-none mt-4"
              role="alert"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
/*
  global
    CURRENT_LANGUAGE
    parkingLotsList
    locationsList
    window
*/

import axios from "axios";
import { router } from "../components/routes";
import ValidationCode from "../components/validation-code.vue";
import AdditionalServices from "../components/additional-services.vue";
import moment from "moment";
import * as util from "../util/shared";
import Vue from "vue";
import GetUser from "../util/users";
import Content from "../../string_resources/reserve.json";
import { mask } from "vue-the-mask";
import Validation, { Validator } from "../util/formValidation";
import store from "../components/store";

moment.locale(CURRENT_LANGUAGE);

Vue.filter("pluralize", (word, amount) =>
  amount !== 1 ? `${word.replace(/jour|day/i, (a) => a + "s")}` : word
);

const initialVehicle = {
  type: "",
  make: "",
  color: "",
  plate: "",
  province: "",
  isEV: null,
};

export default {
  name: "reserveView",
  components: { ValidationCode, AdditionalServices },
  directives: { mask, Validator },
  data() {
    return {
      content: Content[CURRENT_LANGUAGE],
      activeLocation: locationsList.filter((l) => l.active)[0],
      dropOff: moment(this.$store.getters.getDropOff, "YYYY-M-D h:m"),
      pickUp: moment(this.$store.getters.getPickUp, "YYYY-M-D h:m"),
      discount: this.$store.getters.getCoupon,
      communicationMethod: "S",
      translate: util.translate,
      firstName: this.userCustomer ? this.userCustomer.first_name : "",
      lastName: this.userCustomer ? this.userCustomer.last_name : "",
      emailAddress: this.userCustomer ? this.userCustomer.email_address : "",
      confirmEmailAddress: this.userCustomer
        ? this.userCustomer.email_address
        : "",
      phoneNumber: this.userCustomer ? this.userCustomer.cellphone : "",
      postal: this.userCustomer ? this.userCustomer.postal_code : "",
      freeDaysUsed: 0,
      promotionsApproved: false,
      userVehicles: this.userCustomerVehicles
        ? this.userCustomerVehicles
        : false,
      selectedVehicle: 0,
      allowUndo: false,
      aeroplanNumber: "",
      aeroplanNumberValidated: false,
      waitingForAeroplanValidation: false,
      vehicle: initialVehicle,
      flight: {
        airline: "",
        number: "",
      },
      user: false,
      userCustomer: null,
      userCustomerVehicles: null,
      userRewardMember: null,
      service: false,
      errors: [],
      contactValidationHash: "",
      userIsTravelAgent: this.$store.getters.getAuthUserRole === "agent",
      formIsValid: false,
      aeroplanInvalid: false,
      vehicleInfoHasBeenAdded: false,
      canSubmitForm: true,
      applyingRewards: false,
      isTorontoValet: false,
      submittingQuote: false,
    };
  },
  watch: {
    userCustomer: function (userCustomer) {
      this.firstName = userCustomer.first_name;
      this.lastName = userCustomer.last_name;
      this.emailAddress = userCustomer.email_address;
      this.confirmEmailAddress = userCustomer.email_address;
      this.phoneNumber = userCustomer.cellphone;
      this.postal = userCustomer.postal_code;
    },
    quote: function (quote, prevQoute) {
      this.service =
        quote && quote.service
          ? quote.service
          : prevQoute.service
          ? prevQoute.service
          : false;
      this.isTorontoValet = this.quote.locationName === "Toronto Valet";
      $(function () {
        $('[data-toggle="tooltip"]').tooltip();
      });
    },
    contactValidationHash: function () {
      this.formIsValid = true;
      this.validateForm();
    },
    aeroplanNumber: function () {
      if (this.aeroplanNumber && this.aeroplanNumber.length < 9) {
        this.formIsValid = false;
      }
      if (this.aeroplanNumber === "") {
        this.formIsValid = true;
        this.aeroplanInvalid = false;
      }
    },
    vehicle: {
      handler: function () {
        this.vehicleInfoHasBeenAdded =
          this.vehicle.type != "" ||
          this.vehicle.make != "" ||
          this.vehicle.color != "" ||
          this.vehicle.plate != "" ||
          this.vehicle.province != "";
        this.validateForm();
        const $this = this;
        setTimeout(function () {
          $this.checkCanSubmitForm();
        }, 10);
      },
      deep: true,
    },
  },

  methods: {
    showExtraFrenchTerms: function () {
      if (this.quote) {
        if (this.quote.locationName) {
          return (
            this.quote.locationName.includes("Montréal") &&
            CURRENT_LANGUAGE === "en"
          );
        }
      }
      return false;
    },
    validatePhoneNumber: function () {
      let phone = this.phoneNumberDigits;

      return phone.length == 10;
    },
    validateName: function () {
      if (this.isLoggedIn || this.userIsTravelAgent) {
        return true;
      }

      jQuery("#phone-number").attr(
        "placeholder",
        jQuery("#phone-number").attr("placeholder").replace("* ", "")
      );
      this.runValidation(
        "[validate-name]",
        Validation.name,
        this.content.errorMsg.name,
        this.validateName
      );
    },
    validatePhone: function () {
      this.contactValidationHash =
        this.phoneNumberDigits.length == 10 ? "" : "#sssss#";
      return true;
      // this.runValidation(
      //   "[validate-phone]",
      //   Validation.phone,
      //   this.content.errorMsg.phone,
      //   this.validatePhone
      // );
    },
    validateEmail: function () {
      this.runValidation(
        "[validate-email]",
        Validation.emailOptional,
        this.content.errorMsg.email,
        this.validateEmail
      );
    },
    validatePostalCode: function () {
      this.runValidation(
        "[validate-postal]",
        Validation.postalOptional,
        this.content.errorMsg.postal,
        this.validatePostalCode
      );
    },
    validatePlanNumber: function () {
      this.runValidation(
        "[validate-aeroplan]",
        Validation.aeroplanNumberOptional,
        this.content.errorMsg.discountNumber,
        this.validateAeroplan
      );
    },
    runValidation: function (
      selector,
      validationPattern,
      msg,
      callback = false
    ) {
      const $this = this;
      $(selector).each((index, el) => {
        $(el)
          .off("input")
          .on("input", () => {
            var valid = validationPattern.test(el.value);
            if (valid) {
              if ($this.aeroplanNumber && callback) {
                el.setCustomValidity("Waiting for validation...");
                callback(el, msg);
              } else {
                el.setCustomValidity("");
                $this.validateForm();
              }
            } else {
              el.setCustomValidity(msg);
              $this.validateForm();
            }
          });
      });
    },
    bindCustomFieldValidation: function () {
      this.validateName();
      this.validatePlanNumber();
      this.validatePostalCode();
      this.validateEmail();
    },
    handlePointChange: function (val) {
      // Val is inc or dec value; Conditions check that days redeemed is gt 0 and lte days available
      return this.freeDaysUsed + val <= this.freeDaysAvailable &&
        this.freeDaysUsed + val > -1
        ? (this.freeDaysUsed += val)
        : this.freeDaysUsed;
    },
    storeHash: function () {
      this.contactValidationHash = document.getElementById(
        "verified-email-hash"
      ).value;
    },
    checkCanSubmitForm: function () {
      const vehicleDetailsForm = $("#vehicle-details")[0];
      var canSubmit = true;
      if (vehicleDetailsForm) {
        canSubmit = vehicleDetailsForm.checkValidity();
      }
      if (this.firstName.length === 0 || this.lastName.length === 0) {
        canSubmit = false;
      }

      if (!this.userIsTravelAgent) {
        if (this.emailAddress.length === 0 && this.phoneNumber.length === 0) {
          canSubmit = false;
        }
      }

      if (this.postal.length > 0) {
        if (!Validation.postalOptional.test(this.postal)) {
          canSubmit = false;
        }
      }

      if (this.emailAddress.length > 0) {
        if (!Validation.email.test(this.emailAddress)) {
          canSubmit = false;
        }
      }
      if (!this.isLoggedIn && !this.userIsTravelAgent) {
        if (this.phoneNumberDigits > 0) {
          if (this.phoneNumberDigits.length !== 10) {
            canSubmit = false;
          }
          if (
            this.phoneNumberDigits.length === 10 &&
            this.contactValidationHash.length < 5
          ) {
            canSubmit = false;
          }
        }
      }

      if (!this.isLoggedIn) {
        if (
          this.emailAddress.length > 0 ||
          this.confirmEmailAddress.length > 0
        ) {
          if (this.emailAddress !== this.confirmEmailAddress) {
            canSubmit = false;
          }
        }
      }

      if (this.userIsTravelAgent) {
        if (this.phoneNumberDigits > 0) {
          if (this.phoneNumberDigits.length !== 10) {
            canSubmit = false;
          }
        }
      }
      if (this.aeroplanNumber.length > 0) {
        if (this.aeroplanNumber.length !== 9) {
          canSubmit = false;
        }
        if (!this.aeroplanNumberValidated) {
          canSubmit = false;
        }
      }
      this.canSubmitForm = canSubmit;
    },
    alterQuote: function (quoteAlterationType) {
      let $this = this;
      let routeArgs;
      let callback;
      this.applyingRewards = true;
      switch (quoteAlterationType) {
        case "freeDays":
          routeArgs = [
            "/wp-admin/admin-ajax.php?action=pnf_quote_redeem_days",
            {
              quoteId: this.quote.quoteId,
              days: this.freeDaysUsed,
            },
          ];
          callback = (response) => {
            this.allowUndo = true;
            this.applyingRewards = false;
            $this.$store.dispatch(
              "updateSelectedQuote",
              response.data.result.confirmation
            );
          };
          break;

        case "undoFreeDays":
          routeArgs = [
            "/wp-admin/admin-ajax.php?action=pnf_undo_redeem",
            { quoteId: this.quote.quoteId },
          ];
          callback = (response) => {
            this.allowUndo = false;
            this.applyingRewards = false;
            $this.$store.dispatch(
              "updateSelectedQuote",
              response.data.result.confirmation
            );
          };
          break;
      }

      let ajaxProps = [...routeArgs, { timeout: 10000 }];

      return new Promise((resolve, reject) => {
        axios
          .post(...ajaxProps)
          .then((response) => {
            if (callback) {
              callback(response);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    validateAeroplan: function (el, msg) {
      const $this = this;
      this.formIsValid = this.checkFormValidity();
      this.waitingForAeroplanValidation = true;
      this.aeroplanNumberValidated = false;
      this.aeroplanInvalid = false;
      axios
        .post(`/wp-admin/admin-ajax.php?action=pnf_validate_aeroplan`, {
          aeroplanNumber: $this.aeroplanNumber,
        })
        .then((response) => {
          this.waitingForAeroplanValidation = false;
          if (
            response.status !== 200 ||
            response.data.status !== "OK" ||
            !response.data.result
          ) {
            el.setCustomValidity(msg);
            $("#your-information")[0].focus();
            $this.validateForm();
            $this.aeroplanInvalid = true;
            this.aeroplanNumberValidated = false;
          } else {
            el.setCustomValidity("");
            this.aeroplanNumberValidated = true;
            $this.validateForm();
          }
        });
    },
    confirmReservation: function (evt) {
      evt.preventDefault();

      var errorMessage = document.getElementById("errorMessage");
      errorMessage.classList.remove("d-block");

      if (!this.canSubmitForm) return;

      let payload = {};
      if (
        window.is_authenticated() &&
        this.$store.getters.getAuthUserRole === "member"
      ) {
        // Signed In Member
        payload = {
          quoteId: this.quote.quoteId,
          language: CURRENT_LANGUAGE == "en" ? "E" : "F",
          vehicleId: this.selectedVehicle,
          airlineCode: this.flight.airline,
          flightNumber: this.flightNumber,
        };
        if (this.vehicleInfoHasBeenAdded) {
          payload.makeId = this.vehicle.make;
          payload.typeId = this.vehicle.type;
          payload.colorId = this.vehicle.color;
          payload.licenseNumber = this.vehicle.plate;
          payload.licenseProvinceId = this.vehicle.province;
          payload.electronicVehicle = this.vehicle.isEV ? "Y" : "N";
        }
      } else {
        // Anonymous User
        payload = {
          quoteId: this.quote.quoteId,
          communicationMethod: this.communicationMethod,
          language: CURRENT_LANGUAGE == "en" ? "E" : "F",
          email: this.emailAddress,
          cellPhoneNumber: this.isValidPhoneNumber
            ? this.phoneNumberDigits
            : "",
          promotionsApproved: this.promotionsApproved,
          firstName: this.firstName,
          lastName: this.lastName,
          postalcode: this.postal,
          makeId: this.vehicle.make,
          typeId: this.vehicle.type,
          colorId: this.vehicle.color,
          licenseNumber: this.vehicle.plate,
          licenseProvinceId: this.vehicle.province,
          electronicVehicle: this.vehicle.isEV ? "Y" : "N",
          aeroplanNumber: this.aeroplanNumber,
          airlineCode: this.flight.airline,
          flightNumber: this.flightNumber,
        };
      }
      this.submittingQuote = true;
      axios
        .post("/wp-admin/admin-ajax.php?action=pnf_confirm_quote", payload, {
          timeout: 9000,
        })
        .then((response) => {
          this.$store.commit("LOADED");
          if (response.data.result.status == "OK") {
            let responseData = response.data.result.confirmation;
            if (responseData && responseData.ConfirmationId) {
              this.$store.dispatch(
                "setConfirmationId",
                responseData.ConfirmationId
              );
            }
            if (responseData && responseData.RetrievalId) {
              this.$store.dispatch("setRetrievalId", responseData.RetrievalId);
            }
            this.submittingQuote = false;
            if (this.isLoggedIn) {
              router.replace("/thank-you-member");
            } else {
              router.replace("/thank-you");
            }
          } else {
            this.submittingQuote = false;
            errorMessage.classList.add("d-block");
            if (
              response.data.result.errors &&
              response.data.result.errors.length > 0
            ) {
              errorMessage.innerHTML =
                response.data.result.errors.join("<br/>");
            } else {
              errorMessage.innerHTML = this.content.errorMsg.failed;
            }
          }
        })
        .catch(() => {
          this.submittingQuote = false;
          this.$store.commit("LOADED");
          errorMessage.classList.add("d-block");
          errorMessage.innerHTML = this.content.errorMsg.failed;
        });
    },
    updateServices: function (newServiceValue) {
      this.service = newServiceValue;
    },
    clearVehicleForm: function () {
      this.vehicle.type = "";
      this.vehicle.make = "";
      this.vehicle.color = "";
      this.vehicle.plate = "";
      this.vehicle.province = "";
      this.vehicle.isEV = null;
      this.checkFormValidity();
    },
    validateForm: function () {
      const yourInfoForm = $("#your-information")[0];
      const vehicleDetailsForm = $("#vehicle-details")[0];
      setTimeout(() => {
        if (this.emailAddress == "" && this.phoneNumber == "") {
          this.formIsValid = false;
          return;
        }

        this.formIsValid =
          yourInfoForm && vehicleDetailsForm
            ? this.formIsValid
              ? this.reportFormValidity()
              : this.checkFormValidity()
            : false;
      }, 56);
    },
    reportFormValidity: function () {
      const yourInfoForm = $("#your-information")[0];
      const vehicleDetailsForm = $("#vehicle-details")[0];
      return yourInfoForm && vehicleDetailsForm
        ? yourInfoForm.reportValidity() &&
            vehicleDetailsForm.reportValidity() &&
            !this.contactValidationNeeded
        : false;
    },
    checkFormValidity: function () {
      const yourInfoForm = $("#your-information")[0];
      const vehicleDetailsForm = $("#vehicle-details")[0];
      return yourInfoForm && vehicleDetailsForm
        ? yourInfoForm.checkValidity() &&
            vehicleDetailsForm.checkValidity() &&
            !this.contactValidationNeeded
        : false;
    },
    getMakeName(id) {
      if (this.formOptions.make) {
        const make = this.formOptions.make.find(
          (m) => m.vehicle_make_id === id
        );
        if (make) {
          return make.make_name;
        }
      }
    },
    parseAndSum: function (numberArr) {
      return numberArr.reduce(
        (a, b) => a + Number(`${b}`.replace(/,|\$/g, "")),
        0
      );
    },
  },
  filters: {
    formatDiscount(discount) {
      return discount == 0 ? "N/A" : discount;
    },
    formatPhoneNumber(phoneNumber) {
      var cleaned = ("" + phoneNumber.toString()).replace(/\D/g, "");
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return phoneNumber;
    },
    capitalize(text) {
      return text ? text.charAt(0).toUpperCase() + text.slice(1) : " ";
    },
    formatCurrency(text) {
      try {
        const parsedNumberText = `${text}`.replace(/,|\$/g, "");
        return Number(parsedNumberText).toLocaleString("en-CA", {
          style: "currency",
          currency: "CAD",
        });
      } catch (err) {
        return err ? text : "";
      }
    },
    padRight(text) {
      if (text != undefined) {
        text = text.toString();
        return text.charAt(text.length - 2) == "." ? text + "0" : text;
      }
    },
    formatLocationName(text) {
      text = text.toString();
      return text.replace(/^(\w+\s)([A-Z]+\w+)([A-Z])/, "$1 $2-$3");
    },
    formatLocationTitle: (val) =>
      util.OfferFilters[CURRENT_LANGUAGE].formatLocationTitle(val),
    dollarSignOnDiscount: (val) => val.replace("-$?", "-$"),
  },
  computed: {
    lang: function () {
      return CURRENT_LANGUAGE;
    },
    formOptions: function () {
      return {
        airlines: this.$store.getters.getAirlines,
        color: this.$store.getters.getVehicleColours,
        type: this.$store.getters.getVehicleTypes,
        make: this.$store.getters.getVehicleMakes,
        provinces: this.$store.getters.getVehicleProvinces,
      };
    },
    showAirportDetails: function () {
      if (this.activeLocation) {
        return this.activeLocation.city !== "Halifax";
      }
      return false;
    },
    checkAdditionalServices: function () {
      const _this = this;
      const conditions = {
        hasAddedServices: !!_this.service,
        needsSUVSurcharge: !!(
          _this.service &&
          _this.service.surcharge &&
          _this.service.surcharge.rate &&
          (_this.vehicle.type === 2 || _this.vehicle.type === 4)
        ), // Vehicle is SUV or Truck
      };
      const charges =
        (conditions.hasAddedServices ? _this.service.rate : 0) +
        (conditions.needsSUVSurcharge ? _this.service.surcharge.rate : 0);
      const surchargeRate = conditions.needsSUVSurcharge
        ? _this.service.surcharge.rate
        : 0;
      const surchargeTax = conditions.needsSUVSurcharge
        ? _this.service.surcharge.rate * 0.13
        : 0;
      const vehicleSurcharge = {
        rate: surchargeRate,
        tax: surchargeTax,
        total: surchargeTax + surchargeRate,
      };
      const tax = charges * 0.13;
      return {
        ...conditions,
        charges,
        tax,
        vehicleSurcharge,
        total: Number(charges) + Number(tax),
      };
    },
    quote: function () {
      return this.$store.getters.getSelectedQuote;
    },
    isLoggedIn: function () {
      return (
        this.$store.getters.getAuthState &&
        this.$store.getters.getAuthUserRole === "member"
      );
    },
    phoneNumberDigits: function () {
      return this.phoneNumber.replace(/[^0-9]/g, "");
    },
    selectedParkingLot: function () {
      return parkingLotsList.filter(
        (d) => d.location_id == this.quote.locationId
      )[0];
    },
    freeDaysAvailable: function () {
      return this.quote.redeemableDays
        ? this.quote.redeemableDays <= this.quote.maxDaysToRedeem
          ? this.quote.redeemableDays
          : this.quote.maxDaysToRedeem
        : 0;
    },
    maxDaysToRedeem: function () {
      return this.quote.maxDaysToRedeem
        ? this.quote.maxDaysToRedeem
        : this.quote.redeemableDays
        ? this.quote.redeemableDays
        : 0;
    },
    nonTaxQuoteLines: function () {
      return this.quote.quoteLines.filter(
        (quoteLine) => quoteLine.revenueType !== "T"
      );
    },
    taxQuoteLines: function () {
      return this.quote.quoteLines.filter(
        (quoteLine) => quoteLine.revenueType === "T"
      );
    },
    isDisabled: function () {
      this.checkCanSubmitForm();
      return !this.canSubmitForm;
    },
    contactValidationNeeded: function () {
      return false;
    },
    isValidPhoneNumber: function () {
      return true;
    },
    isValidEmailAddress: function () {
      return Validation.email.test(this.emailAddress);
    },
    flightNumber: function () {
      return this.flight.number.replace(/\D/g, "");
    },
    canRedeemDays: function () {
      return this.isLoggedIn && (this.freeDaysAvailable || this.allowUndo);
    },
    valetServices() {
      if (this.selectedParkingLot.valet_services) {
        let list = this.selectedParkingLot.valet_services.tiers;
        return list != undefined && list.length > 0 ? list : false;
      }
      return false;
    },
    loading() {
      return this.$store.getters.getLoading;
    },
  },
  created() {
    if (this.isLoggedIn) {
      GetUser().then((user) => {
        this.userCustomer = user.customer;
        this.userCustomerVehicles = user.customer_vehicle;
        this.userRewardMember = user.reward_member;
      });
    }
    this.$store.dispatch("setAirlines");
  },
  beforeCreate: function () {
    this.$store = store;
  },
  mounted() {
    // Bind expand/collapse events
    if ($("#trip-details").length) {
      $("#trip-details").on("hidden.bs.collapse", function () {
        $("#expand-trip-details").toggle();
      });
      $("#trip-details").on("show.bs.collapse", function () {
        $("#expand-trip-details").toggle();
      });
    }
    if ($("#valet-services-table").length) {
      $("#valet-services-table").on("hidden.bs.collapse", function () {
        $("#expand-valet-services-link").show();
        $("#collapse-valet-services-link").hide();
      });
      $("#valet-services-table").on("show.bs.collapse", function () {
        $("#expand-valet-services-link").hide();
        $("#collapse-valet-services-link").show();
      });
    }
    //Check if free days already redeemed on quote
    this.allowUndo =
      this.quote.quoteLines.filter((d) => d["lineOrder"] == 150).length > 0;
    this.freeDaysUsed = this.allowUndo
      ? this.quote.quoteLines.filter((d) => d["lineOrder"] == 150)[0].quantity
      : 0;
    this.service = this.quote.service;

    this.bindCustomFieldValidation();
    util.styleDropdowns();
    this.formIsValid = this.isLoggedIn;
  },
};
</script>
