import { router } from '../components/routes';
import VueRouter from 'vue-router';
import store from '../components/store';
import Breadcrumbs from '../views/member-profile/breadcrumbs.vue';
import AccountCard from '../views/member-profile/account-card.vue';
import Vue from 'vue';

export default {
  init() {
      //location-details component app
      Vue.use(VueRouter);
      if(window.location.search === '?edit&vs') {
        router.replace('/members/edit')
      } else {
        router.replace('/members/');
      }

      window.breadcrumbs = new Vue({
        el: "#breadcrumbs",
        components: {Breadcrumbs},
        router,
        template: "<breadcrumbs></breadcrumbs>",
      });

      window.accountCard = new Vue({
        el: "#accountCard",
        components: {AccountCard},
        router,
        template: "<account-card></account-card>",
      });


      window.app = new Vue({
        el: "#member-profile",
        store,
        router,
      });

  },
  finalize() {
  },
};
