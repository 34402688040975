import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
const blankState = {
  quotes: [],
  selectedQuoteId: -1,
  dropOff: '', //moment().toDate().toISOString(),
  pickUp: '', //moment().add(7, 'days').toDate().toISOString(),
  coupon: 0,
  city: '',
  airport: '',
  location: {},
  user: null,
  userRole: null,
  tier: null,
  confirmationId: 0,
  retrievalId: 0,
  vehicleColours: [],
  vehicleTypes: [],
  vehicleMakes: [],
  vehicleProvinces: [],
  airlines: [],
  language: '',
  loading: false,
  rewardsPoints: '',
  toastMessage: '',
}

const mutations = {
  CLEAR_QUOTES(state) {
    state.quotes = null;
  },
  INIT_QUOTES(state) {
    state.quotes = [];
  },
  ADD_QUOTE(state, payload) {
    let newQuote = payload;
    if(state.quotes === null) {
      state.quotes = [];
    }
    state.quotes.push(newQuote);
  },
  SET_QUOTE_ID(state, payload) {
    state.selectedQuoteId = payload;
  },
  UPDATE_SELECTED_QUOTE(state, payload) {
    let quotes = state.quotes.filter(d => d.quoteId != payload.quoteId);
    state.quotes = [...quotes, payload];
    state.selectedQuoteId = payload.quoteId;
  },
  SET_DROP_OFF(state, payload) {
    state.dropOff = payload;
  },
  SET_PICK_UP(state, payload) {
    state.pickUp = payload;
  },
  SET_CITY(state, payload) {
    state.city = payload;
  },
  SET_AIRPORT(state, payload) {
    state.airport = payload;
  },
  SET_LOCATION(state, payload) {
    state.location = payload;
  },
  SET_COUPON(state, payload) {
    state.coupon = payload;
  },
  SET_AUTH_STATE(state, payload) {
    state.user = payload;
  },
  SET_TIER(state, payload) {
    state.tier = payload;
  },
  SET_AUTH_ROLE_STATE(state, payload) {
    state.userRole = payload;
  },
  SET_CONFIMRATION_ID(state, payload) {
    state.confirmationId = payload;
  },
  SET_TOAST_MESSAGE(state, payload) {
    state.toastMessage = payload;
  },
  SET_RETRIEVAL_ID(state, payload) {
    state.retrievalId = payload;
  },
  SET_LANGUAGE(state, payload) {
    state.language = payload;
  },
  SET_VEHICLE_MAKES(state, payload) {
    state.vehicleMakes = payload;
  },
  SET_VEHICLE_TYPES(state, payload) {
    state.vehicleTypes = payload;
  },
  SET_VEHICLE_COLOURS(state, payload) {
    state.vehicleColours = payload;
  },
  SET_VEHICLE_PROVINCES(state, payload) {
    state.vehicleProvinces = payload;
  },
  SET_AIRLINES(state, payload) {
    state.airlines = payload;
  },
  SET_REWARDS_POINTS(state, payload) {
    state.rewardsPoints = payload;
  },
  SAVE_STORE_STATE(state) {
    state.loading = false;
    sessionStorage.setItem('storeState', JSON.stringify(state));
  },
  DELETE_STORE_STATE(state) {
    sessionStorage.removeItem('storeState');
    state.selectedQuoteId = -1;
    state = blankState;
  },
  LOADING(state) {
    state.loading = true;
  },
  LOADED(state) {
    state.loading = false;
  },
}
const actions = {
  saveStoreState(context) {
    context.commit('SAVE_STORE_STATE');
  },
  deleteStoreState(context) {
    context.commit('DELETE_STORE_STATE');
  },
  clearQuotes(context) {
    context.commit('CLEAR_QUOTES');
    context.commit('SAVE_STORE_STATE'); //figure out when to best delete the state
  },
  addQuote(context, payload) {
    context.commit('ADD_QUOTE', payload);
    context.commit('SAVE_STORE_STATE');
  },
  setAuthUser(context, payload) {
    context.commit('SET_AUTH_STATE', payload);
    context.commit('SAVE_STORE_STATE');
  },
  setTier(context, payload) {
    context.commit('SET_TIER', payload);
    context.commit('SAVE_STORE_STATE');
  },
  setAuthUserRole(context, payload) {
    context.commit('SET_AUTH_ROLE_STATE', payload);
    context.commit('SAVE_STORE_STATE');
  },
  setSelectedQuote(context, payload) {
    context.commit('SET_QUOTE_ID', payload);
    context.commit('SAVE_STORE_STATE');
  },
  updateSelectedQuote(context, payload) {
    context.commit('UPDATE_SELECTED_QUOTE', payload);
    context.commit('SAVE_STORE_STATE');
  },
  setDropOff(context, payload) {
    context.commit('SET_DROP_OFF', payload);
    context.commit('SAVE_STORE_STATE');
  },
  setPickUp(context, payload) {
    context.commit('SET_PICK_UP', payload);
    context.commit('SAVE_STORE_STATE');
  },
  setCity(context, payload) {
    context.commit('SET_CITY', payload);
    context.commit('SAVE_STORE_STATE');
  },
  setAirport(context, payload) {
    context.commit('SET_AIRPORT', payload);
    context.commit('SAVE_STORE_STATE');
  },
  setLocation(context, payload) {
    context.commit('SET_LOCATION', payload);
    window.activeLocation = payload.ID;
    context.commit('SAVE_STORE_STATE');
  },
  setRewardsPoints(context, payload) {
    context.commit('SET_REWARDS_POINTS', payload);
    context.commit('SAVE_STORE_STATE');
  },
  setLanguage(context, payload) {
    //Toggle language and update objects used to populate form data
    if (context.state.language != payload) {
      context.commit('SET_LANGUAGE', payload);
      context.commit('SAVE_STORE_STATE');
      context.dispatch("setVehicleFormProps");
    }
  },
  setCoupon(context, payload) {
    if (!payload) {
      payload = 0;
    }
    context.commit('SET_COUPON', payload);
    context.commit('SAVE_STORE_STATE');
  },

  setConfirmationId(context, payload) {
    context.commit('SET_CONFIMRATION_ID', payload);
    context.commit('SAVE_STORE_STATE');
  },
  setToastMessage(context, payload) {
    context.commit('SET_TOAST_MESSAGE', payload);
    context.commit('SAVE_STORE_STATE');
  },
  setRetrievalId(context, payload) {
    context.commit('SET_RETRIEVAL_ID', payload);
    context.commit('SAVE_STORE_STATE');
  },
  fetchServiceRates(context, payload) {
    if (!payload) return false;
    // Grab service ids from Car and Truck/SUV rates;
    // Filter duplicates; join to csv string

    let args = {}
    if (payload.estimate) {
      args = payload.args;
    }
    else {
      args = {
        city: context.state.city,
        openDate: context.state.dropOff,
        closeDate: context.state.pickUp,
        groupCouponId: context.state.coupon,
      };
    }

    args.services = Object.values(payload.services)
    .map(s => Object.values(s).filter((v, i, a) => a.indexOf(v) === i))
    .join(",");

    return axios.post('/wp-admin/admin-ajax.php?action=pnf_get_service_rates', args, {
      timeout: 30000,
    });
  },
  setAirlines(context) {
    let ajaxProps = [
      '/wp-admin/admin-ajax.php?action=pnf_get_airlines',
      {
        city: context.state.city,
      },
      {
        timeout: 10000,
      },
    ]

    return new Promise((resolve, reject) => {
      axios.post(...ajaxProps)
        .then((response) => {
          context.commit("SET_AIRLINES", response.data.result);
        })
        .catch((err) => {
          reject(err);
        });
    })
  },
  setVehicleFormProps(context) {
    function grabFormProps(propName) {
      let ajaxProps = [
        '/wp-admin/admin-ajax.php?action=pnf_get_vehicle_' + propName,
        // 'makes'/'provinces' doesn't take a language param
        propName == 'makes' ||
          propName == 'provinces' ? {} : {
            language: context.state.language,
          },
        {
          timeout: 10000,
        },
      ]
      return new Promise((resolve, reject) => {
        axios.post(...ajaxProps)
          .then((response) => {
            resolve(response.data.result.confirmation);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
    return Promise.all([
      grabFormProps('types'),
      grabFormProps('colours'),
      grabFormProps('makes'),
      grabFormProps('provinces'),
    ])
      .then((results) => {
        context.commit("SET_VEHICLE_TYPES", results[0]);
        context.commit("SET_VEHICLE_COLOURS", results[1]);
        context.commit("SET_VEHICLE_MAKES", results[2]);
        context.commit("SET_VEHICLE_PROVINCES", results[3]);
        context.commit('SAVE_STORE_STATE');
      })
  },
  setQuotes(context) {
    context.commit("LOADING");
    return new Promise((resolve, reject) => {
      axios.post('/wp-admin/admin-ajax.php?action=pnf_get_quote', {
        dropOff: context.state.dropOff,
        pickUp: context.state.pickUp,
        city: context.state.city,
        discount: context.state.coupon,
      }, {
        timeout: 30000,
      })
        .then((response) => {
          context.commit("LOADED");
          if (response.data.result.status == "OK") {
            context.commit('INIT_QUOTES');
            for (var i = 0; i < response.data.result.quotes.length; i++) {
              context.commit('ADD_QUOTE', response.data.result.quotes[i]);
            }
            context.commit('SAVE_STORE_STATE');
          }
          resolve(response);
        })
        .catch((error) => {
          context.commit("LOADED");
          reject(error);
        });
    })
  },
}

const getters = {
  getQuotes(state) {
    return state.quotes;
  },
  getQuoteCount(state) {
    return state.quotes.length;
  },
  getSelectedQuote(state) {
    return state.quotes.filter(
      quote => quote.quoteId === state.selectedQuoteId
    )[0];
  },
  getDropOff(state) {
    return state.dropOff;
  },
  getTier(state){
    return state.tier;
  },
  getPickUp(state) {
    return state.pickUp;
  },
  getCity(state) {
    return state.city;
  },
  getAirport(state) {
    return state.airport;
  },
  getLocation(state) {
    return state.location;
  },
  getCoupon(state) {
    return state.coupon;
  },
  getAuthState(state) {
    return state.user !== null;
  },
  getAuthUser(state) {
    return state.user;
  },
  getAuthUserRole(state) {
    return state.userRole;
  },
  getToastMessage(state) {
    let msg = state.toastMessage;
    state.toastMessage = '';
    return msg;
  },

  getConfirmationId(state) {
    return state.confirmationId;
  },
  getRetrievalId(state) {
    return state.retrievalId;
  },
  getLanguage(state) {
    return state.language;
  },
  getVehicleColours(state) {
    return state.vehicleColours;
  },
  getVehicleMakes(state) {
    return state.vehicleMakes;
  },
  getVehicleTypes(state) {
    return state.vehicleTypes;
  },
  getAirlines(state) {
    return state.airlines;
  },
  getVehicleProvinces(state) {
    if (state.vehicleProvinces && state.vehicleProvinces.length) {
      return state.vehicleProvinces;
    } else {
      store.dispatch("setVehicleFormProps");
    }
  },
  getLoading(state) {
    return state.loading;
  },
  getRewardsPoints(state) {
    return state.rewardsPoints;
  },
}

const prevStateString = sessionStorage.getItem('storeState');
const state = prevStateString !== null ? JSON.parse(prevStateString) : blankState;

Vue.use(Vuex);
const store = new Vuex.Store({
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
});

export default store;
